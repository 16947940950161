<div class="card border-0" style="width: 100%; height: 495px; background-color: #F8FAFD;">
  <div class="card-body" style="height: 8rem">
    <p class="title h4 mb-2">
      <strong class="text-bold">{{ totalTitle }}</strong>{{ title }} <br />
      <span class="h6">EN TOTAL</span>
    </p>

    <canvas *ngIf="dataT; else noData" baseChart [data]="dataT" [labels]="labels" [options]="pieChartOptions"
      [type]="pieChartType">
    </canvas>

    <ng-template #noData>
      <div class="text-center m-auto" style="margin-top: 7rem !important">
        <i class="pi pi-eye-slash noData-icon"></i>
        <h6 class="title">sin información</h6>
      </div>
    </ng-template>
  </div>
  <div *ngIf="hasFooter" class="footer text-center card-footer">
    <div class="row">
      <div *ngIf="pieStatus" class="col-4">
        <el-dropdown [options]="pieStatus" [stringOut]="true" customClass="form-control form-control-lg "
          placeHolder="Tipos" valueName="serviceCode" customClass="pieDrop" class="drop" [(ngModel)]="serviceCode"
          (ngModelChange)="reloadData()" (onchange)="reloadData()"></el-dropdown>
      </div>
      <div class="col-8">
        <p class="title float-end m-auto">
          {{ totalGeneral }}
        </p>
      </div>
    </div>
  </div>
</div>
