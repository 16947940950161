import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../auth/_services/user.service';
import { HttpClientService } from '../../shared/_services/http-client/http-client.service';
import { SweetAlertService } from '../../shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from '../../shared/_services/toast/toast.service';
import { ToolsService } from '../../shared/tools/tools.service';
import { DropDownOptionModel } from '../../shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { AsistenciaDiariaResponse } from '../../shared/_services/http-client/types/AsistenciaDiariaResponse';
import { AsistenciaDiariaEmpleadorResponse } from '../../shared/_services/http-client/types/AsistenciaDiariaEmpleadorResponse';
import { AccordeonOpenCloseExpedientService } from '../../shared/accordeon/accordeon-open-close-expedient.service';

@Component({
  selector: 'app-asignacion-abogado',
  templateUrl: './asignacion-abogado.component.html',
  styleUrls: ['./asignacion-abogado.component.css']
})
export class AsignacionAbogadoComponent implements OnInit {
  @Input() record: AsistenciaDiariaEmpleadorResponse;
  constructor(
    private accordeonService: AccordeonOpenCloseExpedientService,
    private HttpService: HttpClientService,
    private users: UserService,
    private Aroute: ActivatedRoute,
    private tool: ToolsService,
    private formBuilder: FormBuilder,
    private sweet: SweetAlertService,
    private toast: ToastService
  ) { }
  motivoAsignacionAbogadoDrop: DropDownOptionModel[] = [];
  asignarAbogadoForm: FormGroup;
  abogadosDrop: DropDownOptionModel[] = [];
  async ngOnInit() {
    this.asignarAbogadoForm = this.formBuilder.group({
      idMotivoAsignacionUsuario: ['', Validators.required],
      idAbogado: [''],
      idAbogadoAlterno: [''],
      idAbogadoAlterno1: ['']
    });
    this.getMotivoAsignacionUsario()
    this.getListAbogado()
    if (this.record) {
      this,this.loadAssignarAbogado(this.record?.asistenciaDiaria)
    }
    console.log(this.record)
  }
  getListAbogado() {
    this.HttpService.getListLayers().subscribe((data) => {
      this.abogadosDrop = data;
    });
  }
  getMotivoAsignacionUsario() {
    this.HttpService.get<any[]>(`MotivoAsignacionUsuario`).subscribe((data) => {
      this.motivoAsignacionAbogadoDrop = data;
    });

  }

  private loadAssignarAbogado(asistenciaDiariaData: any) {

    this.asignarAbogadoForm.setValue({
      idAbogado: asistenciaDiariaData?.idAbogado || '',
      idAbogadoAlterno: asistenciaDiariaData?.idAbogadoAlterno || '',
      idAbogadoAlterno1: asistenciaDiariaData?.idAbogadoAlterno1 || '',
      idMotivoAsignacionUsuario: asistenciaDiariaData?.idMotivoAsignacionUsuario || '',
    });
    this.validateForm()
  }

  async validarAsignarAbogado() {
    if (this.asignarAbogadoForm.valid) {
      const idMotivoAsignacionUsuario = this.asignarAbogadoForm.get('idMotivoAsignacionUsuario').value;
      const idAbogado = this.asignarAbogadoForm.get('idAbogado').value;
      const idAbogadoAlterno = this.asignarAbogadoForm.get('idAbogadoAlterno').value;
      const idAbogadoAlterno1 = this.asignarAbogadoForm.get('idAbogadoAlterno1').value;
      const idAsistenciaDiaria =this.record.asistenciaDiaria?.idAsistenciaDiaria //this.asistenciaDiariaForm.get('idAsistenciaDiaria').value;
      
      try {
        await this.HttpService.postDailyAttendanceForm(
        {
          idAsistenciaDiaria,
          idAbogado,
          idAbogadoAlterno,
          idAbogadoAlterno1,
          idMotivoAsignacionUsuario
        }).toPromise()

        this.accordeonService.cerrarAcordeon('flush-collapseEight')
      } catch (error) {
        this.toast.error(
          'favor inténtelo mas tarde!',
          'La aplicación no esta disponible'
        );
      }
    }
  }

  validateForm() {
    if (this.asignarAbogadoForm?.valid) {
      this.accordeonService.cerrarAcordeon('flush-collapseEight')
    }
  }
}
