<div *ngIf="index !== 0; else first">
  <ng-container [ngSwitch]="format">
    <span *ngSwitchCase="formatTypes.Currency">
      RD
      <el-ellipsis
        [text]="value | currency"
        [charLength]="charLength"
      ></el-ellipsis>
    </span>

    <span *ngSwitchCase="formatTypes.YesOrNo">
      {{ value ? "Si" : "No" }}
    </span>

    <!-- <span *ngSwitchCase="formatTypes.Telephone">
    {{ value | mask: "(000)-000-0000" }}
  </span> -->

    <span *ngSwitchCase="formatTypes.Date">
      {{ value | date: "dd/MM/yyyy" }}
    </span>

    <span *ngSwitchCase="formatTypes.Number">
      <!-- <el-ellipsis
      [text]="value | number"
      [charLength]="charLength"
    ></el-ellipsis> -->
      {{ value | number }}
    </span>

    <span *ngSwitchCase="formatTypes.boolean">
      <div *ngIf="value; else red">
        <i class="fas fa-circle text-success"></i>
      </div>
      <ng-template #red>
        <i class="fas fa-circle text-danger"></i>
      </ng-template>
    </span>

    <span *ngSwitchDefault>
      <el-ellipsis
        [text]="valueOf(value)"
        [charLength]="this.length ? length : charLength"
      ></el-ellipsis>
    </span>
  </ng-container>
</div>
<ng-template #first>
  <div *ngIf="!routePath; else hasRoute">
    <!-- <textarea readonly [placeholder]="value" class="textareaToText">{{
      value
    }}</textarea> -->
    <button readonly class="textareaToText">
      {{ value }}
    </button>
  </div>
  <ng-template #hasRoute>
    <button (click)="route()" class="buttonRoute">
      {{ value }}
    </button>
  </ng-template>
</ng-template>
