<div *ngIf="!isFilter; else isTheFilter">
  <div class="modal-content" *ngIf="!canJustify" [ngClass]="{ cantEditCols: !canEdit }">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="canEdit ? close(false) : close(true)"
      ></button>
    </div>
    <div class="modal-body">
      <div class="centered-content">
        <form
          [formGroup]="infractionReportForm"
          [ngClass]="{ cantEdit: !canEdit }"
        >
          <label class="form-label form-control-lg sectionTitle"
            >Datos de Acta Infracción
          </label>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">No. de Orden </label>
              <input
                type="text"
                placeholder="Numero de orden"
                formControlName="serviceOrderNumber"
                class="form-control form-control-lg"
                placeholder="000-000"
                readonly
              />
            </div>
            <div
              class="col-md col-12 mb-1"
              *ngIf="infractionReport && !isDuplicate"
            >
              <label class="form-label form-control-lg">No. de Acta </label>
              <input
                type="text"
                placeholder="Numero de acta"
                formControlName="infractionReportNumber"
                class="form-control form-control-lg"
                placeholder="000-000"
                readonly
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Fecha&nbsp;y&nbsp;Hora&nbsp;Acta
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="infractionReportForm.get('reportDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Acta"
                  name="reportDate"
                  formControlName="reportDate"
                  ngbDatepicker
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  #reportDate="ngbDatepicker"
                  [markDisabled]="tool.isDisabled"
                  dateMask
                  maxlength="10"
                  [appMaskValue]="infractionReportForm.get('reportDate').value"
                  (ngModelChange)="
                    this.tool.dateVerification(
                      infractionReportForm,
                      'reportDate',
                      $event
                    )
                  "
                  (dateSelect)="refreshMin()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="reportDate.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-md col-6 mb-1 timer">
              <p class="cs-middle">&nbsp;</p>
              <ngb-timepicker
                formControlName="reportHour"
                [minuteStep]="5"
                size="large"
                class="mx-auto"
                [meridian]="meridian"
              ></ngb-timepicker>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-12 col-12 mb-1">
              <label class="form-label form-control-lg"
                >Conceptos
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="infractionReportForm.get('conceptId').invalid"
                  >*</small
                ></label
              >
              <el-dropdown
                [options]="concepts"
                formControlName="conceptId"
                customClass="form-control form-control-lg"
                placeHolder="Elige un concepto"
                (onChange)="getArticles(true)"
              ></el-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Articulos
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  rows="2"
                  *ngIf="infractionReportForm.get('articlesListed').invalid"
                  >*</small
                >
              </label>

              <ng-multiselect-dropdown
                [placeholder]="'Articulos Selección Multiple'"
                [settings]="dropdownSettings"
                [data]="articles"
                formControlName="articlesListed"
                class="multiselect"
                [disabled]="infractionReportForm.get('conceptId').invalid"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Hechos </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="infractionReportForm.get('facts')"
                type="text"
                placeholder="Hechos"
                rows="2"
                class="form-control form-control-lg"
                formControlName="facts"
              ></textarea>
            </div>
          </div>

          <hr />
          <label class="form-label form-control-lg sectionTitle"
            >Datos del Infractor
          </label>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Nombre </label>

              <input
                specialIsAlphaNumeric
                [fControl]="infractionReportForm.get('offenderName')"
                type="text"
                placeholder="Nombre"
                class="form-control form-control-lg"
                formControlName="offenderName"
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Tipo de Documento&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <el-dropdown
                [options]="identificationTypes"
                formControlName="offenderDocumentTypeId"
                customClass="form-control form-control-lg"
                (onChange)="setDocumetType()"
                placeHolder="Elige un tipo de documento"
              ></el-dropdown>
            </div>
            <div
              class="col-md col-12 mb-1"
              *ngIf="infractionReportForm.get('offenderDocumentTypeId').value"
            >
              <label class="form-label form-control-lg"
                >Doc. Identificación
              </label>
              <div [ngSwitch]="documentTypeCode">
                <div *ngSwitchCase="'cd'">
                  <input
                    type="text"
                    placeholder="Cedula"
                    class="form-control form-control-lg"
                    formControlName="documentNumberOfPersonRecevingInfraction"
                    mask="000-0000000-0"
                  />
                </div>
                <div *ngSwitchCase="'ps'">
                  <input
                    type="text"
                    placeholder="Pasaporte"
                    class="form-control form-control-lg"
                    formControlName="documentNumberOfPersonRecevingInfraction"
                  />
                </div>
                <div *ngSwitchCase="'o'">
                  <input
                    type="text"
                    placeholder="Otro"
                    class="form-control form-control-lg"
                    formControlName="documentNumberOfPersonRecevingInfraction"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Domicilio </label>

              <input
                specialIsAlphaNumeric
                [fControl]="infractionReportForm.get('offenderAddress')"
                type="text"
                placeholder="Domicilio"
                class="form-control form-control-lg"
                formControlName="offenderAddress"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">
                Calidad del Infractor
              </label>
              <el-dropdown
                [options]="offenderQuality"
                formControlName="offenderQualityId"
                customClass="form-control form-control-lg"
                placeHolder="Elige una calidad del infractor"
              ></el-dropdown>
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"
                >¿Firmó el Infractor?
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="infractionReportForm.get('signedInfraction').invalid"
                  >*</small
                >
              </label>
              <el-dropdown
                [options]="options"
                formControlName="signedInfraction"
                customClass="form-control form-control-lg"
                placeHolder="Eliga si o no"
              ></el-dropdown>
            </div>
          </div>
          <hr />
          <label class="form-label form-control-lg sectionTitle"
            >Datos del Receptor
          </label>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Nombre </label>

              <input
                specialIsAlphaNumeric
                [fControl]="infractionReportForm.get('nameReceiverInfraction')"
                type="text"
                placeholder="Nombre"
                class="form-control form-control-lg"
                formControlName="nameReceiverInfraction"
              />
            </div>

            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Tipo de Documento&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <el-dropdown
                [options]="identificationTypes"
                formControlName="documentTypeOfPersonRecevingInfractionId"
                customClass="form-control form-control-lg"
                (onChange)="setRecevingDocumetType()"
                placeHolder="Elige un tipo de documento"
              ></el-dropdown>
            </div>
            <div
              class="col-md col-12 mb-1"
              *ngIf="
                infractionReportForm.get(
                  'documentTypeOfPersonRecevingInfractionId'
                ).value
              "
            >
              <label class="form-label form-control-lg"
                >Doc. Identificación
              </label>
              <div [ngSwitch]="recevingDocCode">
                <div *ngSwitchCase="'c'">
                  <input
                    type="text"
                    placeholder="Cedula"
                    class="form-control form-control-lg"
                    formControlName="offenderDocumentNumber"
                    mask="000-0000000-0"
                  />
                </div>
                <div *ngSwitchCase="'p'">
                  <input
                    type="text"
                    placeholder="Pasaporte"
                    class="form-control form-control-lg"
                    formControlName="documentOfPersonReceving"
                  />
                </div>
                <div *ngSwitchCase="'o'">
                  <input
                    type="text"
                    placeholder="Otro"
                    class="form-control form-control-lg"
                    formControlName="document"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!this.infractionReportForm.get('recordStatus')?.value ? true : false"   class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Justificación de Eliminado</label>
            <textarea
              specialIsAlphaNumeric
              [fControl]="infractionReportForm.get('justification_Reason')"
              type="text"
              maxlength="140"
              readonly
              rows="4"
              placeholder="Justifique el motivo de eliminar el acta de apercibimiento"
              class="form-control form-control-lg mb-3"
              formControlName="justification_Reason"
            ></textarea>
           </div>   
          </div>           

          <div>
            <hr />
            <div class="form-row">
              <label class="form-label form-control-lg sectionTitle"
                >Documentos de Acta
              </label>
            </div>
            <div class="form-row">
              <div class="col">
                <app-attached-documents
                  [infractionReport]="infractionReport"
                  (outputfiles)="selectedFiles = $event"
                  toolbar="documentos Acta"
                >
                </app-attached-documents>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <p class="text-danger" *ngIf="!this.infractionReportForm.get('recordStatus')?.value ? true : false"><i class="fa fa-warning faa-flash animated faa-slow"></i>&nbsp;Este registro no se puede modificar, ya que esta eliminado.</p>
        <button
          *ngIf="this.canEdit && !this.infractionReport"
          type="button"
          class="rounded-pill btn btn-clean px-5"
          data-bs-dismiss="modal"
          (click)="cleanForm()"
        >
          Limpiar
        </button>
        <button
          type="button"
          class="rounded-pill btn btn-save px-5"
          [disabled]="!this.infractionReportForm.get('recordStatus')?.value ? true : false"
          data-bs-dismiss="modal"
          (click)="saveReportData()"
          *ngIf="canEdit"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isFilter; else isTheFilter">
    <div  *ngIf="canJustify" class="centered-content">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Eliminar Acta de Infracción</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="canEdit ? close(false) : close(true)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="centered-content">
            <form [formGroup]="infractionReportForm" [ngClass]="{ cantEdit: !canEdit }">
              <div class="form-row">
                <div class="col-md col-12 mb-1">
                <label class="form-label form-control-lg">
                  <small
                    [@warning]="tool.stateName(show)"
                    class="text-danger"
                    *ngIf="infractionReportForm.get('justification_Reason').invalid"
                    >*Digite una Justificación.</small
                  ></label
                >
                <textarea
                  specialIsAlphaNumeric
                  [fControl]="infractionReportForm.get('justification_Reason')"
                  type="text"
                  maxlength="140"
                  rows="4"
                  placeholder="Justifique el motivo de eliminar el acta de infracción"
                  class="form-control form-control-lg mb-3"
                  formControlName="justification_Reason"
                ></textarea>
              </div>   
              </div>
            </form>
            
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="close(false)"
          >
            Cerrar
          </button>          
          <button
            [disabled]="infractionReportForm.get('justification_Reason').invalid ? true : false"
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="SaveInfractionJustification()"
             
          >
            Guardar
          </button>
        </div>
    
      </div>
    </div>  
  </div>

</div>
<ng-template #isTheFilter>
  <div class="centered-content">
    <form [formGroup]="infractionReportForm" [ngClass]="{ cantEdit: !canEdit }">
      <label class="form-label form-control-lg sectionTitle"
        >Datos de Acta Infracción
      </label>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">No. de Acta </label>
          <input
            type="text"
            placeholder="Numero de acta"
            formControlName="infractionReportNumber"
            class="form-control form-control-lg"
            placeholder="000-000"
          />
        </div>
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Conceptos
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="infractionReportForm.get('conceptId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="concepts"
            formControlName="conceptId"
            customClass="form-control form-control-lg"
            placeHolder="Elige un concepto"
          ></el-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Articulos
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              rows="2"
              *ngIf="infractionReportForm.get('articlesListed').invalid"
              >*</small
            >
          </label>

          <textarea
            specialIsAlphaNumeric
            [fControl]="infractionReportForm.get('articlesListed')"
            type="text"
            placeholder="Articulos"
            class="form-control form-control-lg"
            formControlName="articlesListed"
            [disabled]="infractionReportForm.get('conceptId').invalid"
          ></textarea>
        </div>
      </div>
      <hr />
      <label class="form-label form-control-lg sectionTitle"
        >Datos del Infractor
      </label>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">Nombre </label>

          <input
            specialIsAlphaNumeric
            [fControl]="infractionReportForm.get('offenderName')"
            type="text"
            placeholder="Nombre"
            class="form-control form-control-lg"
            formControlName="offenderName"
          />
        </div>
      </div>

      <hr />
      <label class="form-label form-control-lg sectionTitle"
        >Datos del Receptor
      </label>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">Nombre </label>

          <input
            specialIsAlphaNumeric
            [fControl]="infractionReportForm.get('nameReceiverInfraction')"
            type="text"
            placeholder="Nombre"
            class="form-control form-control-lg"
            formControlName="nameReceiverInfraction"
          />
        </div>
      </div>
    </form>
  </div>
</ng-template>
