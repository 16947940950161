<div class="card mt-4 mb-5 customCard">
  <div class="card-body">
    <table class="table table-borderless tableFichaBasica">
      <thead>
        <tr>
          <th></th>
          <th>NO. SERVICIO JUDICIAL</th>
          <th>ESTADO ACTUAL</th>
          <th>FECHA DE REGISTRO</th>
          <th>ABOGADO PRINCIPAL</th>
          <th>ABOGADO ALTERNO</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span class="icono rounded-circle"><i class="fa fa-info"></i></span>
          </td>
          <td class="codigo"><a [routerLink]="['/Casos/creacionRecord/', record.idAsistenciaDiaria]"
              class="text-decoration-none">{{ record.codigo }}</a></td>
          <td>{{ record?.estado?.descripcion }}</td>
          <td>{{ record.asistenciaDiaria.fechaAlta| date: 'dd/MM/yyyy' }}</td>
          <td>{{ AbogadoPrincipal }}</td>
          <td>{{ AbogadoAlternoNombre }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
