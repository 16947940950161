    <div class="container-fluid mt-5">
      <h2 class="mb-4">Lista de Registros de Servicio</h2>
      <div class="row mb-5">
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label for="" class="mb-2">Entrevistador</label>
            <input type="text" class="form-control" value="{{ this.user.getFullName() }}" disabled>
          </div>
        </div>
        <div class="col-12 col-md-9">
          <div class="input-group buscador">
            <input type="text" class="form-control" (input)="filterInput($event.target.value)"
              placeholder="Buscar por numero o nombre">
            <button class="btn border" type="button" id="button-addon2">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- </form> -->

      <div class="table-responsive">
        <table class="table table-hover" id="recordTable">
          <thead>
            <tr>
              <th><a (click)="sortTables('codigo')">NO. REGISTRO {{sortIcon('codigo')}}</a></th>
              <th><a (click)="sortTables('asistenciaDiaria?.fechaAlta')">FECHA REGISTRO
                  {{sortIcon('asistenciaDiaria?.fechaAlta')}}</a></th>
              <th><a (click)="sortTables('tipoEmpleador')">TIPO EMPLEADOR {{sortIcon('tipoEmpleador')}}</a></th>
              <th>RLT</th>
              <th>ESTADO</th>
              <th>TIPO SOLICITANTE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of recordDataFiltered | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }"
              class="align-middle">


              <td>{{ r.codigo == null ? 'No Asignado' : r.codigo }}</td>
              <td>{{ r.asistenciaDiaria?.fechaAlta | date: 'dd/MM/yyyy' }}</td>
              <td>
                {{
                  r.tipoEmpleador === "FISICA"
                  ? "Persona Fisica"
                  : r.asistenciaDiaria.tipoSolicitante.codigoReferencia === "EMPLEADOR"
                  ? "Empresa Formalizada"
                  : "Datos Laborales"
                }}
              </td>
              <td>
                {{ getRltDescription(r?.asistenciaDiaria?.idRepLocalProvinciaCatalog)}}
              </td>
              <td class="estado">
                <span>
                  {{  r.estado?.descripcion}}
                </span>
              </td>
              <td>{{ r.asistenciaDiaria.tipoSolicitante.descripcion }}</td>
              <td>
                <a [routerLink]="['/Expedientes/crearExpediente/', r.idAsistenciaDiariaEmpleador]"
                  class="btn btnOutlinePrimary">Crear
                  Expediente</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination justify-content-center mt-4">
          <pagination-controls (pageChange)="changePage($event)" previousLabel="Anterior" nextLabel="Siguiente"
            *ngIf="totalPages > 1"></pagination-controls>
        </div>
      </div>
    </div>
