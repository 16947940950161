import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { serviceOrder } from '../_models/serviceOrder';
import { workerData } from '../_models/workerData';
import { state, trigger, style, transition, animate } from '@angular/animations'
import Swal from 'sweetalert2';
import { user } from 'src/app/modules/auth/_models/User';

@Component({
  selector: 'app-order-worker-new',
  templateUrl: './order-worker-new.component.html',
  styleUrls: ['./order-worker-new.component.css']
})
export class OrderWorkerNewComponent implements OnInit {
  
  @Input() companyWorkerId: number;
  @Input() workerData: workerData;
  @Input() serviceOrder: serviceOrder;
  @Output() hasValue = new EventEmitter<boolean>();
  @Input() canEdit: boolean = true;
  @Input() isFilter: boolean = false;
  @Output() itChange = new EventEmitter<boolean>();
  @Output() valid = new EventEmitter<boolean>();
  @Input() assignmentEdit: boolean = false;
  
  workerDataForm: FormGroup;
  activityBranchs: DropDownOptionModel[] = []
  identificationTypes: any[];  
  title: string;
  titleValidation: string;
  documentTypeCode: string;
  show = false
  orderFilter: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form

  constructor(private ngbActiveModal: NgbActiveModal, private fb: FormBuilder, private calendar: NgbCalendar, private ngbDateParserFormatter: NgbDateParserFormatter,
    private HttpService: HttpClientService, private sweet: SweetAlertService,
    private toast: ToastService, public tool: ToolsService, private elem: ElementRef,private User: UserService ) { }

  ngOnInit(): void {
 
    this.getDropdowns()
    this.createForm()
    this.setDocumetType()
    this.workerDataForm.valueChanges.subscribe(
      changes => {
        if (this.workerDataForm.dirty) {
          this.itChange.emit(true)
        }
        this.valid.emit(this.workerDataForm.invalid)
      }
    )
    if (!this.assignmentEdit) {
      this.removeValidators(this.workerDataForm)
    }

  }

  close(direct: boolean) {
    if (direct) {
      this.ngbActiveModal.close('lklk');
    } else {
      this.tool.modalWarning()
        .then((result) => {
          if (result.isConfirmed) {
            this.ngbActiveModal.close();
          }
        })
    }
  }

  sectionSelected: boolean = true
  drop: DropDownOptionModel[] = [{ value: 1, text: 'cedula' }, { value: 2, text: 'pasaporte' }]
  options: DropDownOptionModel[] = [{ value: true, text: 'Sí' }, { value: false, text: 'No' }]
  
  removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }

  identificationValidators(event) {
    if (!this.isFilter) {
      if (event !== 'c') {
        this.workerDataForm.get('workerIdentification').setValidators([Validators.required, Validators.minLength(5)])
        this.workerDataForm.get('workerIdentification').updateValueAndValidity();

      }
      else {
        this.workerDataForm.get('workerIdentification').clearValidators()
        this.workerDataForm.get('workerIdentification').setValidators(Validators.required);
        this.workerDataForm.get('workerIdentification').updateValueAndValidity();

      }
    }
  }
 

  createForm() {
     
    if (this.isFilter) {
      this.workerDataForm = this.fb.group({

        workerName: new FormControl(this.orderFilter ? this.orderFilter.workerName : null),
        identificationTypeId: new FormControl(this.orderFilter ? this.orderFilter.workerIdentificationTypeId : null),
        workerIdentification: new FormControl(this.orderFilter ? this.orderFilter.workerIdentification : null),

      })
      this.hasValue.emit(this.tool.hasFormAnyValue(this.workerDataForm.value))
    }
    else {
      if (!this.workerData) {

        this.title =  "Agregar Trabajador";
        this.titleValidation = "¿Esta seguro que desea agregar este Trabajador?"
        this.workerDataForm = this.fb.group({

          workerName: new FormControl(''),
          identificationTypeId: new FormControl(null),
          workerIdentification: new FormControl(''),
          workerAddress: new FormControl(''),
          workerPhone: new FormControl(''),
          workerExtension: new FormControl(''),
          workerEmail: new FormControl('', [Validators.email]),
        })
        this.valid.emit(false)

      }
      else {
        this.title = "Editar Trabajador";
        this.titleValidation = "¿Esta seguro que desea editar este Trabajador?"
        this.workerDataForm = this.fb.group({
          companyWorkerId: this.workerData.companyWorkerId,
          workerName: new FormControl(this.workerData.workerName),
          identificationTypeId: new FormControl(this.workerData.identificationTypeId),
          workerIdentification: new FormControl(this.workerData.workerIdentification),
          workerAddress: new FormControl(this.workerData.workerAddress),
          workerPhone: new FormControl(this.workerData.workerPhone),
          workerExtension: new FormControl(this.workerData.workerExtension),
          workerEmail: new FormControl(this.workerData.workerEmail, [Validators.email]),
        })

      }
    }

  }

  resetDocument() {
    this.workerDataForm.patchValue({
      'workerIdentification	': ''
    })
  }

  async getDropdowns() {
    this.activityBranchs = await this.HttpService.get<DropDownOptionModel[]>(`TypeOfActivityBranch`).toPromise()
    this.identificationTypes = await this.HttpService.get<any[]>(`TypeOfIdentification`).toPromise()
    this.setDocumetType()

  }
  setDocumetType() {
    let id = this.workerDataForm.get('identificationTypeId')?.value;
    if (id) {
      if (this.identificationTypes) {
        let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField

        this.documentTypeCode = code?.toLowerCase()
        this.identificationValidators(this.documentTypeCode)
      }

    } else {
      this.documentTypeCode = null;
    }

  }

  clean() {
    this.workerDataForm.patchValue({
      workerName: null,
      identificationTypeId: null,
      workerIdentification: null,
    })
  }
mapworkerData(){
  
  let dataItem   = {
    "companyPopulationWorkerId":  this.workerData !== undefined? this.workerData.companyPopulationWorkerId : 0,
    "registeredBy": this.workerData !== undefined? this.workerData.registeredBy : this.User.getUserData().userCode,
    "modifiedBy": this.workerData === undefined? null : this.User.getUserData().userCode,
    "registrationDate": this.workerData !== undefined? this.workerData.registrationDate : new Date(),
    "recordModificationDate": this.workerData === undefined? null : new Date(),
    "recordStatus": this.workerData !== undefined? this.workerData.recordStatus : true,
    "companyWorkerId": this.workerData === undefined? this.companyWorkerId : this.workerData.companyWorkerId,
    "workerName": this.workerDataForm.get('workerName').value,
    "identificationTypeId": this.workerDataForm.get('identificationTypeId').value,
    "workerIdentification": this.workerDataForm.get('workerIdentification').value,  
    "workerAddress": this.workerDataForm.get('workerAddress').value,
    "workerPhone": this.workerDataForm.get('workerPhone').value,
    "workerExtension": this.workerDataForm.get('workerExtension').value,
    "workerEmail": this.workerDataForm.get('workerEmail').value
  };
   
  if(dataItem.companyPopulationWorkerId === 0){
    delete  dataItem.recordModificationDate;
    delete  dataItem.modifiedBy;
    delete  dataItem.recordStatus;
    delete  dataItem.registrationDate;
  } 
 
   
  return dataItem;
}
  onClickSave(){
    
     Swal.fire({
       icon: 'info',
       title: this.titleValidation,
       showDenyButton: true,
       confirmButtonText: `Agregar`,
       denyButtonText: `Cancelar`,
     }).then((result) => {
        document.getElementsByClassName("swal2-confirm")[0].setAttribute('id', 'btnSaveWorkerPopulation');
       if (result.isConfirmed) {

        let params = this.mapworkerData();
        
        if(params.companyPopulationWorkerId === 0){
          this.HttpService.post<workerData>(params, 'ServiceOrderPopulationWorker').subscribe(
            {
              next: response => {
                this.sweet.record('success', response.workerName,`Guardado correctamente`, ``);
                this.close(true);
              }
              , error: err => {
     
                this.toast.error(err, 'error')
     
              }
            }
          )
        }else{
          
          this.HttpService.update<workerData>(params, 'ServiceOrderPopulationWorker').subscribe(response => {
            this.sweet.record('success', params.workerName,`Actualizado correctamente`, ``);
            this.close(true);
            }
          )
          
        }

       } else if (result.isDenied) {
       }
     });


  } 



}
