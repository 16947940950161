<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Filtro avanzado de inspección
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="this.ngbActiveModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="centered-content">
      <app-inspection-report
        #inspeccion
        [isFilter]="true"
      ></app-inspection-report>
    </div>
    <div class="modal-footer mt-2">
      <button
        type="button"
        class="rounded-pill btn btn-clean px-5"
        data-bs-dismiss="modal"
        (click)="inspeccion.clean()"
      >
        Limpiar
      </button>
      <button
        id="toggleBtnInspection"
        type="button"
        class="rounded-pill btn btn-save px-5"
        data-bs-dismiss="modal"
        (click)="buildSearch(inspeccion.inspectionReportForm.value)"
      >
        Guardar
      </button>
    </div>
  </div>
</div>
