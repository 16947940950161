<div class="container-fluid">
  <div class="row h-100" style="height: 100vh !important">
    <div class="col-12 col-md-6 d-none d-lg-block p-0 blueScreen">
      <div class="form-row" style="justify-content: space-evenly; height: 100vh">
        <div class="col h-100 d-flex justify-content-evenly align-items-center">
          <div class="">
            <img src="../../../../assets/images/MT LOGO White.png" class="ministerImg" alt="" />
            <!-- <p class="logoMinister text-center">
              El
              <span>Sistema Integrado de Casos de Inspección del Trabajo</span>
              es <br />
              la herramienta maestra del Ministerio del Trabajo encargada de
              <br />
              administrar, procesar, almacenar y generar reportes.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5 col-sm-12 p-0 hidden">
      <div class="container-fluid h-100" style="margin-left: 12%;">
        <div class="form-row">
          <div class="col-12 d-flex justify-content-evenly align-items-center" style="height: 100vh !important">
            <form [formGroup]="loginForm" style="width: 100%">
              <div class="form-row">
                <div class="col-lg-9 col-md-4 col-sm-12 login m-auto mb-5">

                </div>
              </div>
              <div class="form-row" style="justify-content: space-evenly">
                <div class="col-lg-8 col-md-4 col-12 m-auto" style="margin-top: 29px">
                  <label class="form-label form-control-lg loginLabels">Usuario
                    <small [@warning]="tool.stateName(show)" class="text-danger"
                      *ngIf="!loginForm.get('userName').value">*</small>
                  </label>

                  <input type="text" class="form-control form-control-lg" formControlName="userName"
                    placeholder="Escriba su usuario" />
                </div>
              </div>
              <div class="form-row" style="justify-content: space-evenly; margin-top: 14px">
                <div class="col-lg-8 col-md-4 col-sm-12 mb-2 m-auto">
                  <label class="form-label form-control-lg loginLabels">Contraseña
                    <small [@warning]="tool.stateName(show)" class="text-danger"
                      *ngIf="!loginForm.get('password').value">*</small>
                  </label>

                  <input type="password" class="form-control form-control-lg" formControlName="password"
                    placeholder="Escriba su contraseña" />
                </div>
              </div>
              <div class="form-row text-center" style="justify-content: space-evenly" style="margin-top: 40px">
                <div class="btn-group mx-auto">
                  <div *ngIf="!loading; else loaded">
                    <button type="submit" class="rounded-pill btn tableDeleteButton px-5" data-bs-dismiss="modal"
                      (click)="login()" (keydown.enter)="login()">
                      Ingresar
                    </button>
                  </div>
                  <ng-template #loaded>
                    <button type="button" class="rounded-pill btn tableDeleteButton" style="padding: 6px"
                      [disabled]="true">
                      <div class="spinner-border text-light" role="status"></div>
                    </button>
                  </ng-template>
                </div>
              </div>
              <div class="form-row mt-5" style="justify-content: space-evenly;">
                <!-- <p class="text-center lowerText">¿Presenta algun problema para entrar? <br> Entonces haga <span
                    class="text-clikAqui "> click aqui</span></p> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
