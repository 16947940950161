import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import Swal from 'sweetalert2';
import { inspectionProgram } from './_models/inspectionProgram';
import * as _ from 'lodash';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { user as userModel } from "../../../auth/_models/User"
import { ProgramFilter } from '../advance-Filters/advanced-program-filter/programFilter';
import { InspectionProgramFormComponent } from './inspection-program-form/inspection-program-form.component';
@Component({
  selector: 'app-inspection-program',
  templateUrl: './inspection-program.component.html',
  styleUrls: ['./inspection-program.component.css'], animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class InspectionProgramComponent implements OnInit {
  approved: boolean;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private HttpService: HttpClientService, private user: UserService,
    private sweet: SweetAlertService, private toast: ToastService, private router: Router, public tool: ToolsService, private elem: ElementRef,
    private calendarS: NgbCalendar) { }
  Digitador: any
  canDigitEdit: any
  loading: boolean = true
  isEdit: boolean;
  canEdit: boolean = true;
  get currentDay() {
    let date = new Date()
    return date.setDate(date.getDate() - 1)
  }
  inspectionProgram: inspectionProgram;
  inspectionProgramForm: FormGroup;
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (this.inspectionProgramForm.dirty && !this.inspectionProgram) {
      $event.returnValue = true;
    }
  }




  async ngOnInit() {
    if (this.route.data) {
      this.route.data.subscribe((data: { program: inspectionProgram }) => {
        if (data.program) {
          this.inspectionProgram = data.program

          data.program.approvedInspection ? this.approved = true : this.approved = false

          this.loading = false
        } else {
          this.isEdit = false
          this.loading = false
        }



      })
    }
  }

  saveRecord(inspectionProgram: InspectionProgramFormComponent) {
    inspectionProgram.saveRecord()
  }

  updateRecord() { }

  saveAll(inspectionProgram: InspectionProgramFormComponent) {
    inspectionProgram.saveRecord()
  }
}
