<div class="tab-content" id="pills-tabContent" style="height: 100% !important">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link pb-4"
        id="profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#profile"
        type="button"
        role="tab"
        name="crear colecciones"
        aria-controls="profile"
        aria-selected="false"
        routerLink="./asistenciaJudicial"
        [ngClass]="{ 'is-active': active() }"
      >
        Crear&nbsp;Expediente
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link pb-4"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#home"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
        routerLink="./historial"
        routerLinkActive="is-active"
        name="historial"
      >
        Historial
      </button>
    </li>
  </ul>
  <div class="tab-content h-100" id="myTabContent pt-5">
    <router-outlet></router-outlet>
  </div>
</div>
