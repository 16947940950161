<div class="container-fluid">
  <div class="botones">
    <form [formGroup]="searchForm" class=" row-cols-lg-auto g-3">
      <div class="row">
        <div class="col-12 col-md-3">
          <label for="">Representación Local</label>
          <select formControlName="LocalRep" id="" class="form-select">
            <option value="" selected>
              --Seleccione Representacion Local--
            </option>
            <option *ngFor="let rlt of localRepresentativeDrop" value="{{ rlt.value }}">
              {{ rlt.text }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-3" style="margin-right: -92px;">
          <div class="form-group">
            <label for="">Fecha Desde:</label>
            <input formControlName="sinceDate" type="date" name="" class="form-control w-75" />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label for="">Fecha Hasta:</label>
            <input formControlName="untilDate" type="date" name="" class="form-control w-75" />
          </div>
        </div>
        <div class="col-12 col-md-3 p-4">
          <button type="button" (click)="dateRageFilter()" class="btn btnSearch ">
            <i class="fa fa-search mt-2"></i>
          </button>
          <button type="button" (click)="exportToExcel()" class="btn btnExport mx-4">
            Exportar <i class="fa fa-file-pdf-o mt-2"></i>
          </button>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-md-6">
          <div class="input-group buscador">
            <input type="text" class="form-control" formControlName="textFilter"
              (input)="filterInput($event.target.value)" placeholder="Búsqueda por palabra clave">
            <button class="btn border btn-busquedad" type="button" id="button-addon2">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <button (click)="openBusquedaAvanzada()" type="button" class="btn btnBusquedad" id="btnBusquedad"
            data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Búsqueda Avanzada
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive mb-5">
    <table class="table table-hover" id="recordTableHistory">
      <thead>
        <tr>
          <th><a (click)="sortByCodeNumber('codigo')">NO. EXPEDIENTE {{sortIcon('codigo')}}</a></th>
          <th><a (click)="sortByDate('fechaAlta')">FECHA REGISTRO {{sortIcon('fechaAlta')}}</a></th>
          <th>TIPO EMPLEADOR</th>
          <th>RLT</th>
          <th>TIPO SOLICITANTE</th>
          <th>ESTADO</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="
            let r of recordDataFiltered
              | paginate
                : { itemsPerPage: itemPerPage, currentPage: currentPage }
          " class="align-middle">
          <td>{{ r.codigo }}</td>
          <td>{{ r.fechaAlta | date : "dd/MM/yyyy" }}</td>
          <td>
            {{
              r.asistenciaDiariaEmpleador.tipoEmpleador === "FISICA"
              ? "Persona Fisica"
              : r.asistenciaDiariaEmpleador.asistenciaDiaria.tipoSolicitante.codigoReferencia === "EMPLEADOR"
              ? "Empresa Formalizada"
              : "Datos Laborales"
            }}
          </td>
          <td>{{ getRltDescription(r?.asistenciaDiariaEmpleador?.asistenciaDiaria?.idRepLocalProvinciaCatalog) }}</td>
          <td>
            {{
              r?.asistenciaDiariaEmpleador?.asistenciaDiaria?.tipoSolicitante
                ?.descripcion
            }}
          </td>
          <td class="estado">
            <span>{{ r?.estado?.descripcion || r?.estado }}</span>
          </td>

          <td>
            <a [routerLink]="[
                '/Expedientes/crearExpediente/',
                r.idAsistenciaDiariaEmpleador
              ]" class="btn btnOutlinePrimary">
              <i class="fa fa-eye fs-4" aria-hidden="true"></i>
            </a>
            <a pTooltip="Compartir" class="btn btnOutlinePrimary" href="mailto:someone@example.com">
              <i class="fa fa-share-alt fs-4" aria-hidden="true"></i>
            </a>
            <a pTooltip="Print PDF" class="btn btnOutlinePrimary" (click)="pressDowloadPdf(r.codigo)">
              <i class="fa fa-file-pdf-o fs-4" aria-hidden="true"></i>
            </a>
          </td>
          <td>
            <button type="button" id="reopenCaseButton" *ngIf="r?.estado?.codigoReferencia === 'CERR' && this.user?.roleCode === 'ENCA'"
              class="btn btn-reaperturaCaso" (click)="openModal(r.idExpediente)">
              Re-apertura de Caso
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination justify-content-center mt-4">
      <pagination-controls (pageChange)="changePage($event)" previousLabel="Anterior" nextLabel="Siguiente"
        *ngIf="totalPages > 1"></pagination-controls>
    </div>
  </div>
</div>
