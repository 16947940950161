import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { serviceOrder } from '../_models/serviceOrder';
import { ServiceOrderAssignment } from '../_models/ServiceOrderAssignment';
import { emailAssignment } from './_models/emailAssignment';
import { user as userModel } from '../../../auth/_models/User';
import * as moment from 'moment';
import { companyEdit } from '../company-data/companyEdit.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';
@Component({
  selector: 'app-order-assignment',
  templateUrl: './order-assignment.component.html',
  styleUrls: ['./order-assignment.component.css']
})
export class OrderAssignmentComponent implements OnInit {
  constructor(private calendar: NgbCalendar, private fb: FormBuilder,
    private HttpService: HttpClientService, private ngbDateParserFormatter: NgbDateParserFormatter,
    private user: UserService, private companyEditS: companyEdit, private tool: ToolsService
  ) { }

  @Input() orderAssignment: ServiceOrderAssignment;
  @Input() serviceOrder: serviceOrder;
  @Output() createdOrderAssignment = new EventEmitter<ServiceOrderAssignment>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() itChange = new EventEmitter<boolean>();
  @Output() hasValue = new EventEmitter<boolean>();

  @Input() canEdit: boolean = true;
  @Input() isFilter: boolean = false
  @Input() isCompanyData: boolean = true;
  inspectorChanged: boolean;
  orderEdit: ServiceOrderAssignment;
  localRepresentative: any[] = []
  Groups: DropDownOptionModel[]
  supervisors: DropDownOptionModel[] = []
  inspectors: DropDownOptionModel[] = []
  assignmentForm: FormGroup;
  currentUser: userModel = this.user.getUserData();
  orderFilter: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form
  get currentDay() {
    let date = moment().format('YYYY-MM-DD')
    return date
  }

  checkPermission() {
    let user = this.user.getUserData();
    if (user.roleInfo == 'Inspector' || user.roleInfo == 'Digitador') {
      return true
    }
    return false
  }
  ngOnInit(): void {

    this.getDropdowns()
    this.createForm()
    if (!this.currentUser.checkCode('G')) {

    }
    this.assignmentForm.valueChanges.subscribe(
      changes => {
        this.valid.emit(this.assignmentForm.invalid)
        this.orderEdit = this.assignmentForm.value;
        if (this.assignmentForm.dirty) {
          this.itChange.emit(true)
        }

        if (this.assignmentForm.valid) {
          //this.mapDates()
          this.createdOrderAssignment.emit(this.assignmentForm.value)
        }
      }
    )
    this.createdOrderAssignment.emit(this.assignmentForm.value)
    if(this.isCompanyData){
      this.removeValidators(this.assignmentForm);
    }

    // if( this.checkPermission){
    //   this.assignmentForm.clearValidators();
    //   this.assignmentForm.updateValueAndValidity()
    // }
  }



  createForm() {
    if (this.isFilter) {
      this.assignmentForm = this.fb.group({
        localRepresentativeId: new FormControl(this.orderFilter ? this.orderFilter.assignRepLocalId : null),
        supervisorId: new FormControl(this.orderFilter ? this.orderFilter.supervisorId : null),
        groupId: new FormControl(this.orderFilter ? this.orderFilter.groupId : null),

      })
      this.selectedDropdowns()
      this.hasValue.emit(this.tool.hasFormAnyValue(this.assignmentForm.value))
    }
    else {
      if (!this.orderAssignment) {

        this.assignmentForm = this.fb.group({
          serviceOrderNumber: new FormControl(null),
          serviceOrderAssignmentId: new FormControl(0),
          localRepresentativeId: new FormControl(null, Validators.required),
          supervisorId: new FormControl(null, Validators.required),
          assignedInspectorId: new FormControl(null, Validators.required),
          groupId: new FormControl(null),
          modifyBy: this.user.getUserData().userCode,
          registeredBy: this.user.getUserData().userCode,
          recordStatus: true
        })
        this.valid.emit(true)
      }
      else {


        this.assignmentForm = this.fb.group({
          serviceOrderNumber: new FormControl(this.serviceOrder.serviceOrderNumber),
          serviceOrderAssignmentId: new FormControl(this.orderAssignment.serviceOrderAssignmentId),
          localRepresentativeId: new FormControl(this.orderAssignment.localRepresentativeId, Validators.required),
          supervisorId: new FormControl(this.orderAssignment.supervisorId, Validators.required),
          assignedInspectorId: new FormControl(this.orderAssignment.assignedInspectorId, Validators.required),
          justificationInspectorChange: new FormControl(this.orderAssignment.justificationInspectorChange),
          groupId: new FormControl(this.orderAssignment.groupId),
          registrationDate: new FormControl(this.orderAssignment.fechaRegistro),
          recordModificationDate: new FormControl(this.currentDay),
          registeredBy: new FormControl(this.orderAssignment.registradoPor),
          modifiedBy: new FormControl(this.user.getUserData().userCode),

          recordStatus: new FormControl(this.orderAssignment.estatusRegistro)
        })

      }
    }
    this.selectedDropdowns();

    if (this.orderAssignment?.justificationInspectorChange) {
      this.inspectorChanged = true
      this.assignmentForm.get('justificationInspectorChange').setValidators([Validators.required])
      this.assignmentForm.get('justificationInspectorChange').updateValueAndValidity();
    }
  }

  removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }


  async selectedDropdowns() {
    if (!this.isFilter) {
      if (this.orderAssignment?.localRepresentativeId) {
        let supervisors = await this.HttpService.get<any>(`User/SupervisorsByLocalRepresentativeUser/${this.orderAssignment.localRepresentativeId}`).toPromise()
        this.supervisors = supervisors;
        let inspectors = await this.HttpService.get<any>(`User/InspectorsBySupervisor/${this.orderAssignment.supervisorId}`).toPromise()
        this.inspectors = inspectors;
      }
    } else {
      if (this.assignmentForm.get('localRepresentativeId').value) {
        let rep = this.assignmentForm.get('localRepresentativeId').value
        let supervisors = await this.HttpService.get<any>(`User/SupervisorsByLocalRepresentativeUser/${rep}`).toPromise()
        this.supervisors = supervisors;
      }
    }
  }

  async getDropdowns() {
    this.HttpService.get<DropDownOptionModel[]>(`Group`).subscribe((response) => { this.Groups = response });
    if (!this.isFilter) {
      this.HttpService.get<any[]>(`User/UserByRolAndRepresentant/?rolId=4&represenLocaId=${this.serviceOrder.repLocalProvId}`).subscribe((response) => {
        this.localRepresentative = response.map(LR => {
          return { text: LR.fullName, value: LR.userId }
        });
      });

    }
    else {
      this.HttpService.get<any[]>(`User/UserByRolAndRepresentant/?rolId=4`).subscribe((response) => {
        this.localRepresentative = response.map(LR => {
          return { text: LR.fullName, value: LR.userId }
        });
      });

    }
  }

  async representativeDependency(event) {
    this.supervisors = [];
    let data = await this.HttpService.get<any>(`User/SupervisorsByLocalRepresentativeUser/${event}`).toPromise()
    this.supervisors = data;
    this.assignmentForm.patchValue({
      'supervisorId': null,
      'assignedInspectorId': null
    })
  }

  async supervisorDependency(event) {
    this.inspectors = [];
    let data: [] = await this.HttpService.get<any>(`User/InspectorsBySupervisor/${event}`).toPromise()
    let mapData = data.map((el: any) => {
      return {
        text: `${el.text} (${el.numberOfCase} Caso (s))`,
        value: el.value,
      }
    })
    this.inspectors = mapData;
    this.assignmentForm.patchValue({
      'assignedInspectorId': null
    })


  }

  asignInspector() {
    
    if (this.orderAssignment && this.user.getUserData().roleCode == 'SUP') {
      this.companyEditS.subject.next(true);
    }
    if (this.orderAssignment) {
      this.checkInspectorChange(this.assignmentForm.value)
    }
  }
  // sendNotification() {

  //   let email: emailAssignment = {
  //     serviceOrderNumber: this.serviceOrder.serviceOrderNumber,
  //     deadline: this.serviceOrder.estimatedDeadLine,
  //     userCode: this.user.getUserData().userCode,
  //     assignmentUserId: this.orderAssignment.assignedInspectorId,
  //     isReassignment: false,
  //     textCurrentOrderStatus: this.serviceOrder.statusOrderServiceInfo


  //   }
  //   this.HttpService.post<emailAssignment>(email, 'EmailSender/SendNotificationAssignmentInspector')
  // }

  checkInspectorChange(currentOrder: ServiceOrderAssignment) {

    if (this.orderAssignment.assignedInspectorId !== currentOrder.assignedInspectorId) {
      this.inspectorChanged = true;
      this.assignmentForm.get('justificationInspectorChange').setValidators([Validators.required])
      this.assignmentForm.get('justificationInspectorChange').updateValueAndValidity();

    }
    else {
      this.inspectorChanged = false;
      this.assignmentForm.get('justificationInspectorChange').clearValidators()
      this.assignmentForm.get('justificationInspectorChange').updateValueAndValidity();
    }
  }


  clean() {
    this.assignmentForm.patchValue({
      localRepresentativeId: null,
      supervisorId: null,
      groupId: null,

    })

  }
}
