<form [formGroup]="expedientNotasForm" (ngSubmit)="validateEstadoExpedientNotas()" class="form">
  <div class="row mb-3">
    <div class="col-12 col-md-12">
      <div class="form-group">
        <label for="">Observaciones</label>
        <input formControlName="notas" type="text" maxlength="180" class="form-control">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12">
      <button type="submit" [disabled]="expedientCerrado"
        class="btn btnSubmit float-end btn-guardar btnListo">Listo</button>
    </div>
  </div>
</form>
