import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { RequestOptions } from '@angular/http';
import { NgbActiveModal, NgbDateParserFormatter, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { InfractionReportService } from './infraction-report.service';
import { infractionDocument } from './_models/infractionDocument';
import { infractionReport } from './_models/infractionReport';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';
import { DropdownModule } from 'primeng/dropdown';
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null
      ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
      : null;
  }
}
@Component({
  selector: 'app-infraction-report',
  templateUrl: './infraction-report.component.html',
  styleUrls: ['./infraction-report.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],

})


export class infractionReportComponent implements OnInit {
  constructor(private fb: FormBuilder, private user: UserService, private ngbActiveModal: NgbActiveModal,
    private HttpService: HttpClientService, private sweet: SweetAlertService,
    private infractionData: InfractionReportService, private toast: ToastService,
    public tool: ToolsService, private elem: ElementRef) { }
  serviceOrderNumber: string;
  infractionReport: infractionReport;
  infractionReportForm: FormGroup;
  infractionCopy: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  identificationTypes: any[]
  title = ''
  orderFilter: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form

  show: boolean = false;
  offenderQuality: DropDownOptionModel[]
  concepts: any[] = []
  articles: any[]
  time = { hour: 13, minute: 30 };
  meridian = true;
  selectedFiles: any[]
  documentTypeCode: string;
  recevingDocCode: string;
  @Input() isDuplicate: boolean = false;
  @Output() edit = new EventEmitter<boolean>();
  @Input() canEdit: boolean = true;
  @Input() isFilter: boolean = false
  @Output() hasValue = new EventEmitter<boolean>();
  canJustify: boolean;

  minDate: any;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  options: DropDownOptionModel[] = [{ value: true, text: 'Sí' }, { value: false, text: 'No' }]
  get currentDay() {
    let date = new Date()
    return date.setDate(date.getDate() - 1)
  }


  setDocumetType() {
    let id = this.infractionReportForm.get('offenderDocumentTypeId').value;
    if (id) {
      let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField
      this.documentTypeCode = code.toLowerCase()

    } else {
      this.documentTypeCode = null;
    }
  }

  setRecevingDocumetType() {
    let id = this.infractionReportForm.get('documentTypeOfPersonRecevingInfractionId').value;
    if (id) {
      let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField
      this.recevingDocCode = code.toLowerCase()

    } else {
      this.recevingDocCode = null;
    }
  }

  async ngOnInit() {

    this.createForm();
    this.getDropdowns();

  }

  createForm() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar Todos',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true,
      searchPlaceholderText: 'Buscar'

    };
    if (this.isFilter) {
      this.infractionReportForm = this.fb.group({
        infractionReportNumber: new FormControl(this.orderFilter ? this.orderFilter.infractionReportNumber : null),
        conceptId: new FormControl(this.orderFilter ? this.orderFilter.conceptIdInfractionReport : null),
        articlesListed: new FormControl(this.orderFilter ? this.orderFilter.articlesListed : null),
        offenderName: new FormControl(this.orderFilter ? this.orderFilter.offenderName : null),
        nameReceiverInfraction: new FormControl(this.orderFilter ? this.orderFilter.nameReceiverInfraction : null),
        justification_Reason: new FormControl(null, (this.canJustify === true? [Validators.required, Validators.minLength(5)]: null))
      })
      this.hasValue.emit(this.tool.hasFormAnyValue(this.infractionReportForm.value))
    }
    else {
      if (this.isDuplicate) {
        this.title = "Duplicar Acta de Infracción"
        this.serviceOrderNumber = this.infractionReport.serviceOrderNumber;
        this.infractionReportForm = this.fb.group({
          serviceOrderNumber: new FormControl(this.infractionReport.serviceOrderNumber),
          infractionReportId: new FormControl(0),
          infractionReportNumber: new FormControl(null),
          reportDate: new FormControl(null, [Validators.required, this.tool.dateValidator]),
          reportHour: new FormControl(null),
          conceptId: new FormControl(null, Validators.required),
          offenderName: new FormControl(this.infractionReport.offenderName),
          offenderDocumentTypeId: new FormControl(this.infractionReport.offenderDocumentTypeId),
          offenderDocumentNumber: new FormControl(this.infractionReport.offenderDocumentNumber),
          offenderQualityId: new FormControl(this.infractionReport.offenderQualityId),
          offenderAddress: new FormControl(this.infractionReport.offenderAddress),
          nameReceiverInfraction: new FormControl(this.infractionReport.nameReceiverInfraction),
          documentTypeOfPersonRecevingInfractionId: new FormControl(this.infractionReport.documentTypeOfPersonRecevingInfractionId),
          documentNumberOfPersonRecevingInfraction: new FormControl(this.infractionReport.documentNumberOfPersonRecevingInfraction),
          articlesListed: new FormControl(null, Validators.required),
          facts: new FormControl(null),
          signedInfraction: new FormControl(this.infractionReport.signedInfraction, Validators.required),
          registeredBy: new FormControl(this.infractionReport.registradoPor),
          registrationDate: new FormControl(this.infractionReport.fechaRegistro),
          modifiedBy: new FormControl(this.user.getUserData().userCode),
          recordModificationDate: this.currentDay,
          recordStatus: new FormControl(this.infractionReport.estatusRegistro)
 
        })
        this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date };
      }
      else if (!this.infractionReport) {
        this.title = "Crear Acta de Infracción"
        this.infractionReportForm = this.fb.group({
          serviceOrderNumber: new FormControl(this.serviceOrderNumber),
          infractionReportId: new FormControl(0),
          infractionReportNumber: new FormControl(null),
          reportDate: new FormControl(null, [Validators.required, this.tool.dateValidator]),
          reportHour: new FormControl(null),
          offenderName: new FormControl(null),
          offenderDocumentTypeId: new FormControl(null),
          offenderDocumentNumber: new FormControl(null),
          offenderQualityId: new FormControl(null),
          offenderAddress: new FormControl(null),
          nameReceiverInfraction: new FormControl(null),
          documentTypeOfPersonRecevingInfractionId: new FormControl(null),
          documentNumberOfPersonRecevingInfraction: new FormControl(null),
          conceptId: new FormControl(null, Validators.required),
          articlesListed: new FormControl(null, Validators.required),
          facts: new FormControl(null),
          signedInfraction: new FormControl(null, Validators.required),
          registeredBy: new FormControl(!this.isFilter ? this.user.getUserData().userCode : null),
          modifiedBy: new FormControl(null),
          recordStatus: new FormControl(true),
          registrationDate: !this.isFilter ? this.currentDay : null,
          recordModificationDate: !this.isFilter ? this.currentDay : null,
          justification_Reason: new FormControl(null, (this.canJustify === true? [Validators.required, Validators.minLength(5)]: null))
          // attachedDocument:AttachedDocument,
        })

        if (this.isFilter) {
          this.tool.removeValidators(this.infractionReportForm)
        }
        this.infractionCopy = _.cloneDeep(this.infractionReportForm)
        this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date };

      }
      else {

        this.title = "Editar Acta de Infracción"
        this.serviceOrderNumber = this.infractionReport.serviceOrderNumber;
        this.infractionReportForm = this.fb.group({
          serviceOrderNumber: new FormControl(this.infractionReport.serviceOrderNumber),
          infractionReportId: new FormControl(this.infractionReport.infractionReportId),
          infractionReportNumber: new FormControl(this.infractionReport.infractionReportNumber, Validators.required),
          reportDate: new FormControl(this.tool.dateToObject(this.infractionReport.reportDate), [Validators.required, this.tool.dateValidator]),
          reportHour: new FormControl(this.infractionReport.reportHour),
          conceptId: new FormControl(this.infractionReport.conceptId, Validators.required),
          offenderName: new FormControl(this.infractionReport.offenderName),
          offenderDocumentTypeId: new FormControl(this.infractionReport.offenderDocumentTypeId),
          offenderDocumentNumber: new FormControl(this.infractionReport.offenderDocumentNumber),
          offenderQualityId: new FormControl(this.infractionReport.offenderQualityId),
          offenderAddress: new FormControl(this.infractionReport.offenderAddress),
          nameReceiverInfraction: new FormControl(this.infractionReport.nameReceiverInfraction),
          documentTypeOfPersonRecevingInfractionId: new FormControl(this.infractionReport.documentTypeOfPersonRecevingInfractionId),
          documentNumberOfPersonRecevingInfraction: new FormControl(this.infractionReport.documentNumberOfPersonRecevingInfraction),
          articlesListed: new FormControl(this.tool.JsonToObject(this.infractionReport.articlesListedJson), Validators.required),
          facts: new FormControl(this.infractionReport.facts),
          signedInfraction: new FormControl(this.infractionReport.signedInfraction, Validators.required),
          registeredBy: new FormControl(this.infractionReport.registradoPor),
          registrationDate: new FormControl(this.infractionReport.fechaRegistro),
          modifiedBy: new FormControl(this.user.getUserData().userCode),
          recordModificationDate: this.currentDay,
          recordStatus: new FormControl(this.infractionReport.estatusRegistro),
          justification_Reason: new FormControl(this.infractionReport.justification_Reason,(this.canJustify === true? [Validators.required, Validators.minLength(5)]: null))

          // attachedDocument:AttachedDocument,
        })
        this.minDate = null

      }
    }
  }
  refreshMin() {
    this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date };

  }

  getDropdowns() {
    this.HttpService.get<any[]>(`TypeOfIdentification`).subscribe((response) => {
      this.identificationTypes = response
      if (!this.isFilter) {
        this.setDocumetType()
        this.setRecevingDocumetType()
      }
    });
    this.HttpService.get<DropDownOptionModel[]>(`QualityOfTheNotified?status=true`).subscribe((response) => { this.offenderQuality = response });
    this.HttpService.get<DropDownOptionModel[]>(`ConceptOfReport?recordStatus=true`).subscribe((response) => {
      this.concepts = response
      this.getArticles();
    });
  }
  SaveInfractionJustification(){
    if(this.infractionReportForm.valid){
      if(this.user.getUserData().roleCode !== 'REPLO'){
        this.sweet.record('warning', (this.infractionReportForm.get('infractionReportNumber').value  ===""?"Esta acta de infracción": this.infractionReportForm.get('infractionReportNumber').value ),`Solo puede ser eliminada por un Representante Local!`, ``);
      }else{
        if(!this.infractionReportForm.get('recordStatus').value){
          this.sweet.record('warning', `Esta acta ${this.infractionReportForm.get('infractionReportNumber').value}`,`Ya ha sido eliminada!`, `Se recomienda registrar una nueva en caso de ser necesario.`);
        }else{        
          Swal.fire({
            icon: 'warning',
            title: '¿Esta seguro que desea eliminar esta acta de infracción?',
            showDenyButton: true,
            confirmButtonText: `Eliminar`,
            denyButtonText: `Cancelar`,
          }).then((result) => {
            document.getElementsByClassName("swal2-confirm")[0].setAttribute('id', 'btnEditSaveInfractionReport');
              if (result.isConfirmed) {
                
                this.HttpService.patch<infractionReport>(this.infractionReportForm.value, `InfractionReport/${this.infractionReportForm.get('infractionReportId')?.value}?registrationStatus=false&userCode=${this.user.getUserData().userCode}&justification_Reason=${this.infractionReportForm.get('justification_Reason').value}`).subscribe(
                  {
                    next: response => {
                      
                      this.sweet.record('success', this.infractionReportForm.get('infractionReportNumber').value,` Ha sido eliminado correctamente`, ``);
                      this.close(true);
                        
                    }
                    , error: err => {
            
                      this.toast.error(err, 'error')
            
                    }
                  }
                )
              } else if (result.isDenied) {
              }
            
  
    
          });
  
       }          
      }  

    }else{
      this.sweet.record('warning', 'Favor verificar que este toda la información completada o intente nuevamente en unos minutos!',``, ``);
    }   
  }

  async saveReportData() {
    if (this.infractionReportForm.valid) {
      if (this.infractionReport && !this.isDuplicate) {
        try {
           
          this.selectedFiles ? await this.saveDocuments(this.selectedFiles) : '';
          this.HttpService.update<infractionReport>(this.mapinfractionReport(this.infractionReportForm.value), "InfractionReport").subscribe(
            response => {
              this.close(true);
              this.edit.emit(true);
              this.sweet.record('success', `Registro No.${this.infractionReport.infractionReportNumber}`, 'Su acta se ha actualizado correctamente', ``)
              this.infractionData.subject.next(true)
            }
          )
        }
        catch(e) {
           
          this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
        }
      }

      else {
         
        this.HttpService.post<infractionReport>(this.mapinfractionReport(this.infractionReportForm.value), "InfractionReport").subscribe(
          {
            next: response => {
              this.close(true);
              this.selectedFiles ? this.saveDocuments(this.selectedFiles, response.infractionReportNumber) : '';
              this.edit.emit(true);
              this.sweet.record('success', `Registro No.${response.infractionReportNumber}`, 'Su acta se ha registrado correctamente', `Este caso se ha añadido a su historial de actas`)

              this.infractionData.subject.next(true)

            },
            error: error => {
              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
            }
          }
        )

      }
    } else {
      this.tool.createWarning(this.infractionReportForm, this.elem).then(
        result => this.notFound()
      )
    }

  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }
  close(direct?: boolean) {
    if (direct) {
      this.ngbActiveModal.close();
    } else {
      this.tool.modalWarning().then((result) => {
        if (result.isConfirmed) {
          this.ngbActiveModal.close();
        }
      })

    }
  }
  async saveDocuments(doc, infractionReport?: string) {
    if (doc.length > 0) {
      await this.HttpService.post<infractionReport>(this.MapDocuments(doc, infractionReport), 'InfractionReportDocuments').toPromise()

      let file: File = doc[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      let Headers: any = new HttpHeaders();
      /** In Angular 5, including the header Content-Type can invalidate your request */
      Headers.append('Content-Type', 'multipart/form-data');
      Headers.append('Accept', 'application/json');
      let options: any = new RequestOptions({ headers: Headers });

    }


  }


  checkExtension() {

    if ((this.infractionReportForm.get('extensionDays').value >= 1)) {
      this.infractionReportForm.get('extensionJustification').setValidators([Validators.required]);
      this.infractionReportForm.get('extensionJustification').updateValueAndValidity();
    }
    else {

      this.infractionReportForm.get('extensionJustification').clearValidators();
      this.infractionReportForm.get('extensionJustification').updateValueAndValidity();
    }

  }

  JsonToObject(json: string) {

    if (json) {
      let res: DropDownOptionModel[] = JSON.parse(json);
      return res.map(item => {
        return {
          value: Number(item.value),
          text: item.text
        }
      });
    }
    return null
  }



  mapinfractionReport(infractionReport: infractionReport) {
    let articlesListed = infractionReport.articlesListed.map((motivo) => {
      return motivo['value'];
    });

    let mapWarning = {

      reportDate: new Date(`${infractionReport.reportDate['year']}-${infractionReport.reportDate['month']}-${infractionReport.reportDate['day']}`),
      registrationDate: new Date(),
      recordModificationDate: new Date(),
      articlesListed: articlesListed.join(','),

    }
    return { ...infractionReport, ...mapWarning }

  }


  MapDocuments(files: File[], infractionReport?: string) {

    let document: infractionDocument;
    document = {
      evidenceFiles: files,
      documentId: 0,
      modifiedBy: this.user.getUserData().userCode,
      serviceOrderNumber: this.serviceOrderNumber,
      infractionReportNumber: infractionReport ? infractionReport : this.infractionReport.infractionReportNumber,
      registrationDate: new Date(),
      registeredBy: this.user.getUserData().userCode,
      registerStatus: true,
      recordModificationDate: null
    }
    let form = new FormData()
    for (let file of document.evidenceFiles) {
      form.append('evidenceFiles', file);
    }

    form.append('documentId', '0')
    form.append('modifiedBy', document.modifiedBy)
    form.append('infractionReportNumber', document.infractionReportNumber)
    form.append('serviceOrderNumber', document.serviceOrderNumber)
    form.append('registeredBy', document.registeredBy)
    form.append('registerStatus', 'true')
    form.append('registrationDate', '')
    form.append('recordModificationDate', '')
    return form
  }

  cleanForm() {
    if (!this.isFilter) { this.infractionReportForm.reset(this.infractionCopy.value) }
    else {
      this.infractionReportForm.patchValue({
        infractionReportNumber: null,
        conceptId: null,
        articles: null,
        offenderName: null,
        nameReceiverInfraction: null,
      })
    }
  }

  getArticles(dropdown?: boolean) {
    if (dropdown) {
      this.infractionReportForm.patchValue({
        articlesListed: null
      })
    }
    let conceptId = this.infractionReportForm.get('conceptId').value
    let conceptCode = this.concepts.find(el => el.value == conceptId)?.alternateField;

    this.HttpService.get<any[]>(`ArticleMinute?conceptsCode=${conceptCode}`)
      .subscribe(el => this.articles = el)
  }
}

