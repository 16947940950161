import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestOptions } from '@angular/http';
import { NgbActiveModal, NgbDateParserFormatter, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { WarningReportService } from './warning-report.service';
import { warningDocument } from './_models/warningDocuments';
import { warningReport } from './_models/warningReport';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { WarningDocumentService } from './warning-document.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';
import { AttachedDocumentsComponent } from '../attached-documents/attached-documents.component';
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null
      ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
      : null;
  }
}
@Component({
  selector: 'app-warning-report',
  templateUrl: './warning-report.component.html',
  styleUrls: ['./warning-report.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class WarningReportComponent implements OnInit {

  constructor(private fb: FormBuilder, private user: UserService, private ngbActiveModal: NgbActiveModal,
    private HttpService: HttpClientService, private sweet: SweetAlertService,
    private warningService: WarningReportService, private toast: ToastService, private WD: WarningDocumentService,
    public tool: ToolsService, private elem: ElementRef) { }
  @Input() updateSingleDocument: boolean = false;
  @Input() serviceOrderNumber: string;
  @Input() document: any;
  @Input() canEdit: boolean = true;
  @Input() isFilter: boolean = false
  @Output() hasValue = new EventEmitter<boolean>();
  warningReport: warningReport;
  warningReportForm: FormGroup;
  WarningCopy: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  identificationTypes: any[]
  title = ''
  extension: boolean = false;
  orderFilter: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form
  canJustify: boolean;
  notifiedQuality: DropDownOptionModel[]
  articles: DropDownOptionModel[]
  concepts: any[] = []
  documentStatus: any[] = []
  time = { hour: 13, minute: 30 };
  meridian = true;
  selectedFiles: any[] = []
  documentForm: FormGroup;
  documentFormCopy: FormGroup;
  @Output() edit = new EventEmitter<boolean>();
  isDecline: boolean = false
  minDate: any;
  show: boolean = false;
  maxDate = { year: moment().year(), month: moment().month() + 3, day: moment().date() }
  documentTypeCode: string;
  notifiedDocCode: string;
  get currentDay() {
    let date = moment().format('YYYY-MM-DD')
    return date
  }
  saving: boolean = false;
  setDocumetType() {
    let id = this.warningReportForm?.get('documentTypeId')?.value;
    if (id) {
      let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField
      this.documentTypeCode = code.toLowerCase()

    } else {
      this.documentTypeCode = null;
    }
  }

  setNotifiedDocumetType() {
    let id = this.warningReportForm?.get('documentTypeOfPersonRecevingId').value;
    if (id) {
      let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField
      this.notifiedDocCode = code.toLowerCase()

    } else {
      this.notifiedDocCode = null;
    }
  }

  getStatus() {
    if (!this.updateSingleDocument) {
      this.documentForm.patchValue({
        'documentStatusId': this.documentStatus.find(status => status.hasOptionalField == true).value
      })
    }
  };
  ngOnInit(): void {
    this.minDate = !this.warningReport ? { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date } : null

  

    this.createForm()
    if (this.documentStatus.find(status => status.value == this.document.recordStatus)?.hasOptionalField) {

      this.canEdit = false

    }
    this.getDropdowns()
  }

  createForm() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar Todos',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true,
      searchPlaceholderText: 'Buscar'

    };
    if (this.isFilter) {
      this.warningReportForm = this.fb.group({
        warningReportNumber: new FormControl(this.orderFilter ? this.orderFilter.warningReportNumber : null)
      })
      this.hasValue.emit(this.tool.hasFormAnyValue(this.warningReportForm.value))
    }
    else {
      if (!this.warningReport && !this.updateSingleDocument) {

        this.title = "Crear Acta de Apercibimiento"
        this.warningReportForm = this.fb.group({
          serviceOrderNumber: new FormControl(!this.isFilter ? this.serviceOrderNumber : null),
          warningReportId: new FormControl(!this.isFilter ? 0 : null),
          warningReportNumber: new FormControl(null),
          notifiedName: new FormControl(null),
          documentTypeId: new FormControl(null),
          document: new FormControl(null),
          documentTypeOfPersonRecevingId: new FormControl(null),
          documentOfPersonReceving: new FormControl(null),
          notifiedQualityId: new FormControl(null),
          notifiedAddress: new FormControl(null),
          nameOfPersonReceving: new FormControl(null),
          qualityOfPersonRecevingId: new FormControl(null),
          registeredBy: new FormControl(!this.isFilter ? this.user.getUserData().userCode : null),
          modifiedBy: new FormControl(null),
          recordStatus: new FormControl(true),
          registrationDate: !this.isFilter ? this.currentDay : null,
          recordModificationDate: !this.isFilter ? this.currentDay : null,
          justification_Reason: new FormControl(null, (this.canJustify === true? [Validators.required, Validators.minLength(5)]: null))
          // attachedDocument:AttachedDocument,
        })

        if (!this.isFilter) {
          this.tool.removeValidators(this.warningReportForm)
        }
        this.documentForm = this.fb.group({
          reportDate: new FormControl(null, this.tool.dateValidator),
          reportHour: new FormControl(null),
          conceptListed: new FormControl([]),
          articlesListed: new FormControl(null),
          newTermEndDate: new FormControl(null),
          extensionDays: new FormControl(null),
          termEndDate: new FormControl(null),
          termDays: new FormControl(0),
          comment: new FormControl(null),
          reportNumber: new FormControl(null),
          extensionJustification: new FormControl(null),
          documentStatusId: new FormControl(null),
          registrationDate: new FormControl(this.currentDay)
        })
        this.documentFormCopy = _.cloneDeep(this.documentForm);
        this.WarningCopy = _.cloneDeep(this.warningReportForm);
        this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }
      }
      else if (this.updateSingleDocument) {

        this.title = 'Editando Documento'
        this.documentForm = this.fb.group({
          reportDate: new FormControl(this.tool.dateToObject(this.document.reportDate), this.tool.dateValidator),
          reportHour: new FormControl(this.document.reportHour),
          documentId: new FormControl(this.document.documentId),
          documentStatusId: new FormControl(this.document.documentStatusId),
          newTermEndDate: new FormControl(this.tool.dateToObject(this.document.newTermEndDate)),
          termDays: new FormControl(this.document.termDays),
          termEndDate: new FormControl(this.tool.dateToObject(this.document.termEndDate)),
          extensionDays: new FormControl(this.document.extensionDays),
          conceptListed: new FormControl(this.tool.JsonToObject(this.document.conceptListedJson)),
          articlesListed: new FormControl(this.tool.JsonToObject(this.document.articlesListedJson)),
          comment: new FormControl(this.document.comment),
          reportNumber: new FormControl(this.document.reportNumber),
          extensionJustification: new FormControl(this.document.extensionJustification),
          registrationDate: new FormControl(this.tool.dateToObject(this.document.registrationDate)),
          registeredBy: new FormControl(this.document.registeredBy),
          modifiedBy: new FormControl(this.document.modifiedBy),
          serviceOrderNumber: this.document.serviceOrderNumber,
          warningReportNumber: this.document.warningReportNumber ? this.document.warningReportNumber : this.warningReport.warningReportNumber,
          recordStatus: this.document.recordStatus
        })
        this.documentVality([1])
        this.setEndTerm()
        this.checkExtension()
        this.minDate = null
      }
      else {
        this.title = "Editar Acta de Apercibimiento"
        this.serviceOrderNumber = this.warningReport.serviceOrderNumber;
        this.warningReportForm = this.fb.group({
          serviceOrderNumber: new FormControl(this.warningReport.serviceOrderNumber),
          warningReportId: new FormControl(this.warningReport.warningReportId),
          warningReportNumber: new FormControl(this.warningReport.warningReportNumber, Validators.required),

          notifiedName: new FormControl(this.warningReport.notifiedName),
          documentTypeId: new FormControl(this.warningReport.documentTypeId),
          document: new FormControl(this.warningReport.document),
          documentTypeOfPersonRecevingId: new FormControl(this.warningReport.documentTypeOfPersonRecevingId),
          documentOfPersonReceving: new FormControl(this.warningReport.documentOfPersonReceving),
          notifiedQualityId: new FormControl(this.warningReport.notifiedQualityId),
          notifiedAddress: new FormControl(this.warningReport.notifiedAddress),
          nameOfPersonReceving: new FormControl(this.warningReport.nameOfPersonReceving),
          qualityOfPersonRecevingId: new FormControl(this.warningReport.qualityOfPersonRecevingId),
          registrationDate: new FormControl(this.warningReport.fechaRegistro),
          registeredBy: new FormControl(this.warningReport.registradoPor),
          modifiedBy: new FormControl(this.user.getUserData().userCode),
          registeredByName: new FormControl(this.warningReport.registeredByName),
          modifiedByName: new FormControl(this.warningReport.modifiedByName),
          recordModificationDate: this.currentDay,
          recordStatus: new FormControl(this.warningReport.estatusRegistro),
          justification_Reason: new FormControl(this.warningReport.justification_Reason,(this.canJustify === true? [Validators.required, Validators.minLength(5)]: null))

          // attachedDocument:AttachedDocument,
        })

        this.documentForm = this.fb.group({
          reportDate: new FormControl(null, this.tool.dateValidator),
          reportHour: new FormControl(null),
          newTermEndDate: new FormControl(''),
          termDays: new FormControl(0),
          documentStatusId: new FormControl(null),
          termEndDate: new FormControl(null),
          extensionDays: new FormControl(''),
          conceptListed: new FormControl([]),
          articlesListed: new FormControl(null),
          comment: new FormControl(null),
          reportNumber: new FormControl(null),
          extensionJustification: new FormControl(''),
          registrationDate: new FormControl(this.currentDay)


        })

        this.documentFormCopy = _.cloneDeep(this.documentForm);
      }
    }
  }

  selectFiles(event) {
    this.selectedFiles = event

    this.documentVality(event)
  }
  refreshMinDate() {
    this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }

  }
  getDropdowns() {
    this.HttpService.get<any[]>(`TypeOfIdentification`).subscribe((response) => {
      this.identificationTypes = response
      if (!this.isFilter) {
        this.setDocumetType()
        this.setNotifiedDocumetType()
      }
    });
    this.HttpService.get<DropDownOptionModel[]>(`QualityOfTheNotified?status=true`).subscribe((response) => { this.notifiedQuality = response });
    this.HttpService.get<any[]>(`ConceptOfReport?recordStatus=true`).subscribe((response) => {
      this.concepts = response
      this.getArticles();

    });
    this.HttpService.get<any[]>(`StatusWarningReportDocuments?recordStatus=true`).subscribe((response) => {
      this.documentStatus = response
      if (!this.isFilter) {
        this.checkStatus()
        this.getStatus()
      }
    });


  }

  async SaveWithWarning(docs: number) {

    if (this.selectedFiles.length > 0 || docs > 0) {
      this.saveReportData()
    } else {
      this.tool.createSwalModal('¿Esta seguro(a) que desea continuar?', 'Se guardara una acta vacía', 'warning')
        .then((result) => {
          if (result.isConfirmed) {

            this.ngbActiveModal.close();
            this.saveReportData()
          }
        })
    }
  }

  SaveJustification(){
    
    if(this.warningReportForm.valid){
      if(this.warningReportForm.get('registeredBy')?.value !==  this.user.getUserData().userCode){
        this.sweet.record('warning', (this.warningReportForm.get('warningReportNumber')?.value===""?"Esta acta de apercibimiento": this.warningReportForm.get('warningReportNumber')?.value),`Solo puede ser eliminada por el usuario que la registró!`, ``);
      }else{
        if(!this.warningReportForm.get('recordStatus').value){
          this.sweet.record('warning', `Esta acta ${this.warningReportForm.get('warningReportNumber').value}`,`Ya ha sido eliminada!`, `Se recomienda registrar una nueva en caso de ser necesario.`);
        }else{        
          Swal.fire({
            icon: 'warning',
            title: '¿Esta seguro que desea eliminar esta acta de apercibimiento?',
            showDenyButton: true,
            confirmButtonText: `Eliminar`,
            denyButtonText: `Cancelar`,
          }).then((result) => {
            document.getElementsByClassName("swal2-confirm")[0].setAttribute('id', 'btnEditSaveWarningReport');
              if (result.isConfirmed) {
                
                this.HttpService.patch<warningReport>(this.warningReportForm.value, `WarningReport/${this.warningReportForm.get('warningReportId')?.value}?registrationStatus=false&userCode=${this.user.getUserData().userCode}&justification_Reason=${this.warningReportForm.get('justification_Reason').value}`).subscribe(
                  {
                    next: response => {
                      
                      this.sweet.record('success', this.warningReportForm.get('warningReportNumber').value,` Ha sido eliminado correctamente`, ``);
                      this.close(true);
                        
                    }
                    , error: err => {
            
                      this.toast.error(err, 'error')
            
                    }
                  }
                )
              } else if (result.isDenied) {
              }
            
  
    
          });
  
       }          
      }  

    }else{
      this.sweet.record('warning', 'Favor verificar que este toda la información completada o intente nuevamente en unos minutos!',``, ``);
    }
   
  } 
  async saveReportData() {

    if (this.documentForm.valid) {

      this.saving = true
      if (this.warningReport) {
        try {
          this.selectedFiles ? await this.saveDocuments(this.selectedFiles) : '';
          this.HttpService.update<warningReport>(this.mapwarningReport(this.warningReportForm.value), "WarningReport").subscribe(
            response => {
              this.edit.emit(true);
              this.ngbActiveModal.close();
              this.sweet.record('success', `Registro No.${this.warningReport.warningReportNumber}`, 'Su acta se ha actualizado correctamente', ``)
              this.warningService.subject.next(true)
            }
          )
        }
        catch (error) {
          this.saving = false
          this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
        }

      }

      else {

        this.HttpService.post<warningReport>(this.mapwarningReport(this.warningReportForm.value), "WarningReport").subscribe(
          {
            next: response => {

              this.selectedFiles ? this.saveDocuments(this.selectedFiles, response.warningReportNumber) : '';
              this.edit.emit(true);
              this.close(true);

              this.sweet.record('success', `Registro No.${response.warningReportNumber}`, 'Su acta se ha registrado correctamente', `Este caso se ha añadido a su historial de actas`)

              this.warningService.subject.next(true)

            },
            error: error => {
              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
            }
          }
        )

      }
    } else {

      this.tool.createWarning(this.documentForm, this.elem).then(
        result => this.notFound()
      )
    }

  }

  updateDocument() {
    if (this.documentForm.valid) {
      this.HttpService.patch<warningDocument>(this.MapDocuments(this.documentForm.value), "WarningReportDocuments/WarningReportDocument").subscribe(
        {
          next: response => {


            this.sweet.record('success', `Registro No.${response.reportNumber}`, 'Su acta se ha actualizado correctamente', ``)

            this.WD.subject.next(true)
            this.close(true);
          },
          error: error => {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
          }
        }
      )
    } else {
      this.tool.createWarning(this.documentForm, this.elem).then(
        result => this.notFound()
      )
    }
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }
  close(direct?: boolean) {

    if (direct) {
      this.ngbActiveModal.close();
    } else {
      this.tool.modalWarning().then((result) => {
        if (result.isConfirmed) {
          this.ngbActiveModal.close();
        }
      })
    }
  }
  async saveDocuments(doc, warningReport?: string) {
    if (doc.length > 0) {

      this.HttpService.post<warningDocument>(this.MapDocuments(doc, warningReport), 'WarningReportDocuments').toPromise()

      let file: File = doc[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      let Headers: any = new HttpHeaders();
      /** In Angular 5, including the header Content-Type can invalidate your request */
      Headers.append('Content-Type', 'multipart/form-data');
      Headers.append('Accept', 'application/json');
      let options: any = new RequestOptions({ headers: Headers });

    }


  }


  checkExtension() {
    this.setNewEndTerm()
    if ((this.documentForm.get('extensionDays').value >= 1)) {
      this.documentForm.get('extensionJustification').setValidators([Validators.required]);
      this.documentForm.get('extensionJustification').updateValueAndValidity();
      this.extension = true
    }
    else {
      this.documentForm.get('extensionJustification').clearValidators();
      this.documentForm.get('extensionJustification').updateValueAndValidity();
      this.extension = false

    }

  }

  checkStatus() {
    let option = this.documentForm.get('documentStatusId').value;

    if (this.documentStatus?.find(x => x.value == option)?.hasOptionalField == false) {
      this.isDecline = true;

      this.documentForm.get('comment').setValidators([Validators.required]);
      this.documentForm.get('comment').updateValueAndValidity();
    }
    else {
      this.isDecline = false;

      this.documentForm.get('comment').clearValidators();
      this.documentForm.get('comment').updateValueAndValidity();
    }

  }





  setEndTerm() {
    let reportDate;
    if (!this.updateSingleDocument) {
      reportDate = this.tool.dateToObject(this.currentDay);
    } else {
      reportDate = this.documentForm.get('registrationDate').value;

    }
    let extensionDays = this.documentForm.get('termDays').value;

    if (reportDate) {

      this.documentForm.patchValue({
        'termEndDate': this.tool.dateToObject(this.addBusinessDays(moment(`${reportDate['year']}-${reportDate['month']}-${reportDate['day']}`), extensionDays))
      })
    }
    else {
      this.documentForm.patchValue({
        'termEndDate': reportDate
      })
    }
  }
  setNewEndTerm() {
    let termEndDate = this.documentForm.get("termEndDate").value;
    let extensionDays = this.documentForm.get('extensionDays').value;

    if (extensionDays >= 1) {

      this.documentForm.patchValue({
        'newTermEndDate': this.tool.dateToObject(this.addBusinessDays(moment(`${termEndDate['year']}-${termEndDate['month']}-${termEndDate['day']}`), extensionDays))
      })
    }
    else {
      this.documentForm.patchValue({
        'newTermEndDate': null
      })
    }
  }


  mapwarningReport(warningReport: warningReport) {

    let mapWarning = {

      registrationDate: new Date(),
      recordModificationDate: new Date(),


    }

    return { ...warningReport, ...mapWarning }

  }

  MapDocuments(files: File[], warningReport?: string) {

    let { documentStatusId, reportNumber, termDays, termEndDate, extensionDays,
      conceptListed, newTermEndDate, extensionJustification, registrationDate, recordModificationDate,
      reportDate, reportHour, articlesListed } = this.documentForm.value
    let concepts: [] = conceptListed
      .map((motivo) => { return motivo['value'] })

    let articles: [] = articlesListed
      .map((motivo) => { return motivo['value'] })

    let document: any;
    let mapDocument: any;
    if (!this.updateSingleDocument) {
      document = {
        evidenceFiles: files,
        documentId: 0,
        modifiedBy: this.user.getUserData().userCode,
        serviceOrderNumber: this.serviceOrderNumber,
        warningReportNumber: warningReport ? warningReport : this.warningReport.warningReportNumber,
        registrationDate: registrationDate,
        registeredBy: this.user.getUserData().userCode,
        recordStatus: true,
        recordModificationDate: this.currentDay,
        reportNumber: reportNumber,
        termDays: termDays,
        termEndDate: moment(`${termEndDate['year']}-${termEndDate['month']}-${termEndDate['day']}`).format(),
        extensionDays: extensionDays,
        conceptListed: concepts.join(','),
        articlesListed: articles.join(','),
        newTermEndDate: newTermEndDate,
        extensionJustification: extensionJustification,
        reportDate: moment(`${reportDate['year']}-${reportDate['month']}-${reportDate['day']}`).format(),
        reportHour: reportHour,
        documentStatusId: documentStatusId
      }

      let form = new FormData()
      for (let file of document.evidenceFiles) {
        form.append('evidenceFiles', file);
      }

      form.append('documentId', '0')
      form.append('modifiedBy', document.modifiedBy)
      form.append('documentStatusId', document.documentStatusId)
      form.append('warningReportNumber', document.warningReportNumber)
      form.append('serviceOrderNumber', document.serviceOrderNumber)
      form.append('registeredBy', document.registeredBy)
      form.append('recordStatus', 'true')
      form.append('reportDate', document.reportDate),
        form.append('reportHour', document.reportHour),
        form.append('reportNumber', document.reportNumber)
      form.append('recordModificationDate', '')
      form.append('termDays', document.termDays)
      form.append('termEndDate', document.termEndDate)
      form.append('extensionDays', '')
      form.append('conceptListed', document.conceptListed)
      form.append('articlesListed', document.articlesListed)
      form.append('newTermEndDate', '')
      form.append('extensionJustification', '')
      form.append('registrationDate', document.registrationDate)


      return form
    }
    else {
      mapDocument = {
        termEndDate: moment(`${termEndDate['year']}-${termEndDate['month']}-${termEndDate['day']}`).format(),
        newTermEndDate: newTermEndDate ? moment(`${newTermEndDate['year']}-${newTermEndDate['month']}-${newTermEndDate['day']}`).format() : '',
        registrationDate: moment(`${registrationDate['year']}-${registrationDate['month']}-${registrationDate['day']}`).format(),
        reportDate: moment(`${reportDate['year']}-${reportDate['month']}-${reportDate['day']}`).format(),
        recordModificationDate: recordModificationDate ? moment(`${recordModificationDate['year']}-${recordModificationDate['month']}-${recordModificationDate['day']}`).format() : '',
        warningReportId: this.document.warningReportId,
        conceptListed: concepts.join(','),
        articlesListed: articles.join(',')
      }

      return { ...this.documentForm.value, ...mapDocument }
    }
  }

  cleanForm() {
    if (!this.isFilter) { this.warningReportForm.reset(this.WarningCopy.value) }
    else {
      this.warningReportForm.patchValue({
        warningReportNumber: null
      })
    }
  }

  documentVality(docs) {
    if (docs.length >= 1) {

      this.documentForm.get('reportDate').setValidators([Validators.required]);
      this.documentForm.get('reportDate').updateValueAndValidity();
      this.documentForm.get('documentStatusId').setValidators([Validators.required]);
      this.documentForm.get('documentStatusId').updateValueAndValidity();
      this.documentForm.get('reportHour').setValidators([Validators.required]);
      this.documentForm.get('reportHour').updateValueAndValidity();
      this.documentForm.get('conceptListed').setValidators([Validators.required]);
      this.documentForm.get('conceptListed').updateValueAndValidity();
      this.documentForm.get('articlesListed').setValidators([Validators.required]);
      this.documentForm.get('articlesListed').updateValueAndValidity();
      this.documentForm.get('reportNumber').setValidators([Validators.required]);
      this.documentForm.get('reportNumber').updateValueAndValidity();
      this.documentForm.get('termDays').setValidators([Validators.required]);
      this.documentForm.get('termDays').updateValueAndValidity();
    }
    else {
      this.documentForm.reset(this.documentFormCopy.value)
      this.documentForm.get('reportDate').clearValidators();
      this.documentForm.get('reportDate').updateValueAndValidity();
      this.documentForm.get('documentStatusId').clearValidators();
      this.documentForm.get('documentStatusId').updateValueAndValidity();
      this.documentForm.get('reportHour').clearValidators();
      this.documentForm.get('reportHour').updateValueAndValidity();
      this.documentForm.get('conceptListed').clearValidators();
      this.documentForm.get('conceptListed').updateValueAndValidity();
      this.documentForm.get('articlesListed').clearValidators();
      this.documentForm.get('articlesListed').updateValueAndValidity();
      this.documentForm.get('reportNumber').clearValidators();
      this.documentForm.get('reportNumber').updateValueAndValidity();
      this.documentForm.get('termDays').clearValidators();
      this.documentForm.get('termDays').updateValueAndValidity();
    }

  }
  addBusinessDays(originalDate, numDaysToAdd): string {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    const newDate = originalDate

    while (daysRemaining > 0) {
      newDate.add(1, 'days');
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--;
      }
    }

    return newDate.format('YYYY-MM-DD');
  }

  findInvalidControls() {
    const documents = [];
    const wraning = [];

    const controls = this.documentForm.controls;
    const W = this.warningReportForm.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        documents.push(name);
      }
    }
    for (const name in W) {
      if (W[name].invalid) {
        wraning.push(name);
      }
    }

  }

  getArticles(multi?: boolean) {
    if (multi) {
      this.documentForm.patchValue({
        articlesListed: null
      })
    }
    const concepts: any[] = this.documentForm.get('conceptListed').value
    const conceptIds = concepts.map(el => el.value);
    const conceptCodes = this.concepts.filter(el => conceptIds.includes(el.value))
    const codes = conceptCodes.map(el => el.alternateField).join(',');

    this.HttpService.get<any[]>(`ArticleMinute?conceptsCode=${codes}`)
      .subscribe(el => this.articles = el)
  }
}
