
    <div   class="mt-2 ms-1 mr-2">
      <app-prime-table
        [records]="records"
        [loading]="loading"
        [cols]="cols"
        [buttons]="customButtons"
        [filters]="filters"
        [hasGlobalFilters]="true"
      ></app-prime-table>
    </div>  
   
