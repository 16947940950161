<div class="modal-content" [ngClass]="{ cantEditCols: !canEdit }">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="canEdit ? close(false) : close(true)"
    ></button>
  </div>
  <div class="modal-body" [ngClass]="{ cantEdit: !canEdit }">
    <div class="centered-content">
      <form [formGroup]="orderNotesForm">
        <div class="form-row">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg"
              >Datos de Nota
              <small
                [@warning]="stateName"
                class="text-danger faa-pulse"
                *ngIf="orderNotesForm.get('serviceOrderNoteInfo').invalid"
                >*
              </small>
            </label>

            <textarea
              specialIsAlphaNumeric
              [fControl]="orderNotesForm.get('serviceOrderNoteInfo')"
              type="text"
              placeholder="Datos de nota"
              class="form-control form-control-lg"
              formControlName="serviceOrderNoteInfo"
            ></textarea>
          </div>
        </div>
        <div class="form-row" *ngIf="orderNotes">
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Digitado por</label>
            <input
              type="text"
              class="form-control form-control-lg"
              readonly
              formControlName="registeredByName"
            />
          </div>
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Modificado por</label>
            <input
              type="text"
              class="form-control form-control-lg"
              readonly
              formControlName="modifiedByName"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer mt-2">
      <button
        *ngIf="!this.orderNotes"
        type="button"
        class="rounded-pill btn btn-clean px-5"
        data-bs-dismiss="modal"
        (click)="cleanForm()"
      >
        Limpiar
      </button>
      <button
        type="button"
        class="rounded-pill btn btn-save px-5"
        data-bs-dismiss="modal"
        (click)="saveNoteData()"
        *ngIf="canEdit"
      >
        Guardar
      </button>
    </div>
  </div>
</div>
