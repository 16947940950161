import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-iconchart',
  templateUrl: './iconchart.component.html',
  styleUrls: ['./iconchart.component.css']
})
export class IconchartComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() totalTitle: string | number;

  constructor() { }

  ngOnInit(): void {
  }

}
