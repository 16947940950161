import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { FileReaderComponent } from 'src/app/modules/shared/_elements/element-ui/file-reader/file-reader.component';
import { FileUploaderComponent } from 'src/app/modules/shared/_elements/element-ui/file-uploader/file-uploader.component';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { AttachedDocument } from '../_models/attachedDocuments';
import { serviceOrder } from '../_models/serviceOrder';
import * as _ from 'lodash';
import Swal, { SweetAlertIcon } from 'sweetalert2'
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { warningReport } from '../warning-report/_models/warningReport';
import { infractionReport } from '../infraction-report/_models/infractionReport';
import { WarningReportComponent } from '../warning-report/warning-report.component';
import { warningDocument } from '../warning-report/_models/warningDocuments';
import { WarningDocumentService } from '../warning-report/warning-document.service';
@Component({
  selector: 'app-attached-documents',
  templateUrl: './attached-documents.component.html',
  styleUrls: ['./attached-documents.component.css']
})
export class AttachedDocumentsComponent implements OnInit {
  @Input() serviceOrder: serviceOrder;
  @Input() warningReport: warningReport;
  @Input() infractionReport: infractionReport;
  @Input() toolbar: string;
  @Output() outputfiles = new EventEmitter<any[]>()
  @Input() canEdit: boolean = true;
  amount = 0;
  constructor(private user: UserService, private http: HttpClientService, private ngBModal: NgbModal,
    private sweet: SweetAlertService, private WD: WarningDocumentService) { }
  selectedFiles: any[] = [];
  uploadedFiles: any[];

  // Documents: AttachedDocument= new AttachedDocument();
  tolowerCase(name: string) {
    return name.toLowerCase()
  }

  async reLoad() {
    await this.ngOnInit()
  }
  async ngOnInit(): Promise<void> {
    this.WD.subject.subscribe(data => {
      this.http.get<any>(`WarningReportDocuments/WarningReport/${this.warningReport.warningReportNumber}?status=true`).subscribe(
        files => {
          this.uploadedFiles = files
        }
      );
    })
    if (this.serviceOrder && !this.warningReport && !this.infractionReport) {
      this.uploadedFiles = await this.http.get<any>(`ServiceOrderDocuments/ServiceOrderByNumber/${this.serviceOrder.serviceOrderNumber}?status=true`).toPromise();
      this.amount = this.uploadedFiles.length
    } else if (this.warningReport) {
      this.uploadedFiles = await this.http.get<any>(`WarningReportDocuments/WarningReport/${this.warningReport.warningReportNumber}?status=true`).toPromise();

    }
    else if (this.infractionReport) {
      this.uploadedFiles = await this.http.get<any>(`InfractionReportDocuments/InfractionReport/${this.infractionReport.infractionReportNumber}?status=true`).toPromise();

    }
  }


  assignFiles(files) {
    this.selectedFiles = files;
    this.outputfiles.emit(files)
  }

  edit(file) {

    const ngmodal: NgbModalRef = this.ngBModal.open(
      WarningReportComponent, { size: 'xl' });
    ngmodal.componentInstance.updateSingleDocument = true;
    ngmodal.componentInstance.document = _.cloneDeep(file);

  }

  ViewFile(file) {
    const ngmodal: NgbModalRef = this.ngBModal.open(
      FileReaderComponent, { size: 'xl' });

    ngmodal.componentInstance.file = _.cloneDeep(file);

  }
  remove(event) {

    const index: number = this.uploadedFiles.indexOf(event);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }

    if (this.serviceOrder && !this.warningReport && !this.infractionReport) {
      this.http.patch<any>(event, `ServiceOrderDocuments/${event.documentId}/false`).toPromise();
    }
    else if (this.warningReport) {
      this.http.patch<any>(event, `WarningReportDocuments/${event.documentId}/false`).toPromise();

    }
    else if (this.infractionReport) {
      this.http.patch<any>(event, `InfractionReportDocuments/${event.documentId}/false`).toPromise();

    }
  }
  preRemove(event) {
    Swal.fire({
      icon: 'warning',
      title: 'Esta seguro que desea Borrar',
      showDenyButton: true,
      confirmButtonText: `Borrar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.remove(event)
      } else if (result.isDenied) {

      }
    });
  }
}
