<form class="form" [formGroup]="informacionGeneralForm" (ngSubmit)="validateInformacionGeneral()">
  <div class="row mb-2">
    <div class="col-12 col-md-4 mb-3">
      <div class="form-group">
        <label class="mb-2" for="">
          Fecha de Expediente
        </label>
        <input [max]="getCurrentDate()" [min]="getMinDate()" class="form-control" formControlName="fechaAlta"
          type="date" id="inputDate" class="form-control">
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <div class="form-group">
        <label class="mb-2" for="">
          Representacion Local <span class="asterisco">*</span>
        </label>
        <input type="text" class="form-control"
          value="{{record?.localRepresentativeProvince?.provinceCode}} - {{record?.localRepresentativeProvince?.localRepProvinceInformation }}"
          disabled>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <div class="form-group">
        <label class="mb-2" for="DemandaCorrespondeFechasAnteriores">
          La demanda corresponde a años anteriores?
        </label>
        <select class="form-select rounded-0" aria-label="Default select example" id="demandaCorrespondiente"
          formControlName="demandaCorrespondeFechasAnteriores" (change)="displayEspecifiqueField()">
          <option value="" selected>Selecione</option>
          <option value="1">Si</option>
          <option value="2">No</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4" id="especifiqueDate">
      <div class="form-group">
        <label class="mb-2" for="">
          Especifique Fecha
        </label>
        <input type="date" class="form-control" formControlName="fechaDemandaAnterior">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12 mt-4">
      <button type="submit"
        [disabled]="expedientCerrado || this.user.roleCode === 'DIR' || this.user.roleCode === 'ADM' || this.user.roleCode === 'PLEGA' || this.user.roleCode === 'ENCA'"
        class="btn btnSubmit float-end btn-guardar btnListo">Listo</button>
    </div>
  </div>
</form>
