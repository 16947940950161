import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb-browser';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';


@Injectable({
    providedIn: 'root'
})
export class DbPwaService {
    private orderDB: any;
    private serviceDB: any;

    constructor(private http: HttpClientService) {
        this.orderDB = new PouchDB('orderDB');
        this.serviceDB = new PouchDB('serviceDB');
    }

    pouchDatadatabaseExists(dbname) {
        return new Promise((resolve, reject) => {
            const req = indexedDB.open(`_pouch_${dbname}`);
            req.onsuccess = function () {
                resolve(true)
            }
            req.onupgradeneeded = function () {
                resolve(false)
            }
        });
    }

    public addOrderTask = (dataScheme) => {
        this.orderDB.get(dataScheme._id)
            .then((doc: any) => {

                delete dataScheme._id;
                doc = Object.assign(doc, dataScheme);
                this.orderDB.put(doc);


            })
            .catch(() => {


                this.orderDB.put(dataScheme);

            })

    }
    public addServiceTask = (dataScheme) => {
        this.serviceDB.get(dataScheme._id)
            .then((doc: any) => {
                delete dataScheme._id;
                doc = Object.assign(doc, dataScheme);
                this.serviceDB.put(doc);


            })
            .catch(() => {
                this.serviceDB.put(dataScheme);
            })

    }
    public getAllOrders = () => new Promise((resolve, reject) => {
        this.orderDB.allDocs({
            include_docs: true,
            attachments: true
        }).then(({ rows }) => {
            resolve(rows);
        }).catch(() => {
            reject(null);
        })
    })
    public getAllService = () => new Promise((resolve, reject) => {
        this.serviceDB.allDocs({
            include_docs: true,
            attachments: true
        }).then(({ rows }) => {
            resolve(rows);
        }).catch(() => {
            reject(null);
        })
    })
    public clearOrderTask = (dataScheme) => {
        this.orderDB.get(dataScheme.id)
            .then((doc: any) => {
                doc = Object.assign(doc, dataScheme);
                this.orderDB.remove(doc);

            })

    }

    public clearServiceTask = (dataScheme) => {
        this.serviceDB.get(dataScheme.id)
            .then((doc: any) => {
                doc = Object.assign(doc, dataScheme);
                this.orderDB.remove(doc);
            })

    }
    public clearserviceTask = (dataScheme) => {
        this.serviceDB.get(dataScheme.id)
            .then((doc: any) => {
                doc = Object.assign(doc, dataScheme);
                this.serviceDB.remove(doc);

            })

    }

    async uploadAllOrders() {

        let orders: Array<any> = (await this.getAllOrders()) as [];
        let services: Array<any> = (await this.getAllService()) as [];

        try {
            if (orders.length) {

                let mapOrders = orders.map(
                    doc => {
                        delete doc.doc['_id']
                        delete doc.doc['_rev']
                        return doc.doc
                    }
                )

                await this.http.post(mapOrders, 'ServiceOrderFirstStep/FirstStep').toPromise().then(
                    _ => {
                        orders.forEach(doc => {

                            this.clearOrderTask(doc)
                        })
                    }
                );
            }
            if (services.length) {

                let mapServices = services.map(
                    doc => {
                        delete doc.doc['_id']
                        delete doc.doc['_rev']
                        return doc.doc
                    }
                )

                await this.http.post(mapServices, 'UserService/UserServices').toPromise().then(
                    _ => {
                        orders.forEach(doc => {

                            this.clearServiceTask(doc)
                        })
                    }
                );
            }

        } catch {
            services.forEach(doc => {

                this.clearserviceTask(doc)
            })
        }

    }
}