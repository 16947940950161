import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateParserFormatter, NgbModal, NgbModalRef, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { orderNotes } from '../order-notes/_models/orderNotes';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { OrderNotesDataService } from './order-notes-data.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { SaveWarningComponent } from 'src/app/modules/shared/_elements/element-ui/save-warning/save-warning/save-warning.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null
      ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
      : null;
  }
}
@Component({
  selector: 'app-order-notes',
  templateUrl: './order-notes.component.html',
  styleUrls: ['./order-notes.component.css'], animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
})
export class OrderNotesComponent implements OnInit {

  constructor(private fb: FormBuilder, private user: UserService, private ngbActiveModal: NgbActiveModal,
    private HttpService: HttpClientService, private sweet: SweetAlertService, private notesData: OrderNotesDataService,
    private toast: ToastService, private ngBModal: NgbModal, private elem: ElementRef, private tool: ToolsService) { }
  serviceOrderNumber: string;
  orderNotes: orderNotes;
  orderNoteCopy: FormGroup;
  orderNotesForm: FormGroup;
  title = '';
  @Output() edit = new EventEmitter<boolean>();
  @Input() canEdit: boolean = true;
  show = false
  ngOnInit(): void {

    this.createForm()
  }

  createForm() {
    if (!this.orderNotes) {
      this.title = 'Creando Nota'
      this.orderNotesForm = this.fb.group({
        serviceOrderNoteId: new FormControl(0),
        serviceOrderNumber: new FormControl(this.serviceOrderNumber),
        serviceOrderNoteInfo: new FormControl(null, Validators.required),
        registeredBy: new FormControl(this.user.getUserData().userCode),
        modifiedBy: new FormControl(null),
        recordStatus: new FormControl(true),
        registrationDate: this.currentDay,
        recordModificationDate: this.currentDay
      })
      this.orderNoteCopy = _.cloneDeep(this.orderNotesForm)

    } else {
      this.title = 'Editando Nota'

      this.orderNotesForm = this.fb.group({
        serviceOrderNoteId: new FormControl(this.orderNotes.idNota),
        serviceOrderNumber: new FormControl(this.orderNotes.codigo),
        serviceOrderNoteInfo: new FormControl(this.orderNotes.notaInfo, Validators.required),
        registeredBy: new FormControl(this.orderNotes.registradoPor),
        modifiedBy: new FormControl(this.user.getUserData().userCode),
        recordStatus: new FormControl(this.orderNotes.estatusRegistro),
        registrationDate: this.orderNotes.fechaRegistro,
        registeredByName: this.orderNotes.registradoPorNombre,
        modifiedByName: this.orderNotes.modificadoPorNombre,
        recordModificationDate: this.currentDay
      })
    }

  }
  get currentDay() {
    let date = moment().utc()
    return date
  }
  cleanForm() {
    this.orderNotesForm.reset(this.orderNoteCopy.value)
  }
  close(direct?: boolean) {
    if (direct) {
      this.ngbActiveModal.close();
    } else {
      this.tool.modalWarning().then((result) => {
        if (result.isConfirmed) {
          this.ngbActiveModal.close();
        }
      })

    }
  }


  saveNoteData() {
    if (!this.orderNotesForm.invalid) {
      if (this.orderNotes) {

        this.HttpService.update<orderNotes>(this.orderNotesForm.value, "Note").subscribe(
          {
            next: response => {
              this.edit.emit(true);
              this.close(true);
              this.sweet.record('success', `Registro No.${this.orderNotes.idNota}`, 'Su nota se ha actualizado correctamente', ``)

              this.notesData.subject.next(true)

            },
            error: error => {
              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')

            }
          }
        )



      }

      else {

        this.HttpService.post<orderNotes>(this.orderNotesForm.value, "Note").subscribe(
          {
            next: response => {
              this.sweet.record('success', `Registro No.${response.idNota}`, 'Su nota se ha registrado correctamente', `Este registro se ha añadido a su historial de Notas`)
              this.close(true);
              this.edit.emit(true);
              this.notesData.subject.next(true)

            },
            error: error => {
              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')

            }
          }
        )

      }
    }
    else {
      this.tool.createWarning(this.orderNotesForm, this.elem).then(result => {
        this.notFound()
      })
    }
  }

  get stateName() {
    return this.show ? "show" : "hide";
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }

}
