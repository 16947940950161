<div class="card">
  <p-table
    dataKey="id"
    [responsive]="true"
    responsiveLayout="scroll"
    [loading]="loading"
    [value]="records"
    [paginator]="paginator"
    [rows]="10"
    styleClass="p-datatable-striped"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} registros"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [columns]="cols"
    #dt1
    [globalFilterFields]="filters"
  >
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between align-items-center">
        <span class="p-input-icon-left p-ml-auto" *ngIf="hasGlobalFilters">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Buscar por palabra"
          />
        </span>

        <button
          *ngIf="creationButton"
          type="button"
          pButton
          pRipple
          (click)="creationButton.action()"
          icon="pi pi-plus"
          iconPos="right"
          [label]="creationButton.label"
          class="p-button-info p-mr-2 m-1"
          tooltipPosition="bottom"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          [ngStyle]="{ 'width:8rem': col.width }"
          [pSortableColumn]="col.field"
        >
          <div
            class="p-d-flex p-jc-between p-ai-center"
            *ngIf="col.header == 'ESTATUS'; else notStatus"
          >
            {{ col.header }}
            <div *ngIf="hasIndividualFilters">
              <p-sortIcon [field]="col.field"></p-sortIcon>

              <p-columnFilter
                field="statusOrderServiceInfo"
                matchMode="equals"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              >
                <ng-template
                  pTemplate="body"
                  let-value
                  let-filter="filterCallback"
                  class="DropMenu"
                >
                  <p-dropdown
                    [ngModel]="value"
                    [options]="status"
                    (onChange)="filter($event.value)"
                    placeholder="Ninguno"
                    [showClear]="true"
                  >
                    <ng-template let-option let-record pTemplate="item">
                      <span
                        class="options"
                        [style]="'background:' + option.statusColor + ';'"
                      >
                        {{ option.label }}</span
                      >
                      <!-- <textarea
                        class="textareaToText"
                        [style]="'background:' + option.statusColor + ';'"
                        readonly
                        [placeholder]="option.label"
                      ></textarea> -->
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </div>
          </div>
          <ng-template #notStatus>
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ col.header }}
              <div *ngIf="hasIndividualFilters">
                <p-sortIcon [field]="col.field"></p-sortIcon>

                <p-columnFilter
                  *ngIf="!col.hasMulti; else hasMultiFilter"
                  lang="es"
                  type="text"
                  [field]="col.field"
                  display="menu"
                ></p-columnFilter>
                <ng-template #hasMultiFilter>
                  <p-columnFilter
                    [field]="col.field"
                    matchMode="in"
                    display="menu"
                    [showMatchModes]="false"
                    [showOperator]="false"
                    [showAddButton]="false"
                  >
                    <ng-template pTemplate="header"> </ng-template>
                    <ng-template
                      pTemplate="filter"
                      let-value
                      let-filter="filterCallback"
                    >
                      <p-multiSelect
                        [ngModel]="value"
                        [options]="col.multiFilter.options"
                        placeholder="Cualquiera"
                        (onChange)="filter($event.value)"
                        [optionLabel]="col.multiFilter.text"
                        [optionValue]="col.multiFilter.text"
                      >
                        <ng-template let-option pTemplate="item">
                          <div class="p-multiselect-representative-option">
                            <span class="p-ml-1">{{
                              option[col.multiFilter.text]
                            }}</span>
                          </div>
                        </ng-template>
                      </p-multiSelect>
                    </ng-template>
                  </p-columnFilter>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </th>
        <th style="width: 8rem">&nbsp;</th>
      </tr>
    </ng-template>
    <ng-template
      class="tes"
      pTemplate="body"
      style="display: table-caption !important"
      let-record
      let-columns="columns"
    >
      <tr>
        <td *ngFor="let col of columns; let i = index">
          <span class="p-column-title">{{ col.header }}</span>
          <widget-dynamic-format
            [format]="col.format"
            [value]="record[col.field]"
            [index]="i"
            [routePath]="hasRouter"
            [ngClass]="
              record[col.style]
                ? 'css-shadow d-flex justify-content-center'
                : 'd-flex'
            "
            [ngStyle]="
              record[col.style]
                ? { backgroundColor: record[col.style] }
                : null || col.color
                ? { color: col.color }
                : null
            "
            [length]="col.length ? col.length : null"
          >
          </widget-dynamic-format>
        </td>
        <td>
          <div class="d-flex flex-row">
            <div
              class="d-flex justify-content-center"
              *ngFor="let button of buttons"
            >
              <button
               *ngIf="!button.validateShow || button.validateShow(record)"
                pButton
                type="button"
                [icon]="button.icon"
                [disabled]="record.recordStatusStr == null || record.recordStatusStr == undefined? false : (record.recordStatusStr == 'Eliminado' &&  button.icon == 'pi pi pi-trash' ?true:false)"
                [class]="button.class"
                (click)="button.function(record)"
                [label]="button.label"
                [pTooltip]="button.tooltip"
                tooltipPosition="top"
              ></button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <!-- <div class="noRegisters text-center mx-auto">
        <i class="fa fa-codepen m-2" style="font-size: 40px"></i>
        No hay registros
      </div> -->

      <tr>
        <td colspan="8">
          <div class="noRegisters text-center mx-auto pt-5">
            <i
              class="pi pi-eye-slash m-2"
              style="font-size: 80px; color: #a6b9cf"
            ></i>
            <h1 style="color: #a6b9cf">
              {{ notRegistersMessage || "No se encontraron registros" }}
            </h1>
          </div>
        </td>
      </tr>
    </ng-template>
    
  </p-table>
</div>
