<div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ file.fileName }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="exit()"
        ></button>
      </div>
      <div class="modal-body">
        <iframe style="height: 100%;
        width: 100%; " [src]="file.route | safe" title="Iframe Example" ></iframe>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          (click)="exit()"
        >
          Close
        </button>
     
      </div>
    </div>
  </div>
  <!-- //</div> -->
  