<div class="container-fluid">
  <div class="ms-1">
    <h4 class="tituloH mt-5 mb-3">Historial</h4>

  </div>
  <div class="botones">
    <form [formGroup]="searchForm">
      <div class="row" id="busquedaSimple">
        <div class="col-12 col-md-3">
          <label for="">Tipo de Registro</label>
          <select formControlName="registerType" class="form-select" (change)="onChangeTypeRegister()"
            aria-label="Default select example">
            <option value="1" selected>Registro de Asistencia Diaria</option>
            <option value="2">Servicio Judicial Empleador</option>
            <option value="3">Servicio Judicial Trabajador</option>
          </select>
        </div>
        <div class="col-12 col-md-3" style="margin-right: -92px;">
          <div class="form-group">
            <label for="">Fecha Desde:</label>
            <input formControlName="sinceDate" type="date" name="" class="form-control w-75">
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label for="">Fecha Hasta:</label>
            <input formControlName="untilDate" type="date" name="" class="form-control w-75">
          </div>
        </div>
        <div class="col-12 col-md-3 p-4">
          <button type="button" (click)="executeFilter()" class="btn btnSearch"><i
              class="fa fa-search mt-2"></i></button>
          <button type="button" (click)="exportToExcel()" class="btn btnExport mx-4" id="btnExport"> Exportar <i
              class="fa fa-file-pdf-o mt-2"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <div class="input-group buscador">
            <input type="text" class="form-control" formControlName="textFilter"
              (input)="filterInput($event.target.value)" placeholder="Búsqueda por palabra clave">
            <button class="btn border busquedadSimple" type="button" id="button-addon2">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div *ngIf="searchForm.get('registerType').value !== '1'" class="col-12 col-md-3 ">
          <button (click)="openBusquedaAvanzada()" type="button" class="btn btnBusquedad" id="btnBusquedad"
            data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Búsqueda avanzada
          </button>
        </div>
      </div>

    </form>
  </div>
  <div class="table-responsive">
    <table class="table table-hover" id="dailyAttendanceTable">
      <thead *ngIf="searchForm.get('registerType').value == '1'">
        <tr>
          <th><a (click)="sortTables('codigo')">NO. REGISTRO {{sortIcon('codigo')}}</a></th>
          <th><a (click)="sortTables('fechaAlta')">FECHA REGISTRO {{sortIcon('fechaAlta')}}</a></th>
          <th>TIPO SOLICITANTE</th>
          <th><a (click)="sortTables('nombre')">NOMBRE SOLICITANTE {{sortIcon('nombre')}}</a></th>
          <th>No. DOCUMENTO</th>
          <th>RLT</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="searchForm.get('registerType').value == '1'">
        <!-- <tr *ngFor="let ad of recordDataFiltered | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }" -->
        <tr *ngFor="let ad of multipleRltResult | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }"
          class="align-middle">
          <td>{{ad.codigo}}</td>
          <td>{{ad.fechaAlta | date: 'dd/MM/yyyy'}}</td>
          <td>{{ad.tipoSolicitante.descripcion}}</td>
          <td>{{ad.nombre}} {{ad.apellido}}</td>
          <td>{{ ad.identificacion }}</td>
          <td>{{getRltDescription(ad?.idRepLocalProvinciaCatalog)}}</td>
          <td>
            <a [routerLink]="['/Casos/creacionRecord/', ad.idAsistenciaDiaria]" class="btn btnOutlinePrimary">
              <i class="fa fa-eye fs-4" aria-hidden="true"></i>
            </a>
            <a pTooltip="Compartir" class="btn btnOutlinePrimary"
              href="mailto:someone@example.com?subject=Asistencia Diaria - {{ad.codigo}}">
              <i class="fa fa-share-alt fs-4" aria-hidden="true"></i>
            </a>
          </td>

        </tr>
      </tbody>
      <thead *ngIf="searchForm.get('registerType').value != '1'">
        <tr>
          <th><a (click)="sortTables('codigo')">NO. REGISTRO {{sortIcon('codigo')}}</a> </th>
          <th><a (click)="sortTables('asistenciaDiaria?.fechaAlta')">FECHA REGISTRO</a></th>
          <th *ngIf="searchForm.get('registerType').value ==2">TIPO
            {{searchForm.get('registerType').value ==2?"EMPLEADOR":"TRABAJADOR"}}</th>
          <th>RLT</th>
          <th><a (click)="sortTables('estado')">ESTADO {{sortIcon('estado')}}</a></th>
          <th><a (click)="sortTables('empresa?.rncCedula')">NO. CEDULA/RNC</a></th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="searchForm.get('registerType').value != '1'">
        <tr *ngFor="let ade of recordDataFiltered | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }"
          class="align-middle">
          <td>{{ade.codigo}}</td>
          <td>{{ade?.asistenciaDiaria?.fechaAlta | date: 'dd/MM/yyyy'}}</td>
          <td *ngIf="searchForm.get('registerType').value ==2">
            {{ade?.tipoEmpleador == 'FISICA' ? 'Persona Fisica' : 'Empresa Formalizada'}}</td>
          <td>{{getRltDescription(ade?.asistenciaDiaria?.idRepLocalProvinciaCatalog)}}</td>
          <td>{{ade?.estado?.descripcion}}</td>
          <td>{{ade?.empresa?.rncCedula ? ade?.empresa?.rncCedula : ade?.empresa?.identificacion}}</td>
          <td></td>
          <td>
            <a pTooltip="Ver" [routerLink]="['/Casos/creacionRecord/', ade?.idAsistenciaDiaria]"
              class="btn btnOutlinePrimary">
              <i class="fa fa-eye fs-4" aria-hidden="true"></i>
            </a>
            <a pTooltip="Compartir" class="btn btnOutlinePrimary" href="mailto:someone@example.com">
              <i class="fa fa-share-alt fs-4" aria-hidden="true"></i>
            </a>
            <a pTooltip="Imprimir PDF" class="btn btnOutlinePrimary" (click)="pressDowloadPdf(ade.codigo)">
              <i class="fa fa-file-pdf-o fs-4" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>

    </table>
    <div class="pagination justify-content-center mt-4">
      <pagination-controls (pageChange)="changePage($event)" previousLabel="Anterior" nextLabel="Siguiente"
        *ngIf="totalPages > 1"></pagination-controls>
    </div>
  </div>

</div>

