<div *ngIf="!isFilter; else isTheFilter">
  <div *ngIf="!canJustify" [ngClass]="{ cantEditCols: !canEdit }">
    <div *ngIf="!updateSingleDocument; else editDocument">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="canEdit ? close(false) : close(true)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="centered-content">
            <form
              [formGroup]="warningReportForm"
              [ngClass]="{ cantEdit: !canEdit }"
            >
              <label class="form-label form-control-lg sectionTitle"
                >Datos de Acta Apercibimiento
              </label>
              <div class="form-row">
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >No. de Orden
                  </label>
                  <input
                    type="text"
                    placeholder="Número de orden"
                    formControlName="serviceOrderNumber"
                    class="form-control form-control-lg"
                    placeholder="233-233"
                    readonly
                  />
                </div>
                <div class="col-md col-12 mb-1" *ngIf="warningReport">
                  <label class="form-label form-control-lg">No. de Acta </label>
                  <input
                    type="text"
                    placeholder="Número de acta"
                    formControlName="warningReportNumber"
                    class="form-control form-control-lg"
                    placeholder="233-233"
                    readonly
                  />
                </div>
              </div>
              <!-- <div class="form-row">
                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg mb-5"
                  >Hora Visita
                  <small [@warning]="tool.stateName(show)"
                    class="text-danger"
                    *ngIf="warningReportForm.get('reportHour').invalid "
                    >*</small
                  ></label
                  >
                  <ngb-timepicker 
                  formControlName="reportHour"
                    [readonlyInputs]="true" 
                   [minuteStep]="15"
                   size="large"
                   class="mx-auto"
                   [meridian]="meridian"></ngb-timepicker>
                </div>
              </div> -->

              <hr />
              <label class="form-label form-control-lg sectionTitle"
                >Datos del Notificado
              </label>
              <div class="form-row">
                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg">Nombre </label>

                  <input
                    specialIsAlphaNumeric
                    [fControl]="warningReportForm.get('notifiedName')"
                    type="text"
                    placeholder="Nombre"
                    class="form-control form-control-lg"
                    formControlName="notifiedName"
                  />
                </div>

                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg"
                    >Tipo&nbsp;de&nbsp;Documento
                  </label>
                  <el-dropdown
                    [options]="identificationTypes"
                    formControlName="documentTypeId"
                    customClass="form-control form-control-lg"
                    (onChange)="setDocumetType()"
                    placeHolder="Elige un tipo de documento"
                  ></el-dropdown>
                </div>
                <div
                  class="col-md col-12 mb-1"
                  *ngIf="this.warningReportForm.get('documentTypeId').value"
                >
                  <label class="form-label form-control-lg"
                    >Doc.&nbsp;Identificación
                  </label>
                  <div [ngSwitch]="documentTypeCode">
                    <div *ngSwitchCase="'cd'">
                      <input
                        type="text"
                        placeholder="Cedula"
                        class="form-control form-control-lg"
                        formControlName="document"
                        mask="000-0000000-0"
                      />
                    </div>
                    <div *ngSwitchCase="'ps'">
                      <input
                        type="text"
                        placeholder="Pasaporte"
                        class="form-control form-control-lg"
                        formControlName="document"
                      />
                    </div>
                    <div *ngSwitchCase="'o'">
                      <input
                        type="text"
                        placeholder="Otro"
                        class="form-control form-control-lg"
                        formControlName="document"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg">Calidad </label>
                  <el-dropdown
                    [options]="notifiedQuality"
                    formControlName="notifiedQualityId"
                    customClass="form-control form-control-lg"
                    placeHolder="Elige una calidad"
                  ></el-dropdown>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg">Domicilio </label>

                  <input
                    specialIsAlphaNumeric
                    [fControl]="warningReportForm.get('notifiedAddress')"
                    type="text"
                    placeholder="Domicilio"
                    class="form-control form-control-lg"
                    formControlName="notifiedAddress"
                  />
                </div>
              </div>
              <hr />
              <label class="form-label form-control-lg sectionTitle"
                >Datos del Receptor
              </label>

              <div class="form-row mb-4">
                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg">Nombre </label>

                  <input
                    specialIsAlphaNumeric
                    [fControl]="warningReportForm.get('nameOfPersonReceving')"
                    type="text"
                    placeholder="Nombre"
                    class="form-control form-control-lg"
                    formControlName="nameOfPersonReceving"
                  />
                </div>

                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg"
                    >Tipo&nbsp;de&nbsp;Documento
                  </label>
                  <el-dropdown
                    [options]="identificationTypes"
                    formControlName="documentTypeOfPersonRecevingId"
                    customClass="form-control form-control-lg"
                    (onChange)="setNotifiedDocumetType()"
                    placeHolder="Elige un tipo de documento"
                  ></el-dropdown>
                </div>
                <div
                  class="col-md col-12 mb-1"
                  *ngIf="
                    this.warningReportForm.get('documentTypeOfPersonRecevingId')
                      .value
                  "
                >
                  <label class="form-label form-control-lg"
                    >Doc.&nbsp;Identificación
                  </label>
                  <div [ngSwitch]="notifiedDocCode">
                    <div *ngSwitchCase="'c'">
                      <input
                        type="text"
                        placeholder="Cedula"
                        class="form-control form-control-lg"
                        formControlName="documentOfPersonReceving"
                        mask="000-0000000-0"
                      />
                    </div>
                    <div *ngSwitchCase="'p'">
                      <input
                        type="text"
                        placeholder="Pasaporte"
                        class="form-control form-control-lg"
                        formControlName="documentOfPersonReceving"
                      />
                    </div>
                    <div *ngSwitchCase="'o'">
                      <input
                        type="text"
                        placeholder="Otro"
                        class="form-control form-control-lg"
                        formControlName="document"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg">Calidad </label>
                  <el-dropdown
                    [options]="notifiedQuality"
                    formControlName="qualityOfPersonRecevingId"
                    customClass="form-control form-control-lg"
                    placeHolder="Elige una calidad"
                  ></el-dropdown>
                </div>
              </div>
              <div class="form-row" *ngIf="warningReport">
                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg">Digitado por</label>
                  <input
                    type="text"
                    placeholder="Digitado por"
                    class="form-control form-control-lg"
                    readonly
                    formControlName="registeredByName"
                  />
                </div>
                <div class="col-md col-6 mb-1">
                  <label class="form-label form-control-lg"
                    >Modificado por</label
                  >
                  <input
                    type="text"
                    placeholder="Modificado por"
                    class="form-control form-control-lg"
                    readonly
                    formControlName="modifiedByName"
                  />
                </div>
              </div>
              <div *ngIf="!this.warningReportForm.get('recordStatus')?.value ? true : false"   class="form-row">
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg">Justificación de Eliminado</label>
                <textarea
                  specialIsAlphaNumeric
                  [fControl]="warningReportForm.get('justification_Reason')"
                  type="text"
                  maxlength="140"
                  readonly
                  rows="4"
                  placeholder="Justifique el motivo de eliminar el acta de apercibimiento"
                  class="form-control form-control-lg mb-3"
                  formControlName="justification_Reason"
                ></textarea>
               </div>   
              </div>              
            </form>
            <hr />
            <div class="form-row">
              <h6 class="form-label form-control-lg sectionTitle">
                Documentos de Acta
              </h6>
            </div>
            <div class="form-row mb-2">
              <div class="col">
                <app-attached-documents
                  [warningReport]="warningReport"
                  (outputfiles)="selectFiles($event)"
                  toolbar="Documentos Acta"
                  #docs
                >
                </app-attached-documents>
              </div>
            </div>
            <form [formGroup]="documentForm">
              <div *ngIf="selectedFiles?.length >= 1">
                <div class="form-row">
                  <div class="col-md col-12 mb-1">
                    <label class="form-label form-control-lg"
                      >Fecha&nbsp;y&nbsp;Hora&nbsp;Acta
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="
                          documentForm.get('reportDate').invalid ||
                          documentForm.get('reportHour').invalid
                        "
                        >*</small
                      ></label
                    >
                    <div class="input-group">
                      <input
                        class="form-control form-control-lg"
                        placeholder="Fecha Acta"
                        name="reportDate"
                        formControlName="reportDate"
                        ngbDatepicker
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        #reportDate="ngbDatepicker"
                        (dateSelect)="setEndTerm()"
                        [markDisabled]="tool.isDisabled"
                        maxlength="10"
                        [appMaskValue]="documentForm.get('reportDate').value"
                        dateMask
                        (ngModelChange)="
                          this.tool.dateVerification(
                            documentForm,
                            'reportDate',
                            $event
                          )
                        "
                        (dateSelect)="refreshMinDate()"
                      />
                      <div class="input-group-append">
                        <button
                          pButton
                          icon="pi pi-calendar"
                          class="btn btn-outline-secondary btn-lg"
                          (click)="reportDate.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md col-12 mb-1 timer">
                    <p class="cs-middle">&nbsp;</p>
                    <ngb-timepicker
                      formControlName="reportHour"
                      [readonlyInputs]="true"
                      [minuteStep]="5"
                      size="large"
                      class="mx-auto"
                      [meridian]="meridian"
                    ></ngb-timepicker>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md col-12 col-lg">
                    <label class="form-label form-control-lg"
                      >Número de Acta
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('reportNumber').invalid"
                        >*</small
                      >
                    </label>
                    <input
                      specialIsAlphaNumeric
                      [fControl]="warningReportForm.get('reportNumber')"
                      type="text"
                      placeholder="Número de acta"
                      class="form-control form-control-lg"
                      formControlName="reportNumber"
                    />
                  </div>
                  <div class="col-md col-12 mb-1">
                    <label class="form-label form-control-lg"
                      >Estatus
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('documentStatusId').invalid"
                        >*</small
                      >
                    </label>
                    <el-dropdown
                      [disabled]="!updateSingleDocument"
                      [options]="documentStatus"
                      formControlName="documentStatusId"
                      customClass="form-control form-control-lg"
                      placeHolder="Elige un estatus"
                    ></el-dropdown>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md col-12 col-lg mb-1">
                    <label class="form-label form-control-lg"
                      >Plazo Dias
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('termDays').invalid"
                        >*</small
                      ></label
                    >
                    <input
                      type="number"
                      min="0"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                      class="form-control form-control-lg"
                      formControlName="termDays"
                      (ngModelChange)="setEndTerm()"
                    />
                  </div>
                  <div class="col-md col-12 col-lg mb-1">
                    <label class="form-label form-control-lg"
                      >Fecha Fin
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('termEndDate').invalid"
                        >*</small
                      ></label
                    >
                    <div class="input-group">
                      <input
                        class="form-control form-control-lg"
                        placeholder="Fecha Fin"
                        name="termEndDate"
                        formControlName="termEndDate"
                        ngbDatepicker
                        [markDisabled]="tool.isDisabled"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        #termEndDate="ngbDatepicker"
                        readonly
                        [disabled]="true"
                      />
                      <div class="input-group-append">
                        <button
                          pButton
                          icon="pi pi-calendar"
                          class="btn btn-outline-secondary btn-lg"
                          (click)="termEndDate.toggle()"
                          type="button"
                          [disabled]="true"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md col-12 col-lg">
                    <label class="form-label form-control-lg"
                      >Conceptos
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('conceptListed').invalid"
                        >*</small
                      ></label
                    >

                    <ng-multiselect-dropdown
                      [placeholder]="'Conceptos selección multiple'"
                      [settings]="dropdownSettings"
                      [data]="concepts"
                      formControlName="conceptListed"
                      class="multiselect"
                      (onDeSelect)="getArticles(true)"
                      (onSelect)="getArticles()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md col-12 col-lg">
                    <label class="form-label form-control-lg"
                      >Articulos
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('articlesListed').invalid"
                        >*</small
                      ></label
                    >

                    <ng-multiselect-dropdown
                      [placeholder]="'Articulos selección multiple'"
                      [settings]="dropdownSettings"
                      [data]="articles"
                      formControlName="articlesListed"
                      class="multiselect"
                      [disabled]="documentForm.get('conceptListed').invalid"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <p class="text-danger" *ngIf="!this.warningReportForm.get('recordStatus')?.value ? true : false"><i class="fa fa-warning faa-flash animated faa-slow"></i>&nbsp;Este registro no se puede modificar, ya que esta eliminado.</p>
          <button
            *ngIf="!this.warningReport"
            type="button"
            class="rounded-pill btn btn-clean px-5"
            data-bs-dismiss="modal"
            (click)="cleanForm()"
          >
            Limpiar
          </button>
          <button
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="SaveWithWarning(docs.uploadedFiles?.length)"
            [disabled]="warningReportForm.invalid || saving"
            [disabled]="!this.warningReportForm.get('recordStatus')?.value ? true : false"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>

    <!-- In case of Document edition -->
    <ng-template #editDocument>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="close(true)"
          ></button>
        </div>
        <div class="modal-body" [ngClass]="{ cantEdit: !canEdit }">
          <div class="centered-content">
            <form [formGroup]="documentForm">
              <div class="form-row">
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >Fecha&nbsp;y&nbsp;Hora&nbsp;Acta
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="
                        documentForm.get('reportDate').invalid ||
                        documentForm.get('reportHour').invalid
                      "
                      >*</small
                    ></label
                  >
                  <div class="input-group">
                    <input
                      class="form-control form-control-lg"
                      placeholder="Fecha Acta"
                      name="reportDate"
                      formControlName="reportDate"
                      ngbDatepicker
                      [markDisabled]="tool.isDisabled"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      #reportDate="ngbDatepicker"
                      (dateSelect)="setEndTerm()"
                      maxlength="10"
                      [appMaskValue]="documentForm.get('reportDate').value"
                      dateMask
                      (ngModelChange)="
                        this.tool.dateVerification(
                          documentForm,
                          'reportDate',
                          $event
                        )
                      "
                      [disabled]="documentForm"
                    />
                    <div class="input-group-append">
                      <button
                        pButton
                        icon="pi pi-calendar"
                        class="btn btn-outline-secondary btn-lg"
                        (click)="reportDate.toggle()"
                        type="button"
                        [disabled]="documentForm"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-6">
                  <label class="form-label form-control-lg"
                    >Número de Acta
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('reportNumber').invalid"
                      >*</small
                    >
                  </label>
                  <input
                    specialIsAlphaNumeric
                    [fControl]="documentForm.get('reportNumber')"
                    type="text"
                    placeholder="Número de acta"
                    class="form-control form-control-lg"
                    formControlName="reportNumber"
                  />
                </div>
                <div class="col-md col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >Estatus
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('documentStatusId').invalid"
                      >*</small
                    >
                  </label>
                  <el-dropdown
                    [options]="documentStatus"
                    formControlName="documentStatusId"
                    customClass="form-control form-control-lg"
                    (onChange)="checkStatus()"
                    placeHolder="Elige un estatus"
                  ></el-dropdown>
                </div>
              </div>
              <div class="form-row" *ngIf="isDecline">
                <div class="col-md-12 col-12 mb-1">
                  <label class="form-label form-control-lg">
                    Comentario de Estatus
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('comment').invalid"
                      >*</small
                    ></label
                  >
                  <textarea
                    specialIsAlphaNumeric
                    [fControl]="documentForm.get('comment')"
                    type="text"
                    placeholder="Comentario de estatus"
                    class="form-control form-control-lg mb-3"
                    formControlName="comment"
                  ></textarea>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6 col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >Plazo Dias
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('termDays').invalid"
                      >*</small
                    ></label
                  >
                  <input
                    type="number"
                    min="0"
                    onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                    class="form-control form-control-lg"
                    formControlName="termDays"
                    (ngModelChange)="setEndTerm()"
                    [disabled]="true"
                    readonly
                  />
                </div>
                <div class="col-md-6 col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >Plazo Fin
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('termEndDate').invalid"
                      >*</small
                    ></label
                  >
                  <div class="input-group">
                    <input
                      class="form-control form-control-lg"
                      placeholder="Plazo Fin"
                      name="termEndDate"
                      formControlName="termEndDate"
                      ngbDatepicker
                      [maxDate]="maxDate"
                      [markDisabled]="tool.isDisabled"
                      [minDate]="minDate"
                      #termEndDate="ngbDatepicker"
                      readonly
                      [disabled]="true"
                      (click)="termEndDate.toggle()"
                    />
                    <div class="input-group-append">
                      <button
                        pButton
                        icon="pi pi-calendar"
                        class="btn btn-outline-secondary btn-lg"
                        (click)="termEndDate.toggle()"
                        type="button"
                        [disabled]="true"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="this.document">
                <div class="form-row">
                  <div class="col-md-6 col-6 col-lg-6 mb-1">
                    <label class="form-label form-control-lg">
                      Días Prorroga
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('extensionDays').invalid"
                        >*</small
                      >
                    </label>

                    <input
                      type="number"
                      min="0"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                      class="form-control form-control-lg"
                      formControlName="extensionDays"
                      (ngModelChange)="checkExtension()"
                    />
                  </div>
                  <div class="col-md-6 col-6 col-lg-6 mb-1">
                    <label class="form-label form-control-lg"
                      >Nueva Fecha Plazo
                      <small
                        [@warning]="tool.stateName(show)"
                        class="text-danger"
                        *ngIf="documentForm.get('newTermEndDate').invalid"
                        >*</small
                      ></label
                    >
                    <div class="input-group">
                      <input
                        class="form-control form-control-lg"
                        placeholder="Nueva Fecha Plazo"
                        name="newTermEndDate"
                        formControlName="newTermEndDate"
                        ngbDatepicker
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        #newTermEndDate="ngbDatepicker"
                        readonly
                        [markDisabled]="tool.isDisabled"
                        [disabled]="true"
                        (click)="newTermEndDate.toggle()"
                      />
                      <div class="input-group-append">
                        <button
                          pButton
                          icon="pi pi-calendar"
                          [disabled]="true"
                          class="btn btn-outline-secondary btn-lg"
                          (click)="newTermEndDate.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 col-12 mb-1" *ngIf="extension">
                  <label class="form-label form-control-lg">
                    Justificación Prorroga
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('extensionJustification').invalid"
                      >*</small
                    ></label
                  >
                  <textarea
                    specialIsAlphaNumeric
                    [fControl]="documentForm.get('extensionJustification')"
                    type="text"
                    placeholder="Justificación Prorroga"
                    class="form-control form-control-lg mb-3"
                    formControlName="extensionJustification"
                  ></textarea>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 col-12">
                  <label class="form-label form-control-lg"
                    >Conceptos
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('conceptListed').invalid"
                      >*</small
                    ></label
                  >
                  <ng-multiselect-dropdown
                    [placeholder]="'Conceptos selección multiple'"
                    [settings]="dropdownSettings"
                    [data]="concepts"
                    formControlName="conceptListed"
                    class="multiselect"
                    (onDeSelect)="getArticles(true)"
                    (onSelect)="getArticles()"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md col-12 col-lg">
                  <label class="form-label form-control-lg"
                    >Articulos
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="documentForm.get('articlesListed').invalid"
                      >*</small
                    ></label
                  >

                  <ng-multiselect-dropdown
                    [placeholder]="'Articulos selección multiple'"
                    [settings]="dropdownSettings"
                    [data]="articles"
                    formControlName="articlesListed"
                    class="multiselect"
                    [disabled]="documentForm.get('conceptListed').invalid"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button
            *ngIf="canEdit"
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="updateDocument()"
          >
            Guardar
          </button>
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="!isFilter; else isTheFilter">
    <div  *ngIf="canJustify" class="centered-content">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Eliminar Acta de Apercibimiento</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="canEdit ? close(false) : close(true)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="centered-content">
            <form [formGroup]="warningReportForm" [ngClass]="{ cantEdit: !canEdit }">
              <div class="form-row">
                <div class="col-md col-12 mb-1">
                <label class="form-label form-control-lg">
                  <small
                    [@warning]="tool.stateName(show)"
                    class="text-danger"
                    *ngIf="warningReportForm.get('justification_Reason').invalid"
                    >*Digite una Justificación.</small
                  ></label
                >
                <textarea
                  specialIsAlphaNumeric
                  [fControl]="warningReportForm.get('justification_Reason')"
                  type="text"
                  maxlength="140"
                  rows="4"
                  placeholder="Justifique el motivo de eliminar el acta de apercibimiento"
                  class="form-control form-control-lg mb-3"
                  formControlName="justification_Reason"
                ></textarea>
              </div>   
              </div>
            </form>
            
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="close(false)"
          >
            Cerrar
          </button>          
          <button
            [disabled]="warningReportForm.get('justification_Reason').invalid ? true : false"
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="SaveJustification()"
             
          >
            Guardar
          </button>
        </div>
    
      </div>
    </div>  
  </div>


</div>
<ng-template #isTheFilter>
  <div class="centered-content">
    <form [formGroup]="warningReportForm" [ngClass]="{ cantEdit: !canEdit }">
      <label class="form-label form-control-lg sectionTitle"
        >Datos de Acta Apercibimiento
      </label>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">No. de Acta </label>
          <input
            type="text"
            placeholder="Número de acta"
            formControlName="warningReportNumber"
            class="form-control form-control-lg"
            placeholder="233-233"
          />
        </div>
      </div>
    </form>
  </div>
</ng-template>
