<div class="modal-content mapContainer">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ title || "Mapa" }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="ngbActiveModal.close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="centered-content">
      <div class="map" id="map"></div>
    </div>
  </div>
</div>
