<div class="d-flex h-100 bg-primary p-1">
  <div class="bg-primary sidebar-container">
    <div class="logo">
      <img src="../../../../assets/images/Layer_1.png" alt="">
      <!-- <svg class="d-sm-block d-none" xmlns="http://www.w3.org/2000/svg" width="110" height="32.011"
        viewBox="0 0 110 32.011">
        <defs>
          <style>
            .a {
              fill: #003777;
            }

          </style>
        </defs>
        <g transform="translate(-98.299 -1213.494)">
          <path class="a"
            d="M106.01,1222.461c0-1.855,1.58-3.108,4.413-3.108a13.317,13.317,0,0,1,9.012,3.386l3.67-4.825a17.247,17.247,0,0,0-12.078-4.314c-7.293,0-11.753,4.268-11.753,9.418,0,11.644,17.838,7.887,17.838,13.361,0,1.763-1.766,3.433-5.389,3.433a13.592,13.592,0,0,1-9.848-4.176l-3.577,5.011c2.88,2.923,7.154,4.918,13.1,4.918,8.361,0,12.4-4.269,12.4-9.928C123.8,1224.085,106.01,1227.379,106.01,1222.461Z"
            transform="translate(0 -0.06)" />
          <rect class="a" width="6.597" height="30.943" transform="translate(128.221 1214.005)" />
          <path class="a"
            d="M208.911,1219.34a8.4,8.4,0,0,1,7.293,4.5l5.667-2.783a14.184,14.184,0,0,0-12.96-7.562c-9.291,0-16.491,6.5-16.491,16.006s7.2,16.005,16.491,16.005a14.27,14.27,0,0,0,12.96-7.608l-5.667-2.737a8.309,8.309,0,0,1-7.293,4.5c-5.621,0-9.709-4.314-9.709-10.16S203.29,1219.34,208.911,1219.34Z"
            transform="translate(-52.989 0)" />
          <rect class="a" width="6.597" height="30.943" transform="translate(172.802 1214.005)" />
          <path class="a" d="M293.44,1214.661v5.8H302.5V1245.6h6.643V1220.46h9.012v-5.8Z"
            transform="translate(-109.854 -0.656)" />
        </g>
      </svg> -->

    </div>
    <div class="menu ms-0">
      <div class="nav flex-column" role="tablist" aria-orientation="vertical">
        <a class="nav-link ms-1 d-flex align-items-center" [routerLink]="'/Dashboard'" value="Inspeccion"
          aria-selected="true" routerLinkActive="is-active" *ngIf="
            this.userS.user.roleCode !== 'DIG' &&
            this.tool.hasInternetConnection
          "><i class="dashboard-icon" aria-hidden="true"></i>&nbsp;Gráficos</a>
        <a class="nav-link d-flex align-items-center" [routerLink]="'/Casos'" value="Casos" aria-selected="false"
          routerLinkActive="is-active"><i class="collection-icon mx-1" aria-hidden="true"></i>&nbsp;Registro de
          Servicios</a>
        <!-- <a class="nav-link d-flex align-items-center" [routerLink]="'/Asistencia'" value="Asistencia" aria-selected="false"
          routerLinkActive="is-active"><i class="collection-icon mx-1" aria-hidden="true"></i>&nbsp;Registro de
          Asistencias</a> -->
        <a class="nav-link d-flex align-items-center" [routerLink]="'/Expedientes'" value="Expedientes"
          aria-selected="false" routerLinkActive="is-active"><i class="fa fa-suitcase mx-1"
            aria-hidden="true"></i>&nbsp;Expedientes</a>

        <div *ngIf="this.userS.user.roleCode !== 'ABOG' && this.userS.user.roleCode !== 'PLEGA'">
          <a [routerLink]="'/Reportes'" class="nav-link d-flex align-items-center ms-1" value="Reportes"
          aria-selected="false" routerLinkActive="is-active"><i class="report-icon" aria-hidden="true"
            style="margin-right: 5px"></i>&nbsp;Reportes</a>
        </div>
        
        <!--      
        <a *ngIf="
          this.userS.user.roleCode !== 'DIG' &&
          this.userS.user.roleCode !== 'INSP' &&
          this.tool.hasInternetConnection
          " [routerLink]="'/Talleres'" class="nav-link ms-1 d-flex align-items-center" value="Talleres"
          aria-selected="false" routerLinkActive="is-active"><i class="workshop" aria-hidden="true"
            style="margin-right: 4px"></i>&nbsp;Talleres</a> -->
        <a *ngIf="this.userS.user.roleCode == 'ADM' && this.tool.hasInternetConnection
          " [routerLink]="'/Admin'" class="nav-link ms-1 d-flex align-items-center" value="admin" aria-selected="false"
          routerLinkActive="is-active"><i class="admin" aria-hidden="true"
            style="margin-right: 4px"></i>&nbsp;Usuarios&nbsp;y&nbsp;Roles</a>
        <a *ngIf="this.userS.user.roleCode == 'ADM'" class="nav-link d-flex align-items-center" [routerLink]="'/Auditoria'" value="Auditoria"
            aria-selected="false" routerLinkActive="is-active"><i class="auditory"
              aria-hidden="true" style="margin-right: 5px"></i>&nbsp;Auditoria</a>
        <!-- <a *ngIf="
            this.userS.user.roleCode == 'DIR' && this.tool.hasInternetConnection
          " [routerLink]="'/Admin'" class="nav-link ms-1 d-flex align-items-center" value="admin" aria-selected="false"
          routerLinkActive="is-active"><i class="admin" aria-hidden="true"
            style="margin-right: 4px"></i>&nbsp;Usuarios&nbsp;y&nbsp;Roles</a> -->
      </div>
    </div>
  </div>
</div>
