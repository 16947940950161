import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ReportLayoutComponent } from "./layout/report-layout/report-layout.component";
import { MontlyViolationComponent } from "./reportForms/montly-violation/montly-violation.component";
import { MontlyWarningComponent } from "./reportForms/montly-warning/montly-warning.component";
import { MontlyInspectionWorkComponent } from "./reportForms/montlyInspectionWork/montly-inspection-work/montly-inspection-work.component";
import { OrderInspectionReasonsComponent } from "./reportForms/order-inspection-reasons-01/order-inspection-reasons.component";
import { ServiceOrderReportComponent } from "./reportForms/service-order-report/service-order-report.component";
import { UserServiceReportComponent } from "./reportForms/user-service-report/user-service-report.component";
import { WeeklyStatisticalReportComponent } from "./reportForms/weekly-statistical-report/weekly-statistical-report.component";
import { ByDateRangeReportComponent } from "./reportForms/by-date-range-report/by-date-range-report.component";



const routes: Routes = [
    {
        path: '',
        component: ReportLayoutComponent,
        data: { breadcrumb: `Reportes` }
        , children: [
            {
                path: 'ReportPorAbogado',
                component: ByDateRangeReportComponent
            },
            {
                path: 'ReportPorFaseProceso',
                component: ByDateRangeReportComponent
            },
            {
                path: 'ReportPorInstanciaJudicial',
                component: ByDateRangeReportComponent
            },
            {
                path: 'ReportPorMotivoVisita',
                component: ByDateRangeReportComponent
            },
            {
                path: 'ReportPorNacionalidad',
                component: ByDateRangeReportComponent
            },
            {
                path: 'ReportPorRLT',
                component: ByDateRangeReportComponent            
            },
            {
                path: 'ReportPorSectorEconomico',
                component: ByDateRangeReportComponent
            }
        ]
    }]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ReportsRouterModule { }