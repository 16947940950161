import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class ServiceorderService {

  constructor() { }
  hasCreatedOffline = new Subject<boolean>()
}
