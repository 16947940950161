<div class="card border-0" style="width: 99.4%; height: 99%; margin-left: 4px">
  <div class="card-body">
    <p class="title h4 mb-2">
      <strong class="text-bold">{{ totalTitle }}</strong>{{ title }} <br />
      <span class="h6">EN TOTAL</span>
    </p>

    <canvas *ngIf="dataT; else noData" baseChart [data]="dataT" [labels]="labels" [options]="chartOptions">
    </canvas>
    <ng-template #noData>
      <div class="text-center m-auto" style="margin-top: 7rem !important">
        <i class="pi pi-eye-slash noData-icon"></i>
        <h6 class="title">sin información</h6>
      </div>
    </ng-template>
  </div>
  <div *ngIf="hasFooter" class="footer text-center card-footer">
    <div class="col-8">
      <p class="title float-end m-auto">
        {{ totalGeneral }}
      </p>
    </div>
  </div>
</div>
