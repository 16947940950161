<div class="form-group" [style]="customStyle" *ngIf="!button; else withButtons">
  <!-- select2 -->
  <!-- [ngClass]="{
    selectPlaceHolder: (!_selectedText && !_selectedId) || !counterValue,
    select: counterValue
  }" -->
  <select name="selectFilter" [class]="customClass + ' ' + class" (change)="changeValue($event.target.value)"
    [disabled]="disabled" [class.invalid]="this.control.invalid && this.control.touched" [ngClass]="{
      selectPlaceHolder: (!_selectedText && !_selectedId) || !counterValue,
      select: counterValue
    }" class="noButton">
    <option class="placeholder" *ngIf="!_selectedText && !_selectedId" [value]="null" [disabled]="true"
      [selected]="counterValue === null">
      {{ this.placeHolder ? this.placeHolder : "Elige una opción" }}
    </option>
    <option *ngIf="_selectedText && _selectedId" [value]="_selectedId" [selected]="counterValue === _selectedId">
      {{ _selectedText }}
    </option>
    <option *ngFor="let option of options" [value]="!valueName ? option.value : option[valueName]" [selected]="
        !valueName
          ? option.value === counterValue
          : option[valueName] === counterValue
      ">
      {{ !textName ? option.text : option[textName] }}
    </option>
  </select>
</div>

<ng-template #withButtons>
  <div class="input-group mb-3">
    <select name="selectFilter" class="withButton" [ngClass]="customClass + ' ' + class + ' ' + 'form-select'"
      (change)="changeValue($event.target.value)" [disabled]="disabled" style="border-radius: 0px">
      <option [value]="null" [disabled]="true" [selected]="counterValue === null">
        {{ this.placeHolder ? this.placeHolder : "Elige una opción" }}
      </option>
      <option *ngFor="let option of options" [value]="option.value" [selected]="option.value === counterValue">
        {{ option.text }}
      </option>
    </select>
    <button [disabled]="this._selectedId == null" [class]="button.class" type="button"
      (click)="button.function(this._selectedId)" style="border-radius: 0px; border: 1px solid grey !important">
      <i [class]="button.icon"></i>
    </button>
  </div>
</ng-template>
