<div *ngIf="!isFilter; else isTheFilter">
  <div class="container-fluid pl-sm-0">
    <div class="row">
      <form [formGroup]="userServiceForm" class="pl-sm-0">
        <div class="form-row">
          <div class="col-md col-12 mb-1" *ngIf="userServiceInput">
            <label class="form-label form-control-lg">No. Servicio </label>

            <input
              type="text"
              placeholder="Numero de servicio"
              class="form-control form-control-lg"
              formControlName="userServiceNumber"
              readonly
            />
          </div>

          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg"
              >Fecha&nbsp;y&nbsp;Hora&nbsp;de&nbsp;la&nbsp;Solicitud
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="
                  userServiceForm.get('reportDate').invalid ||
                  userServiceForm.get('reportHour').invalid
                "
                >*</small
              ></label
            >
            <div class="input-group">
              <input
                class="form-control form-control-lg"
                placeholder="Fecha Solicitud"
                formControlName="reportDate"
                name="dp"
                ngbDatepicker
                [maxDate]="maxDate"
                [minDate]="minDate"
                #reportDate="ngbDatepicker"
                maxlength="10"
                [appMaskValue]="userServiceForm.get('reportDate').value"
                [markDisabled]="tool.isDisabled"
                dateMask
                (ngModelChange)="
                  this.tool.dateVerification(
                    userServiceForm,
                    'reportDate',
                    $event
                  )
                "
              />
              <div class="input-group-append">
                <button
                  pButton
                  icon="pi pi-calendar"
                  class="btn btn-outline-secondary btn-lg"
                  (click)="reportDate.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-md col-6 timer">
            <p class="cs-middle">&nbsp;</p>
            <ngb-timepicker
              formControlName="reportHour"
              [minuteStep]="5"
              size="large"
              class="mx-auto"
              [meridian]="meridian"
            ></ngb-timepicker>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Representación Local
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('repLocalProvId').invalid"
                >*</small
              >
            </label>

            <el-dropdown
              [options]="repLocalProvs"
              formControlName="repLocalProvId"
              customClass="form-control form-control-lg"
              [disabled]="true"
              placeHolder="Elige una representación local"
            ></el-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg"
              >Tipo Solicitud
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('LocalApplicant').invalid"
                >*</small
              >
            </label>

            <el-dropdown
              [options]="LocalTypeApplicants"
              customClass="form-control form-control-lg"
              (onChange)="chooseTypeApplicant($event)"
              [stringOut]="true"
              formControlName="LocalApplicant"
              placeHolder="Elige un tipo de solicitud"
            ></el-dropdown>
          </div>
        </div>
        <div *ngIf="userServiceForm.get('LocalApplicant').value">
          <div [ngSwitch]="userServiceForm.get('LocalApplicant').value">
            <div *ngSwitchCase="'G'">
              <div class="form-row">
                <div class="col-md-12 col mb-1">
                  <label class="form-label form-control-lg">
                    Cantidad Solicitantes
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="
                        userServiceForm.get('groupQuantity').invalid ||
                        userServiceForm.get('groupQuantity').value == 0
                      "
                      >*</small
                    >
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger h6"
                      *ngIf="
                        userServiceForm.get('applicantsListed').value.length < 2
                      "
                      >En grupal es requerido almenos 2 solicitantes</small
                    >
                    <small
                      [@warning]="tool.stateName(show)"
                      class="red h6"
                      *ngIf="
                        userServiceForm.get('applicantsListed').value.length >=
                          2 &&
                        userServiceForm.get('applicantsListed').value.length !==
                          userServiceForm.get('groupQuantity').value
                      "
                      >Debes presionar el boton de agregar
                    </small>
                  </label>
                  <div class="input-group">
                    <input
                      type="number"
                      min="0"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                      class="form-control form-control-lg"
                      formControlName="groupQuantity"
                    />

                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary btn-lg"
                        (click)="
                          increaseGroupCount(
                            userServiceForm.get('groupQuantity').value
                          )
                        "
                        type="button"
                        [disabled]="
                          userServiceForm.get('groupQuantity').value ==
                          GroupCount
                        "
                      >
                        Agregar
                        <!-- {{userServiceForm.get('groupQuantity').value < GroupCount?'Disminuir':'Aumentar'}} -->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="userServiceForm.get('groupQuantity').value >= 1"
              ></div>
              <div formArrayName="applicantsListed">
                <div
                  class="card mb-1"
                  *ngFor="
                    let applicant of userServiceForm.controls?.applicantsListed
                      .controls;
                    let i = index
                  "
                >
                  <div class="card-header">
                    <h5 class="float-start card-header-text">
                      {{ "Solicitante " + (i + 1) }}
                    </h5>
                    <button
                      pRipple
                      pButton
                      icon="pi pi-trash"
                      name="Eliminar solicitante"
                      title="Eliminar solicitante"
                      class="
                        p-button-rounded p-button-danger p-button-text
                        css-label-btn-grid
                        float-end
                      "
                      (click)="removeApplicant(i)"
                    ></button>
                    <!-- <button
                      pButton
                      icon="pi pi-trash"
                      iconPos="left"
                      name="Eliminar solicitante"
                      label="Eliminar"
                      title="Eliminar solicitante"
                      class="
                        p-button-rounded p-button-danger
                        css-label-btn-grid
                        float-end
                      "
                      (click)="removeApplicant(i)"
                    ></button> -->
                  </div>
                  <div class="card-body" [formGroupName]="i">
                    <div>
                      <div class="form-row">
                        <div class="col-md-6 col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Tipo de Solicitante
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantTypeId.invalid
                              "
                              >*</small
                            >
                          </label>

                          <el-dropdown
                            [options]="applicantTypes"
                            formControlName="applicantTypeId"
                            customClass="form-control form-control-lg"
                            (onChange)="
                              otherTypeApplicant(
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantTypeId.value,
                                i
                              )
                            "
                            placeHolder="Elige un tipo de solicitante"
                          ></el-dropdown>
                        </div>
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Sexo
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.sex.invalid
                              "
                              >*</small
                            >
                          </label>

                          <el-dropdown
                            [options]="genders"
                            formControlName="sex"
                            customClass="form-control form-control-lg"
                            [stringOut]="true"
                            placeHolder="Elige un Sexo"
                          ></el-dropdown>
                        </div>
                      </div>
                      <div
                        class="form-row"
                        *ngIf="
                          isOtherApplicant(
                            userServiceForm.controls?.applicantsListed.controls[
                              i
                            ].controls.applicantTypeId.value
                          )
                        "
                      >
                        <div class="col-md-12 col-12 mb-1">
                          <label class="form-label form-control-lg">
                            Justificación Otros Solicitantes
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantTypeOther
                                  .invalid
                              "
                              >*</small
                            ></label
                          >
                          <textarea
                            specialIsAlphaNumeric
                            [fControl]="
                              userServiceForm.controls?.applicantsListed
                                .controls[i].controls.applicantTypeOther
                            "
                            type="text"
                            placeholder="Justificación otros solicitantes"
                            class="form-control form-control-lg mb-3"
                            formControlName="applicantTypeOther"
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Condición Solicitante
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantConditionId
                                  .invalid
                              "
                              >*</small
                            >
                          </label>

                          <el-dropdown
                            [options]="conditions"
                            formControlName="applicantConditionId"
                            customClass="form-control form-control-lg"
                            placeHolder="Elige una condición del solicitante"
                          ></el-dropdown>
                        </div>
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Nacionalidad
                          </label>

                          <el-dropdown
                            [options]="nationalityDrop"
                            formControlName="nationalityId"
                            customClass="form-control form-control-lg"
                            placeHolder="Opcional elige una nacionalidad"
                          ></el-dropdown>
                        </div>                       

                      </div>
                      <div class="form-row">
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Tipo de Documento
                          </label>

                          <el-dropdown
                            [options]="identificationTypes"
                            (onChange)="setDocumetType(
                              userServiceForm.controls?.applicantsListed
                                .controls[i].controls,

                                i

                            )"
                            formControlName="identificationTypeId"
                            customClass="form-control form-control-lg"
                            placeHolder="Opcional elige un tipo de documento identidad"
                          ></el-dropdown>
                        </div>
                        <div class="col-md col-6 mb-1" *ngIf="documentTypeCode">
                          <label class="form-label form-control-lg"
                            >Documento de Identificación
                          </label>

                          <div [ngSwitch]="documentTypeCode">
                            <div *ngSwitchCase="'cd'">
                              <input
                                type="text"
                                placeholder="Cedula"
                                class="form-control form-control-lg"
                                formControlName="identification"
                                mask="000-0000000-0"
                              />
                            </div>
                            <div *ngSwitchCase="'ps'">
                              <input
                                type="text"
                                placeholder="Pasaporte"
                                class="form-control form-control-lg"
                                formControlName="identification"
                                maxlength="16"
                              />
                            </div>
                            <div *ngSwitchCase="'o'">
                              <input
                                type="text"
                                placeholder="Otro"
                                class="form-control form-control-lg"
                                formControlName="identification"
                                maxlength="16"
                              />
                            </div>
                        </div>                                                 
                       </div>                         
                      </div>                     

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'I'">
              <div formArrayName="applicantsListed">
                <div
                  class="card mb-1"
                  *ngFor="
                    let applicant of userServiceForm.controls?.applicantsListed
                      .controls;
                    let i = index
                  "
                >
                  <div class="card-header">
                    <h5 class="float-start card-header-text">
                      {{ "Solicitante " }}
                    </h5>
                  </div>
                  <div class="card-body" [formGroupName]="i">
                    <div>
                      <div class="form-row">
                        <div class="col-md-6 col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Tipo de Solicitante
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantTypeId.invalid
                              "
                              >*</small
                            >
                          </label>

                          <el-dropdown
                            [options]="applicantTypes"
                            formControlName="applicantTypeId"
                            customClass="form-control form-control-lg"
                            (onChange)="
                              otherTypeApplicant(
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantTypeId.value,
                                i
                              )
                            "
                            placeHolder="Elige un tipo de solicitante"
                          ></el-dropdown>
                        </div>
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Sexo
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.sex.invalid
                              "
                              >*</small
                            >
                          </label>

                          <el-dropdown
                            [options]="genders"
                            formControlName="sex"
                            customClass="form-control form-control-lg"
                            [stringOut]="true"
                            placeHolder="Elige un sexo"
                          ></el-dropdown>
                        </div>
                      </div>
                      <div
                        class="form-row"
                        *ngIf="
                          isOtherApplicant(
                            userServiceForm.controls?.applicantsListed.controls[
                              i
                            ].controls.applicantTypeId.value
                          )
                        "
                      >
                        <div class="col-md-12 col-12 mb-1">
                          <label class="form-label form-control-lg">
                            Justificación Otros Solicitantes
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantTypeOther
                                  .invalid
                              "
                              >*</small
                            ></label
                          >
                          <textarea
                            specialIsAlphaNumeric
                            [fControl]="
                              userServiceForm.controls?.applicantsListed
                                .controls[i].controls.applicantTypeOther
                            "
                            type="text"
                            placeholder="Justificación otros solicitantes"
                            class="form-control form-control-lg mb-3"
                            formControlName="applicantTypeOther"
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Condición Solicitante
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="
                                userServiceForm.controls?.applicantsListed
                                  .controls[i].controls.applicantConditionId
                                  .invalid
                              "
                              >*</small
                            >
                          </label>

                          <el-dropdown
                            [options]="conditions"
                            formControlName="applicantConditionId"
                            customClass="form-control form-control-lg"
                            placeHolder="Elige una condición del solicitante"
                          ></el-dropdown>
                        </div>
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Nacionalidad
                          </label>

                          <el-dropdown
                            [options]="nationalityDrop"
                            formControlName="nationalityId"
                            customClass="form-control form-control-lg"
                            placeHolder="Opcional elige una nacionalidad"
                          ></el-dropdown>
                        </div>


                      </div>
                      <div class="form-row">
                        <div class="col-md col-6 mb-1">
                          <label class="form-label form-control-lg"
                            >Tipo de Documento
                          </label>

                          <el-dropdown
                            [options]="identificationTypes"
                            (onChange)="setDocumetType(
                              userServiceForm.controls?.applicantsListed
                                .controls[i].controls,

                                i

                            )"
                            formControlName="identificationTypeId"
                            customClass="form-control form-control-lg"
                            placeHolder="Opcional elige un tipo de documento identidad"
                          ></el-dropdown>
                        </div>
                        <div class="col-md col-6 mb-1" *ngIf="documentTypeCode">
                          <label class="form-label form-control-lg"
                            >Documento de Identificación
                          </label>

                          <div [ngSwitch]="documentTypeCode">
                            <div *ngSwitchCase="'cd'">
                              <input
                                type="text"
                                placeholder="Cedula"
                                class="form-control form-control-lg"
                                formControlName="identification"
                                mask="000-0000000-0"
                              />
                            </div>
                            <div *ngSwitchCase="'ps'">
                              <input
                                type="text"
                                placeholder="Pasaporte"
                                class="form-control form-control-lg"
                                formControlName="identification"
                                maxlength="16"
                              />
                            </div>
                            <div *ngSwitchCase="'o'">
                              <input
                                type="text"
                                placeholder="Otro"
                                class="form-control form-control-lg"
                                formControlName="identification"
                                maxlength="16"
                              />
                            </div>
                        </div>                                                 
                       </div>                         
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-12 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Tipos de Servicio
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('typeOfServiceListed').invalid"
                >*</small
              ></label
            >
            <ng-multiselect-dropdown
              [placeholder]="'Tipos de Servicio Selección Multiple'"
              [settings]="dropdownSettings"
              [data]="serviceTypes"
              [ngClass]="setClass()"
              formControlName="typeOfServiceListed"
              class="multiselect"
              (ngModelChange)="otherServices()"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 col-12 mb-1" *ngIf="otherService">
            <label class="form-label form-control-lg">
              Justificación Otros Servicios
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('typeOfServiceOther').invalid"
                >*</small
              ></label
            >
            <textarea
              specialIsAlphaNumeric
              [fControl]="userServiceForm.get('typeOfServiceOther')"
              type="text"
              placeholder="Justificación otros servicios"
              class="form-control form-control-lg mb-3"
              formControlName="typeOfServiceOther"
            ></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg"
              >Forma Solicitud
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('requestFormId').invalid"
                >*</small
              >
            </label>

            <el-dropdown
              [options]="requestForms"
              formControlName="requestFormId"
              customClass="form-control form-control-lg"
              (onChange)="otherRequestForm()"
              placeHolder="Elige una forma de solicitud"
            ></el-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg"
              >Rama de Actividad
            </label>

            <el-dropdown
              [options]="activityBranchs"
              formControlName="activityBranchRLTId"
              customClass="form-control form-control-lg"
              placeHolder="Opcional elige una rama de actividad de la Empresa"
            ></el-dropdown>
          </div>          
        </div>
        <div class="form-row">
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg"
              >A que se dedica la Empresa
            </label>
  
            <input
              specialIsAlphaNumeric
              [fControl]="userServiceForm.get('companyArea')"
              type="text"
              placeholder="Nombre del Trabajador"
              class="form-control form-control-lg"
              formControlName="companyArea"
              maxlength="250"
            />
          </div>         
        </div>        
        <div class="form-row">
          <div class="col-md col-12 mb-1" *ngIf="otherForm">
            <label class="form-label form-control-lg">
              Justificación Forma Solicitud
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('requestFormOther').invalid"
                >*</small
              ></label
            >
            <textarea
              specialIsAlphaNumeric
              [fControl]="userServiceForm.get('requestFormOther')"
              type="text"
              placeholder="Justificación forma solicitud"
              rows="2"
              class="form-control form-control-lg"
              formControlName="requestFormOther"
            ></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg">Observaciones </label>

            <textarea
              specialIsAlphaNumeric
              [fControl]="userServiceForm.get('observations')"
              type="text"
              placeholder="Observaciones"
              rows="2"
              class="form-control form-control-lg"
              formControlName="observations"
            ></textarea>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg"
              >Grupo
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="userServiceForm.get('groupId').invalid"
                >*</small
              >
            </label>

            <el-dropdown
              [options]="Groups"
              formControlName="groupId"
              customClass="form-control form-control-lg"
              placeHolder="Elige un grupo"
            ></el-dropdown>
          </div>
        </div>
        <div class="form-row" *ngIf="userServiceInput">
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Digitado por</label>
            <input
              type="text"
              placeholder="Digitado por"
              class="form-control form-control-lg"
              readonly
              formControlName="registeredByName"
            />
          </div>

          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Modificado por</label>
            <input
              type="text"
              placeholder="Modificado por"
              class="form-control form-control-lg"
              readonly
              formControlName="modifiedByName"
            />
          </div>
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Fecha Cre.</label>
            <input
              type="text"
              placeholder="Fecha de creación"
              class="form-control form-control-lg"
              readonly
              formControlName="registrationDateStr"
            />
          </div>
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Fecha Modi.</label>
            <input
              type="text"
              placeholder="Fecha de modificación"
              class="form-control form-control-lg"
              readonly
              formControlName="recordModificationDateStr"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="btn-group float-right m-3">
      <button
        type="button"
        class="rounded-pill btn btn-save px-5"
        (click)="saveRecord()"
      >
        Guardar
      </button>
    </div>
  </div>
</div>
<ng-template #isTheFilter>
  <div class="container-fluid">
    <div class="row">
      <form [formGroup]="userServiceForm">
        <div class="form-row">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg"
              >Fecha&nbsp;&nbsp;&nbsp;de&nbsp;la&nbsp;Solicitud
            </label>
            <div class="input-group">
              <input
                class="form-control form-control-lg"
                placeholder="Fecha Solicitud"
                formControlName="reportDate"
                ngbDatepicker
                [maxDate]="maxDate"
                [minDate]="minDate"
                #reportDate="ngbDatepicker"
                dateMask
                [markDisabled]="tool.isDisabled"
                (onModelChange)="
                  this.tool.dateVerification(
                    userServiceForm,
                    'reportDate',
                    $event
                  )
                "
              />
              <div class="input-group-append">
                <button
                  pButton
                  icon="pi pi-calendar"
                  class="btn btn-outline-secondary btn-lg"
                  (click)="reportDate.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg">Forma Solicitud </label>

            <el-dropdown
              [options]="requestForms"
              formControlName="requestFormId"
              customClass="form-control form-control-lg"
              (onChange)="otherRequestForm()"
              placeHolder="Elige una forma de solicitud"
            ></el-dropdown>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
