<form [formGroup]="byDateRangeForm">
  <div class="row" style="margin-top: 20px;">
    <div *ngIf="byDateRangeForm.hasError('fechaInvalida')">
      <label class="form-label form-control-lg d-block" for="">La fecha desde no puede ser superior a la fecha hasta</label>
    </div>
    <div class="col-12 col-md-3" style="margin-right: -92px;">
      <div class="form-group">
        <label class="form-label form-control-lg d-block" for="">Fecha Desde: 
          <small
          [@warning]="tool.stateName(show)"
          class="text-danger"
          *ngIf="!byDateRangeForm.get('startDate').value"
          >*</small
        >
        </label>
        <input (change)="validarRangoFecha()" formControlName="startDate" type="date" name="" class="form-control w-75" />
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group">
        <label class="form-label form-control-lg d-block" for="">Fecha Hasta:
          <small
          [@warning]="tool.stateName(show)"
          class="text-danger"
          *ngIf="!byDateRangeForm.get('endDate').value"
          >*</small
        >
        </label>
        <input (change)="validarRangoFecha()"  formControlName="endDate" type="date" name="" class="form-control w-75" />
      </div>
    </div>
  </div>
  <div class="btn-group float-right m-3">
    <button
      type="submit"
      class="rounded-pill btn btn-save px-5"
      (click)="generateReport()"
      [disabled] ="disableButtonGenerateReport"
    >
      Generar Reporte
    </button>
  </div>
</form>
