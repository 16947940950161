<a routerLink="/Expedientes" class="btn btn-light mb-4 header-bottom">
  <i class="fa fa-arrow-left"></i> Volver a Listado de Registros
</a>
<div class="container-fluid">
  <div *ngIf="record">
    <app-form-expedient-header [AbogadoPrincipal]="AbogadoPrincipal" [AbogadoAlternoNombre]="AbogadoAlternoNombre"
      [record]="record" [expedientCerrado]="expedientCerrado">
    </app-form-expedient-header>
    <div class="row mb-4">
      <div class="col-12 col-md-6">
        <h2 class="text-left">Ficha Basica</h2>
      </div>
      <div class="col-12 col-md-6">
        <div *ngIf="this.user.roleCode == 'ABOG' || this.user.roleCode == 'ENCA' || this.user.roleCode == 'COOR'">
          <button class="btn btnOutlinedDanger float-end" data-bs-toggle="modal" data-bs-target="#closeConfirm"
        >
            <i class="fa fa-minus"></i> Cerrar Expediente
          </button>
        </div>

      </div>
    </div>

    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            <i class="fa fa-clock-o mx-2" aria-hidden="true" id="iconoCheckflush-collapseOne"></i>
            INFORMACION GENERAL
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <app-form-expedient-informacion-general *ngIf="record?.idAsistenciaDiariaEmpleador" [record]="record"
              [expedientCerrado]="expedientCerrado" [recordDetailId]="recordDetailId" [user]="user"
              [expedientResult]="expedientResult"></app-form-expedient-informacion-general>
          </div>
        </div>
      </div>
      <div class="accordion-item empresa-formalizada" id="empresa-formalizada">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#EmpresaFormalizada-collapseOne" aria-expanded="false"
            aria-controls="EmpresaFormalizada-collapseOne" id="acordionEmpresaFormalizada">
            <i class="fa fa-check-circle-o mx-2 iconoCheckEmpresaFormalizada"
              id="iconoCheckEmpresaFormalizada-collapseOne" aria-hidden="true"></i>
            PARTE DEMANDADA &nbsp; <span class="text-muted">(EMPLEADOR)</span>
          </button>
        </h2>
        <div id="EmpresaFormalizada-collapseOne" class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingOne">
          <div class="accordion-body">
            <app-form-demandada *ngIf="record?.idAsistenciaDiariaEmpleador" [expedientCerrado]="expedientCerrado"
              [record]="record" [user]="user" [nationalityDrop]="nationalityDrop" [civilStatusDrop]="civilStatusDrop"
              [identificationDrop]="identificationDrop"></app-form-demandada>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            <i class="fa fa-clock-o mx-2" id="iconoCheckflush-collapseThree" aria-hidden="true"></i>
            PARTE DEMANDANTE &nbsp; <span class="text-muted">(TRABAJADOR)</span>
          </button>
        </h2>
        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <app-form-demandante *ngIf="record?.idAsistenciaDiariaEmpleador" [record]="record" [user]="user"
              [expedientCerrado]="expedientCerrado" [nationalityDrop]="nationalityDrop"
              [civilStatusDrop]="civilStatusDrop" [identificationDrop]="identificationDrop" [sexDrop]="sexDrop"
              [typeApplicantDrop]="typeApplicantDrop" [rTEDrop]="rTEDrop" [reasonOfVisitDrop]="reasonOfVisitDrop"
              [referidoDrop]="referidoDrop" [abogadosDrop]="abogadosDrop" [expedientResult]="expedientResult">
            </app-form-demandante>
          </div>
        </div>
      </div>
      <div class="accordion-item" id="FundamentosDemanda">
        <h2 class="accordion-header" id="flush-headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
            <i class="fa fa-clock-o mx-2" id="iconoCheckflush-collapseFour" aria-hidden="true"></i>
            FUNDAMENTOS DE LA DEMANDA
          </button>
        </h2>
        <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <app-form-fundamento-demanda *ngIf="record?.idAsistenciaDiariaEmpleador"
              [expedientCerrado]="expedientCerrado" [record]="record" [user]="user" [expedientResult]="expedientResult">
            </app-form-fundamento-demanda>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSevent" aria-expanded="false" aria-controls="flush-collapseSevent">
            <i class="fa fa-clock-o mx-2" id="iconoCheckflush-collapseSevent" aria-hidden="true"></i>
            ARCHIVOS DE PRUEBA
          </button>
        </h2>
        <div id="flush-collapseSevent" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <app-form-archivos-prueba [expedientResult]="expedientResult"></app-form-archivos-prueba>
          </div>
        </div>
      </div>
      <div class="accordion-item" *ngIf="this.expedientResult?.value?.idExpediente">
        <h2 class="accordion-header" id="flush-headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
            <i class="fa fa-clock-o mx-2" id="iconoCheckflush-collapseSix" aria-hidden="true"></i>
            NOTAS
          </button>
        </h2>
        <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <button class="btn btn-agregarNota" (click)="onPressAddNote()">
              <i class="fa fa-plus-circle" aria-hidden="true"></i> Agregar Nota
            </button>
            <app-notes-history *ngIf="this.codigo" [codigo]="this.codigo"></app-notes-history>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-5">
      <div class="col-12 col-md-12">
        <button (click)="guardarTodo()"
          [disabled]="expedientCerrado || this.user.roleCode === 'ADM' || this.user.roleCode === 'DIR' || this.user.roleCode === 'PLEGA' || this.user.roleCode === 'ENCA'"
          class="btn btnSaveSubmitForm float-end" id="btnSaveAll">
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="closeConfirm" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-12 col-md-12">
            <img src="../../../../assets/images/warning_exclamation_icon.png"
              style="margin-left: 45%; margin-bottom: 5%;" alt="">
          </div>
        </div>
        <h3 class="text-center mb-3">Cerrar Expediente</h3>
        <p class="parrafoModal text-justify">
          ¿Está seguro/a que desea ejecutar esta acción? La misma no se podrá
          revertir.
        </p>
      </div>
      <div class="modal-footer border-0 justify-content-center">
        <button type="button" class="btn btn-si-continuar" data-bs-dismiss="modal" (click)="openModal()">
          Continuar
        </button>
        <button type="button" class="btn btn-cancelar" data-bs-dismiss="modal">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
