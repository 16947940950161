import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

@Component({
  selector: 'donut-chart',
  templateUrl: './donutchart.component.html',
  styleUrls: ['./donutchart.component.css']
})
export class donutChartComponent implements OnInit {
  constructor() { }
  @Input() title: string;
  @Input() type: ChartType;
  @Input() totalTitle: string;
  @Input() data: number[]
  @Input() labels: string[]
  @Input() total: string | number
  @Input() colors: string[];
  @ViewChild(BaseChartDirective) chart: Chart
  image = new Image();
  Plugins: any;
  doughnutChartLabels: string[];
  chartColors: any[];
  doughnutChartData: any;
  chartOptions: ChartOptions
  ngOnInit(): void {

    if (this.data.reduce((a, b) => { return a + b }, 0) == 0) {
      this.data = null
    }
    this.createChart()



  }



  createChart() {

    this.chartColors = [{
      backgroundColor: this.colors
    }];



    this.chartOptions = {

      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 5,
      elements: {
        arc: {
          borderWidth: 0
        }
      },

      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: 'center',
          labels: {
            pointStyle: 'circle',
            usePointStyle: true,
            padding: 25,
            textAlign: 'left',
          },
        },

        // tooltip: {
        //   callbacks: {
        //     label: function (context) {
        //       var data = context.dataset.data,
        //         label = context.label,
        //         currentValue = context.raw,
        //         total = 0;

        //       for (var i = 0; i < data.length; i++) {
        //         total += (data[i] as number);
        //       }
        //       var percentage = parseFloat(((currentValue as number) / total * 100).toFixed(1));

        //       return label + ": " + currentValue + ' (' + percentage + '%)';
        //     }
        //   }
        // }
      },

    };

  }
}