import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { authRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { BrowserModule } from '@angular/platform-browser';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { SesionServiceService } from 'src/app/_services/sesion-service.service';



@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    authRoutingModule,
    ReactiveFormsModule,
    FormsModule,

  ],
  providers: [JwtHelperService, SesionServiceService]
})
export class AuthModule { }
