<div *ngIf="!isFilter; else isTheFilter">
    <div class="modal-content" [ngClass]="{ cantEditCols: !canEdit }">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="canEdit ? close(false) : close(true)"
        ></button>
      </div>
      <div class="modal-body">
        <div class="centered-content">
            <form [formGroup]="workerDataForm" [ngClass]="{ cantEdit: !canEdit }">
                <div class="form-row">
                  <div class="col-md col-6 mb-1">
                    <label class="form-label form-control-lg"
                      >Nombre del Trabajador
                    </label>
          
                    <input
                      specialIsAlphaNumeric
                      [fControl]="workerDataForm.get('workerName')"
                      type="text"
                      placeholder="Nombre del Trabajador"
                      class="form-control form-control-lg"
                      formControlName="workerName"
                      maxlength="250"
                    />
                  </div>
                  <div class="col-md col-6 mb-1">
                    <label class="form-label form-control-lg"
                      >Tipo de Documento&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <el-dropdown
                      [options]="identificationTypes"
                      formControlName="identificationTypeId"
                      customClass="form-control form-control-lg"
                      (onChange)="setDocumetType()"
                      placeHolder="Elige un tipo de documento"
                    ></el-dropdown>
                  </div>
                  <div
                    class="col-md col-12 mb-1"
                    *ngIf="workerDataForm.get('identificationTypeId').value"
                  >
                    <label class="form-label form-control-lg"
                      >Documento de Identificación
                      <small
                        class="text-danger"
                        *ngIf="workerDataForm.get('workerIdentification').invalid"
                        >*</small
                      >
                    </label>
          
                    <div [ngSwitch]="documentTypeCode">
                      <div *ngSwitchCase="'cd'">
                        <input
                          type="text"
                          placeholder="Cedula"
                          class="form-control form-control-lg"
                          formControlName="workerIdentification"
                          mask="000-0000000-0"
                        />
                      </div>
                      <div *ngSwitchCase="'ps'">
                        <input
                          type="text"
                          placeholder="Pasaporte"
                          class="form-control form-control-lg"
                          formControlName="workerIdentification"
                          maxlength="16"
                        />
                      </div>
                      <div *ngSwitchCase="'o'">
                        <input
                          type="text"
                          placeholder="Otro"
                          class="form-control form-control-lg"
                          formControlName="workerIdentification"
                          maxlength="16"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row mb-2" *ngIf="!isFilter">
                  <div class="col-md col-6 mb-1">
                    <label class="form-label form-control-lg">Domicilio </label>
          
                    <input
                      specialIsAlphaNumeric
                      [fControl]="workerDataForm.get('workerAddress')"
                      type="text"
                      placeholder="Domicilio"
                      class="form-control form-control-lg"
                      formControlName="workerAddress"
                      maxlength="150"
                    />
                  </div>
                  <div class="col-md col-6 mb-1">
                    <label class="form-label form-control-lg">Teléfono</label>
          
                    <input
                      specialIsAlphaNumeric
                      [fControl]="workerDataForm.get('workerPhone')"
                      type="text"
                      placeholder="Telefono"
                      class="form-control form-control-lg"
                      formControlName="workerPhone"
                      mask="(000)-000-0000"
                    />
                  </div>
                  <div class="col-md col-6 mb-1">
                    <label class="form-label form-control-lg">Extensión </label>
          
                    <input
                      specialIsAlphaNumeric
                      [fControl]="workerDataForm.get('workerExtension')"
                      type="text"
                      placeholder="Extensión"
                      class="form-control form-control-lg"
                      formControlName="workerExtension"
                      maxlength="6"
                    />
                  </div>
                  <div class="col-md col-6 mb-1">
                    <label class="form-label form-control-lg">Email </label>
          
                    <input
                      specialIsAlphaNumeric
                      [fControl]="workerDataForm.get('workerEmail')"
                      type="email"
                      class="form-control form-control-lg"
                      formControlName="workerEmail"
                      maxlength="60"
                      ngModel email="true"
                    />
                     <small
                      class="text-danger"
                      *ngIf="workerDataForm.get('workerEmail').errors?.email"
                      >El email no es valido</small
                    >
                  </div>
                </div>    
              </form>
        </div>
        <div class="modal-footer" *ngIf="canEdit">
          <button
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            (click)="close(false)"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="rounded-pill btn btn-save px-5"
            data-bs-dismiss="modal"
            [disabled]="!workerDataForm.get('workerIdentification').valid ? true : false"
            [disabled]="workerDataForm.get('workerEmail').errors?.email"
            (click)="onClickSave()"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #isTheFilter>
    <div class="centered-content" [ngClass]="{ cantEditCols: !canEdit }">
      <form [formGroup]="workerDataForm" [ngClass]="{ cantEdit: !canEdit }">
        <div class="form-row">
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg"
              >Nombre del Trabajador
            </label>
  
            <input
              specialIsAlphaNumeric
              [fControl]="workerDataForm.get('workerName')"
              type="text"
              placeholder="Nombre del Trabajador"
              class="form-control form-control-lg"
              formControlName="workerName"
              maxlength="250"
            />
          </div>
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg"
              >Tipo de Documento&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <el-dropdown
              [options]="identificationTypes"
              formControlName="identificationTypeId"
              customClass="form-control form-control-lg"
              (onChange)="setDocumetType()"
              placeHolder="Elige un tipo de documento"
            ></el-dropdown>
          </div>
          <div
            class="col-md col-12 mb-1"
            *ngIf="workerDataForm.get('identificationTypeId').value"
          >
            <label class="form-label form-control-lg"
              >Documento de Identificación
              <small
                class="text-danger"
                *ngIf="workerDataForm.get('workerIdentification').invalid"
                >*</small
              >
            </label>
  
            <div [ngSwitch]="documentTypeCode">
              <div *ngSwitchCase="'cd'">
                <input
                  type="text"
                  placeholder="Cedula"
                  class="form-control form-control-lg"
                  formControlName="workerIdentification"
                  mask="000-0000000-0"
                />
              </div>
              <div *ngSwitchCase="'ps'">
                <input
                  type="text"
                  placeholder="Pasaporte"
                  class="form-control form-control-lg"
                  formControlName="workerIdentification"
                  maxlength="16"
                />
              </div>
              <div *ngSwitchCase="'o'">
                <input
                  type="text"
                  placeholder="Otro"
                  class="form-control form-control-lg"
                  formControlName="workerIdentification"
                  maxlength="16"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  
