<div *ngIf="!isFilter; else isTheFilter">
  <div
    class="centered-content"
    [ngClass]="{ cantEditCols: !canEdit || this.checkPermission() }"
  >
    <form
      [formGroup]="assignmentForm"
      [ngClass]="{ cantEdit: !canEdit || this.checkPermission() }"
    >
      <div class="form-row">
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg"
            >Representante local
            <small
              class="text-danger"
              *ngIf="assignmentForm.get('localRepresentativeId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="localRepresentative"
            formControlName="localRepresentativeId"
            customClass="form-control form-control-lg"
            (onChange)="representativeDependency($event)"
            placeHolder="Elige un representante local"
          ></el-dropdown>
        </div>
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">
            Supervisor
            <small
              class="text-danger"
              *ngIf="assignmentForm.get('supervisorId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="supervisors"
            formControlName="supervisorId"
            customClass="form-control form-control-lg"
            [disabled]="assignmentForm.get('localRepresentativeId').invalid"
            (onChange)="supervisorDependency($event)"
            placeHolder="Elige un supervisor"
          ></el-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg"
            >Asignar Inspector
            <small
              class="text-danger"
              *ngIf="assignmentForm.get('assignedInspectorId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="inspectors"
            formControlName="assignedInspectorId"
            customClass="form-control form-control-lg"
            [disabled]="assignmentForm.get('supervisorId').invalid"
            (onChange)="asignInspector()"
            placeHolder="Elige un inspector"
          ></el-dropdown>
        </div>
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">Grupo </label>
          <el-dropdown
            [options]="Groups"
            formControlName="groupId"
            customClass="form-control form-control-lg"
            placeHolder="Elige un grupo"
          ></el-dropdown>
        </div>
      </div>
      <div class="form-row" *ngIf="inspectorChanged">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">
            Justificación Cambio Inspector
            <small
              class="text-danger"
              *ngIf="assignmentForm.get('justificationInspectorChange').invalid"
              >*</small
            ></label
          >
          <textarea
            specialIsAlphaNumeric
            [fControl]="assignmentForm.get('justificationInspectorChange')"
            type="text"
            class="form-control form-control-lg mb-3"
            formControlName="justificationInspectorChange"
          ></textarea>
        </div>
      </div>
      <div class="form-row" *ngIf="orderAssignment">
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">Digitado por</label>
          <input
            type="text"
            placeholder="Digitado por"
            class="form-control form-control-lg"
            readonly
            [value]="orderAssignment.registeredByName"
          />
        </div>
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">Modificado por</label>
          <input
            type="text"
            placeholder="Modificado por"
            class="form-control form-control-lg"
            readonly
            [value]="orderAssignment.modifiedByName"
          />
        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #isTheFilter>
  <div class="centered-content">
    <form [formGroup]="assignmentForm">
      <div class="form-row">
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg"
            >Representante local
            <small
              class="text-danger"
              *ngIf="assignmentForm.get('localRepresentativeId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="localRepresentative"
            formControlName="localRepresentativeId"
            customClass="form-control form-control-lg"
            (onChange)="representativeDependency($event)"
            placeHolder="Elige un representante local"
          ></el-dropdown>
        </div>
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">
            Supervisor
            <small
              class="text-danger"
              *ngIf="assignmentForm.get('supervisorId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="supervisors"
            formControlName="supervisorId"
            customClass="form-control form-control-lg"
            [disabled]="assignmentForm.get('localRepresentativeId').invalid"
            (onChange)="supervisorDependency($event)"
            placeHolder="Elige un supervisor"
          ></el-dropdown>
        </div>
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">Grupo </label>
          <el-dropdown
            [options]="Groups"
            formControlName="groupId"
            customClass="form-control form-control-lg"
            placeHolder="Elige un grupo"
          ></el-dropdown>
        </div>
      </div>
    </form>
  </div>
</ng-template>
