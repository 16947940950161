import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { user } from 'src/app/modules/auth/_models/User';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { MapComponent } from 'src/app/modules/shared/_elements/element-ui/map/Map.component';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ServiceOrderAssignment } from '../../../serviceOrderForm/_models/ServiceOrderAssignment';
import { visitResultModel } from './models/visitResultModel';
import { visitRouteModel } from './models/visitRouteModel';
import * as _ from 'lodash';
import { routeDataService } from './routeData.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { ServiceOrderComponent } from '../../service-order/service-order.component';
import { companyData } from '../../../serviceOrderForm/_models/companyData';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-visit-route',
  templateUrl: './visit-route.component.html',
  styleUrls: ['./visit-route.component.css'],
  animations: [
    trigger('rncPopOver', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ]),
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [

    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class VisitRouteComponent implements OnInit {
  @Input() route: visitRouteModel
  @Input() canEdit: boolean = false
  @Input() inspectionProgramId: number;
  @Input() inspectionProgramNumber: string;
  canEditRoute: boolean;
  canEditResult: boolean;
  title: string;
  options = [{ text: '2B4Y6F', value: '2B4Y6F' }, { text: '2B4Y6F', value: '2B4Y6F' }]
  currentUser = this.userService.getUserData() as user;
  routeForm: FormGroup;
  routeEdit: visitRouteModel;
  resultsForm = new FormArray([]);
  // results: any[]
  results: visitResultModel[]
  show: boolean = false
  dropdownSettings: IDropdownSettings = {};
  @Output() createdvisitRoute = new EventEmitter<visitRouteModel>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() itChange = new EventEmitter<boolean>();
  @Output() hasValue = new EventEmitter<boolean>();
  inspectors: DropDownOptionModel[] = [];
  provinces: any[] = [];
  companies: any[] = [];
  municipalities: any[] = []
  municipalDistricts: any[] = [];
  geoInfo: any;
  resultDropdown: DropDownOptionModel[];

  constructor(private FB: FormBuilder,
    private userService: UserService,
    public tool: ToolsService, private elem: ElementRef,
    private calendarS: NgbCalendar,
    private ngbActiveModal: NgbActiveModal,
    private http: HttpClientService,
    private rawHttp: HttpClient,
    private sweet: SweetAlertService,
    private ngBModal: NgbModal,
    private routeData: routeDataService,
    private toast: ToastService,) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar Todos',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true,
      searchPlaceholderText: 'Buscar'

    };
    this.getDropdowns()

  }
  minDate = this.calendarS.getToday()
  async ngOnInit() {
    if (this.currentUser.roleCode == 'REPLO') {
      if (!this.route) {

        this.canEditRoute = true;
      }
      this.canEditResult = true;
    }
    else if (this.currentUser.roleCode == 'INSP') {
      this.canEditResult = true;
    }
    this.title = this.route ? 'Editando Ruta' : 'Creando Ruta'

    this.createRouteForm()

    this.routeForm.valueChanges.subscribe(
      changes => {
        this.valid.emit(this.routeForm.invalid)
        this.routeEdit = this.routeForm.value;
        if (this.routeForm.dirty) {
          this.itChange.emit(true)
        }

        if (this.routeForm.valid) {
          //this.mapDates()
          this.createdvisitRoute.emit(this.routeForm.value)
        }
      }
    )
    this.createdvisitRoute.emit(this.routeForm.value)
    if (this.route) {
      this.getResults();
    }
  }


  createRouteForm() {
    if (!this.route) {
      this.routeForm = this.FB.group({
        visitRouteId: new FormControl(0),
        provinceCode: new FormControl(null, Validators.required),
        municipalityCode: new FormControl(null, Validators.required),
        municipalDistrictId: new FormControl(null, Validators.required),
        section: new FormControl(null),
        place: new FormControl(null),
        address: new FormControl(null),
        location: new FormControl(null),
        companyToVisitListed: new FormControl([], Validators.required),
        route: new FormControl(null, Validators.required),
        startDate: new FormControl(null, Validators.required),
        assignedInspectorCode: new FormControl(null, Validators.required),
        InspectionProgramNumber: this.inspectionProgramNumber,
        modifiedBy: this.currentUser.userCode,
        registeredBy: this.currentUser.userCode,
        registrationDate: null,
        recordModificationDate: null,
        recordStatus: true,
      })
    } else {
      this.routeForm = this.FB.group({
        visitRouteId: new FormControl(this.route.visitRouteId),
        provinceCode: new FormControl(this.route.provinceCode),
        municipalityCode: new FormControl(this.route.municipalityCode),
        municipalDistrictId: new FormControl(this.route.municipalDistrictId),
        section: new FormControl(this.route.section),
        place: new FormControl(this.route.place),
        address: new FormControl(this.route.address),
        location: new FormControl(this.route.location),
        companyToVisitListed: new FormControl(this.tool.JsonToObject(this.route.companyToVisitListedJson)),
        route: new FormControl(this.route.route),
        startDate: new FormControl(this.tool.dateToObject(this.route.startDate)),
        assignedInspectorCode: new FormControl(this.route.assignedInspectorCode),
        InspectionProgramNumber: this.route.InspectionProgramNumber,
        modifiedBy: this.currentUser.userCode,
        registeredBy: this.route.registradoPor,
        registrationDate: this.route.fechaRegistro,
        recordModificationDate: null,
        recordStatus: true,
      })
    }
  }

  initResult(visitResult?: visitResultModel) {
    if (!visitResult?.visitResultId) {
      return this.FB.group({
        visitResultId: [0],
        visitRouteId: [this.route?.visitRouteId],
        companyTradeName: [visitResult?.companyTradeName, Validators.required],
        specificCompanyRnl: [''],
        managementResult: [null],
        closeDate: [null, Validators.required],
        resultId: [null, Validators.required],
        comment: [null],
        modifiedBy: [this.currentUser.userCode],
        registeredBy: [this.currentUser.userCode],
        registrationDate: [null],
        recordModificationDate: [null],
        recordStatus: [true],
        isManuallyCreated: true,
        hasOrder: false
      });
    } else {
      return this.FB.group({
        visitResultId: [visitResult.visitResultId],
        visitRouteId: [visitResult.visitRouteId],
        companyTradeName: [visitResult.companyTradeName, Validators.required],
        specificCompanyRnl: [visitResult.specificCompanyRnl],
        managementResult: [visitResult.managementResult],
        closeDate: [this.tool.dateToObject(visitResult.closeDate), Validators.required],
        resultId: [visitResult.resultId, Validators.required],
        comment: [visitResult.comment],
        modifiedBy: visitResult.modificadoPor,
        registeredBy: visitResult.registradoPor,
        registrationDate: visitResult.fechaRegistro,
        recordModificationDate: visitResult.fechaModificacion,
        recordStatus: visitResult.estatusRegistro,
        isManuallyCreated: visitResult.isManuallyCreated,
        hasOrder: [visitResult.hasOrder]
      });
    }
  }

  addResult(result?: any) {
    this.resultsForm.push(this.initResult(result));
  }

  createResultsFromRoute(results: Array<any>) {
    if (results) {
      results.forEach((company) => {
        this.addResult(company);
      });
    }
    else {
      return null;
    }
  }
  cleanResultsForm() {
    while (this.resultsForm.length !== 0) {
      this.resultsForm.removeAt(0)
    }
  }

  saveResult(result: FormGroup, direct?: boolean) {
    if (result.invalid) {
      this.tool.validateAllFormFields(result);
    }
    else {
      let { companyTradeName } = result.value

      if (result.get('registrationDate').value) {
        this.http.update(this.mapResult(result.value, direct), 'visitResult').subscribe(
          {
            next: response => {
              if (!direct) {
                this.sweet.record('success', `Resultado de la visita a la empresa ${companyTradeName} se ha guardado correctamente`, '', '')

              } this.getResults()
            },
            error: error => {
              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
            }
          }
        )
      }
      else {
        this.http.post(this.mapResult(result.value), 'visitResult').subscribe(
          {
            next: response => {
              this.sweet.record('success', `Resultado de la visita a la empresa ${companyTradeName} se ha guardado correctamente`, '', '')
              this.getResults();
            },
            error: error => {
              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
            }
          }
        )
      }
    }
  }

  close(direct?: boolean) {
    if (direct) {
      this.ngbActiveModal.close();
    } else {
      this.tool.modalWarning().then((result) => {
        if (result.isConfirmed) {
          this.ngbActiveModal.close();
        }
      })

    }
  }

  saveRoute() {
    if (this.routeForm.valid) {
      this.http.post<visitRouteModel>(this.mapRoute(this.routeForm.value), 'VisitRoute').subscribe(
        {
          next: response => {
            this.close(true);

            this.sweet.record('success', `Registro No.${response.visitRouteId}`, 'Su ruta se ha registrado correctamente', `Esta ruta  se ha añadido a su historial de rutas`)

            this.routeData.subject.next(true)

          },
          error: error => {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
          }
        }
      )
    }
    else {
      this.tool.createWarning(this.routeForm, this.elem).then(_ => this.notFound());
    }
  }
  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }

  async getResults() {
    this.results = []
    this.cleanResultsForm()
    let results = await this.http.get<visitResultModel[]>(`VisitResult/VisitResultOfRoute/${this.route.visitRouteId}`).toPromise()
    this.results = results
    this.createResultsFromRoute(this.results)
  }
  async getDropdowns() {
    let provinceP = this.http.get<any[]>('Location/Province?status=true').toPromise();
    let companyP = this.http.get<any[]>('VMCompaniesEstablishment/CompaniesRnlAndName').toPromise();
    let inspectorsP = this.http.get<DropDownOptionModel[]>(`User/UserResumeByRolAndRepresentant?rolId=2&repLocalProvId=${this.currentUser.repLocalProvId}`).toPromise();
    let resultDropdownP = this.http.get<DropDownOptionModel[]>(`VisitResultList?status=true`).toPromise();
    let [provinces, companies, inspectors, resultDropdown] = await Promise.all([provinceP, companyP, inspectorsP, resultDropdownP]);
    this.provinces = provinces;
    this.companies = companies;
    this.inspectors = inspectors;
    this.resultDropdown = resultDropdown;
    if (this.route) {
      let municipalityP = this.http.get<any[]>(`Location/Municipality?status=true&provinceBelongsCode=${this.route.provinceCode}`).toPromise()
      let municipalDistrictP = this.http.get<any[]>(`Location/MunicipalDistrict?status=true&municipalityBelongsCode=${this.route.municipalityCode}`).toPromise()
      let [municipality, municipalDistrict] = await Promise.all([municipalityP, municipalDistrictP])
      this.municipalities = municipality;
      this.municipalDistricts = municipalDistrict;
    }
    this.getLocation();
  }
  createOrder(result: visitResultModel) {
    const ngmodal: NgbModalRef = this.ngBModal.open(
      ServiceOrderComponent, { size: 'xl', centered: true });

    ngmodal.componentInstance.inspectionProgramId = this.inspectionProgramId;
    ngmodal.componentInstance.visitResultId = result.visitResultId;
    ngmodal.closed.subscribe(el => {
      if (el) {
        this.getResults()

      }
    })
  }
  getMunicipality(code: string) {

    this.routeForm.patchValue({
      municipalityCode: null, municipalDistrictId: null
    })
    this.http.get<any[]>(`Location/Municipality?status=true&provinceBelongsCode=${code}`).subscribe(el => this.municipalities = el)

  }

  getmunicipalDistrict(code: string) {
    this.routeForm.patchValue({
      municipalDistrictId: null
    });

    this.http.get<any[]>(`Location/MunicipalDistrict?status=true&municipalityBelongsCode=${code}`).subscribe(el => this.municipalDistricts = el)

  }

  getLocation(short?: boolean) {


    // let location = `${province}, ${municipality} ${street}`
    this.rawHttp.get<any>(`https://nominatim.openstreetmap.org/search.php?q=${this.getLocationAddress(short)}&polygon_geojson=1&format=jsonv2`).subscribe(loc => {
      if (loc.length > 0) {
        this.geoInfo = loc
      }
      else if (loc.length == 0 && short) {
        this.geoInfo = null
      } else {
        this.getLocation(true)
      }
    })

  }

  getLocationAddress(short?: boolean): string {
    let { provinceCode, municipalityCode, municipalDistrictId, address } = this.route
    let province = this.provinces.find(el => el.value == provinceCode)?.text;
    let municipality = this.municipalities.find(el => el.value == municipalityCode)?.text;
    let municipalDistrict = this.municipalDistricts.find(el => el.value == municipalDistrictId)?.text;

    return short ? `${province}, ${municipality}` : `${province}, ${municipality} ${municipalDistrict}`
  }
  async openLocation() {
    const ngmodal: NgbModalRef = this.ngBModal.open(
      MapComponent, {
      size: 'xl', backdrop: 'static',
      keyboard: false
    });

    ngmodal.componentInstance.location = _.cloneDeep(location);
    ngmodal.componentInstance.geoInfo = this.geoInfo
    ngmodal.componentInstance.title = 'Locación de empresa'
  }

  mapRoute(route: visitRouteModel) {
    let companies = route.companyToVisitListed.map((motivo) => {
      return motivo['value'];
    });

    let mapRoute = {
      startDate: new Date(`${route.startDate['year']}-${route.startDate['month']}-${route.startDate['day']}`),
      companyToVisitListed: companies.join(','),

    }
    return { ...route, ...mapRoute }

  }


  mapResult(result: visitResultModel, direct?: boolean) {
    let mapResult = {
      closeDate: new Date(`${result.closeDate['year']}-${result.closeDate['month']}-${result.closeDate['day']}`),
      hasOrder: direct ? true : false
    }
    return { ...result, ...mapResult }
  }
  assignSpecificName(formGroup: FormGroup, name) {
    formGroup.patchValue({
      companyTradeName: name
    })
  }




  async getCompany(result: FormGroup, value, type) {
    try {

      let response = await this.http.get<companyData>(`VMCompaniesEstablishment?${type}=${value}`).toPromise()

      result.patchValue({
        companyTradeName: response[0].companyTradeName,
        specificCompanyRnl: response[0].rnl
      })
    }
    catch (error) {
      if (error.status == 404) {

        result.patchValue({
          companyTradeName: null,
          specificCompanyRnl: null
        })
        return this.notFound()
      }
      return this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
    }


  }

  get stateName() {
    return this.show ? "show" : "hide";
  }
}
