<form class="form" [formGroup]="expedientEstadoForm" (ngSubmit)="validateEstadoExpedient()">
  <div class="row mb-3">
    <div class="col-12 col-md-4">
      <div class="form-group">
        <label for="">Estado Actual</label>
        <input type="text" class="form-control" value="{{EstadoNombre}}" disabled>
      </div>
    </div>
    <!-- <div class="col-12 col-md-4">
      <div class="form-group">
        <label for="">Estados</label>
        <select formControlName="idEstado" class="form-select">
          <option value="" selected>Seleccione</option>
          <option *ngFor="let estadoC of getAllExpedientNoCerrado" value="{{ estadoC.value }}">{{
            estadoC.text }}</option>
        </select>
      </div>
    </div> -->
  </div>

  <div class="row">
    <div class="col-12 col-md-12">
      <button type="submit" [disabled]="expedientCerrado"
        class="btn btnSubmit float-end btn-guardar btnListo">Listo</button>
    </div>
  </div>
</form>
