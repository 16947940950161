<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ title }} - {{ this.currentCopy.roleInfo }}
    </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="this.ngbActiveModal.close()"></button>
  </div>
  <div class="modal-body">
      <div class="form-row">
        <div class="row">
          <div class="col-md-6 col-6 mb-1">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Nombre</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" [(ngModel)]="editUserFormData.firstName">
            </div>
          </div>
          <div class="col-md-6 col-6 mb-1">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Segundo Nombre</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" [(ngModel)]="editUserFormData.secondName">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6 mb-1">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Apellido</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" [(ngModel)]="editUserFormData.firstLastName">
            </div>
          </div>
          <div class="col-md-6 col-6 mb-1">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Segundo Apellido</label>
              <input type="text" class="form-control" id="exampleFormControlInput1" [(ngModel)]="editUserFormData.secondLastName">
            </div>
          </div>
        </div>
  
        <div class="card mb-4">
          <div class="card-body">
            <div class="col-md-12 col-12 mb-1">
              <label class="form-label form-control-lg">Representación&nbsp;Local</label>
              <div class="container">
                  <div class="row">
                    <div class="col">
                <ng-multiselect-dropdown
                  name="editUserFormData.repLocalProvCode"
                  [placeholder]="'Selecciona RLT'"
                  [data]="localRep"
                  [(ngModel)]="selectedItems"
                  [settings]="dropdownSettings"
                  (onSelect)="onItemSelect($event)"
                  (onDeSelect)="onItemDeSelect($event)">
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
            </div>
            <div class="col-md-12 col-12 mb-3">
              <label class="form-label form-control-lg">Rol </label>
              <select [(ngModel)]="editUserFormData.roleId" class="form-select" id="">
                <option *ngFor="let r of roles" value="{{ r.roleId }}">{{ r.roleInfo }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-12 mb-1">
          <label class="form-label form-control-lg">Activar/Desactivar Usuario:</label>&nbsp;
          <button type="button" [ngClass]="{ 'btnActive': !this.editUserFormData.recordStatus, 'btnInactive': this.editUserFormData.recordStatus }" id="btnEnableUser"
            (click)="toggleActive()">{{ !this.editUserFormData.recordStatus ? 'Activar Usuario' : 'Inactivar Usuario' }}</button>
        </div>
  
      </div>
      <div class="text-center  float-right mt-5 mb-3">
        <button type="button" (click)="editUser()" class="rounded-pill btn btn-save px-5">
          Guardar
        </button>
      </div>
  </div>
</div>
