<form [formGroup]="asignarAbogadoForm" (ngSubmit)="validarAsignarAbogado()" id="asignarAbogadoForm">
    <div class="form">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Abogado (Principal)</label>
              <select class="form-select" formControlName="idAbogado">
                <option value="">Selecione</option>
                <option *ngFor="let a of abogadosDrop" value="{{ a.userId }}">
                  {{ a.firstName }} {{ a.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Abogado (Alterno 1)</label>
              <select class="form-select" formControlName="idAbogadoAlterno">
                <option value="">Selecione</option>
                <option *ngFor="let a of abogadosDrop" value="{{ a.userId }}">
                  {{ a.firstName }} {{ a.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Abogado (Alterno 2)</label>
              <select class="form-select" formControlName="idAbogadoAlterno1">
                <option value="">Selecione</option>
                <option *ngFor="let a of abogadosDrop" value="{{ a.userId }}">
                  {{ a.firstName }} {{ a.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="idMotivoAsignacionUsuario" class="pl-0">Motivo<span
                  class="asterisco">*</span></label>
              <select class="form-select" id="idMotivoAsignacionUsuario"
                formControlName="idMotivoAsignacionUsuario">
                <option value="">Selecione</option>
                <option *ngFor="let rlt of motivoAsignacionAbogadoDrop" value="{{ rlt.value }}">{{
                  rlt.text
                  }}
                </option>
              </select>
              <div *ngIf="asignarAbogadoForm.get('idMotivoAsignacionUsuario').invalid &&
              asignarAbogadoForm.get('idMotivoAsignacionUsuario').touched">
                <span class="text-danger">Elija una opcion</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="boton-guardar float-end">
      <button type="submit" class="btn btn-guardar float-end" id="btn-asignacioAbogado">
        Listo
      </button>
    </div>
  </form>