import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { InspectionReportComponent } from '../../../serviceOrderForm/inspection-report/inspection-report.component';
import { inspectionReport } from '../../../serviceOrderForm/inspection-report/_models/inspectionReport';
import { InspectionFilter } from './inspectionFilter';

@Component({
  selector: 'app-advanced-inspection-filter',
  templateUrl: './advanced-inspection-filter.component.html',
  styleUrls: ['./advanced-inspection-filter.component.css']
})
export class AdvancedInspectionFilterComponent implements OnInit {

  constructor(public ngbActiveModal: NgbActiveModal, private tool: ToolsService) { }
  @Input() isActive: boolean;
  ngOnInit(): void {

  }
  buildSearch(forms: inspectionReport) {
    const inspection: InspectionFilter = {
      printedReport: forms.printedReport,
      directedTo: forms.directedTo
    }

    const filter = {
      form: inspection,
      active: this.isActive
    }
    this.tool.setFilterLS('inspectionFilter', JSON.stringify(filter))
    this.tool.buildSearch(inspection, this.ngbActiveModal)
  }

  clean(inspection: InspectionReportComponent) {
    this.tool.cleanFilterLS('inspectionFilter')
    inspection.clean();
  }
}
