<div *ngIf="!isFilter; else isTheFilter">
  <p-tabView styleClass="tabview-custom">
    <p-tabPanel
      header="Datos"
      rightIcon="pi pi-info-circle"
      tooltip="Datos de empresa"
    >
      <div class="centered-content" [ngClass]="{ cantEditCols: !canEdit }">
          <div *ngIf="isRnCSearch" >
            <div class="form-group">
              <div class="col-md col-6 mb-1"> 
                <div>
                  <button type="button" class="btn btn-outline-primary" (click)="onClickBack()"><i class="fa fa-arrow-circle-o-left" aria-hidden="true"> Regresar</i></button>                 
                </div>
                  <label class="form-label form-control-lg">Listado RNL </label> 
                  <span [@rnlPopOver]="stateName" class="badge badge-danger"
                  >No se encontraron datos con este RNC</span
                >                                
              </div>
              <div>
              <div class="col-md col-12 mb-1 height:100px; overflow-y:scroll;"  >
                  <ul class="css-companyRnl-list">
                      <li *ngFor="let items of records">
                        <button class="btn btn-light form-control form-control-lg cssbtnRnc" type="button" (click)="onSelect(items.rnl)">
                          <span class="name">RNL:{{items.rnl}} | </span>
                          <span class="name">TIPO:{{items.type}} | </span>
                          <span class="name"> {{items.socialCompanyName}} | </span> 
                          <span *ngIf="items.province != null" class="name">{{items.province}}</span>
                          <span *ngIf="items.municipality != null"class="name">, {{items.municipality}}</span>
                          <span *ngIf="items.municipalDistrict != null" class="name">, {{items.municipalDistrict}}</span>
                          <span *ngIf="items.street != null" class="name">,  {{items.street}}</span>
                          <span *ngIf="items.streetNumber != null" class="name">,  {{items.streetNumber}}</span>
                        </button>
                      </li>
                    </ul>
              </div>           
              </div>        
          <br />
          </div>   
        </div>         
        <form [formGroup]="companyDataForm" [ngClass]="{ cantEdit: !canEdit }" *ngIf="!isRnCSearch">
          <div class="form-row" >
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"
                >RNL

                <span [@rncPopOver]="stateName" class="badge badge-danger"
                  >No se encontraron datos</span
                >
              </label>
              <div class="input-group mb-3">
                <input
                  type="number"
                  min="0"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                  class="form-control form-control-lg rnl"
                  formControlName="rnl"
                  [attr.disabled]="this.companyInfo"
                />
                <div class="input-group-append">
                  <button
                    [disabled]="!companyDataForm.get('rnl').value"
                    class="btn btn-outline-secondary"
                    type="button"
                    (click)="
                      getCompany(companyDataForm.get('rnl').value, 'rnl')
                    "
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">RNC </label>
              <div class="input-group mb-3">
                <input
                  specialIsAlphaNumeric
                  [fControl]="companyDataForm.get('extensionJustification')"
                  type="text"
                  placeholder="RNC"
                  class="form-control form-control-lg"
                  formControlName="rnc"
                 
                />
                <div class="input-group-append">
                  <button
                    [disabled]="!companyDataForm.get('rnc').value"
                    class="btn btn-outline-secondary"
                    type="button"
                    (click)="
                    showRnc()
                    "
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>              
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12 mb-1">
              <label class="form-label form-control-lg"
                >Razón Social
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('socialCompanyName').invalid"
                  >*</small
                >
              </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('socialCompanyName')"
                type="text"
                placeholder="Razón social"
                class="form-control form-control-lg"
                formControlName="socialCompanyName"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Nombre Comercial
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('companyTradeName').invalid"
                  >*</small
                >
              </label>
              <div class="input-group mb-3">
                <input
                  specialIsAlphaNumeric
                  [fControl]="companyDataForm.get('companyTradeName')"
                  type="text"
                  placeholder="Nombre comercial"
                  class="form-control form-control-lg"
                  formControlName="companyTradeName"
                  [attr.disabled]="this.companyInfo"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    (click)="
                      getCompany(
                        companyDataForm.get('companyTradeName').value,
                        'companyTradeName'
                      )
                    "
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Nombre Representante
              </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('representativeCompanyName')"
                type="text"
                placeholder="Nombre representante"
                class="form-control form-control-lg"
                formControlName="representativeCompanyName"
              />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-1">
              <label class="form-label form-control-lg">Sucursal </label>

              <el-dropdown
                [options]="options"
                formControlName="branchOffice"
                customClass="form-control form-control-lg"
                (onChange)="branchOfficeValidators($event)"
                placeHolder="Elige una sucursal"
              ></el-dropdown>
            </div>
            <div
              class="col-lg-12 col-md-12 col-sm-12 mb-1"
              *ngIf="companyDataForm.get('branchOffice').value"
            >
              <label class="form-label form-control-lg"
                >Nombre Sucursal
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('companyBranchOffice').invalid"
                  >*</small
                ></label
              >
              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('companyBranchOffice')"
                type="text"
                placeholder="Nombre sucursal"
                class="form-control form-control-lg"
                formControlName="companyBranchOffice"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Provincia </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('province')"
                type="text"
                placeholder="provincia"
                class="form-control form-control-lg"
                formControlName="province"
              />
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Municipio </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('municipality')"
                type="text"
                placeholder="Municipio"
                class="form-control form-control-lg"
                formControlName="municipality"
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Distrito Municipal
              </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('municipalDistrict')"
                type="text"
                placeholder="Distrito municipal"
                class="form-control form-control-lg"
                formControlName="municipalDistrict"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"> Sección </label>
              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('companySection')"
                type="text"
                placeholder="Sección"
                class="form-control form-control-lg"
                formControlName="companySection"
              />
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Barrio </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('neighborhood')"
                type="text"
                placeholder="Barrio"
                class="form-control form-control-lg"
                formControlName="neighborhood"
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Calle </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('street')"
                type="text"
                placeholder="Calle"
                class="form-control form-control-lg"
                formControlName="street"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">No. Calle </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('streetNumber')"
                type="text"
                placeholder="Numero de calle"
                class="form-control form-control-lg"
                formControlName="streetNumber"
              />
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Referencia </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('reference')"
                type="text"
                placeholder="Referencia"
                class="form-control form-control-lg"
                formControlName="reference"
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Teléfono </label>

              <input
                type="text"
                placeholder="Teléfono"
                class="form-control form-control-lg"
                formControlName="telephone"
                mask="(000)-000-0000"
                placeholder="(000)-000-000"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Extensión </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('companyExtension')"
                type="text"
                placeholder="Extensión"
                class="form-control form-control-lg"
                formControlName="companyExtension"
              />
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Fax </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('fax')"
                type="text"
                placeholder="Fax"
                class="form-control form-control-lg"
                formControlName="fax"
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Email </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('fax')"
                [allowSpecial]="true"
                type="email"
                placeholder="Email"
                class="form-control form-control-lg"
                formControlName="email"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-1">
              <label class="form-label form-control-lg">A que se Dedica </label>

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('companyArea')"
                type="text"
                placeholder="A que se dedica"
                class="form-control form-control-lg"
                formControlName="companyArea"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Rama Actividad RLT – 02
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('activityBranchRLTId').invalid"
                  >*</small
                >
              </label>

              <el-dropdown
                [options]="activitieBranchs"
                formControlName="activityBranchRLTId"
                customClass="form-control form-control-lg"
                placeHolder="Elige una Rama de Actividad"
                (change)="setActivityRLT(companyDataForm.get('activityBranchRLTId').value)"
              ></el-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">
                Valor de las Instalaciones
              </label>
              <div class="input-group">
                <input
                  currencyMask
                  [options]="{ prefix: 'RD$ ', thousands: ',', decimal: '.' }"
                  class="form-control form-control-lg"
                  formControlName="stocksValue"
                />
              </div>
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">
                Fecha Inicio Operaciones
              </label>
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="DD/MM/YYYY"
                  name="dp"
                  formControlName="operationsStartDate"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  readonly
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d.toggle()"
                    type="button"
                  >
                    <ion-icon name="calendar-outline"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-1">
              <label class="form-label form-control-lg">
                Trabajadoras Afectadas
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('womenWorkersAffected').invalid"
                  >*</small
                >
              </label>

              <input
                type="number"
                min="0"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                class="form-control form-control-lg"
                formControlName="womenWorkersAffected"
              />
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-1">
              <label class="form-label form-control-lg">
                Trabajadores Afectados
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('maleAffectedWorkers').invalid"
                  >*</small
                >
              </label>

              <input
                type="number"
                min="0"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                class="form-control form-control-lg"
                formControlName="maleAffectedWorkers"
              />
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-1">
              <label class="form-label form-control-lg">
                Trabajadores Menores Afectados
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('minorsBoys').invalid"
                  >*</small
                >
              </label>

              <input
                type="number"
                min="0"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                class="form-control form-control-lg"
                formControlName="minorsBoys"
              />
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-1">
              <label class="form-label form-control-lg">
                Trabajadoras Menores Afectadas
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('minorsGirls').invalid"
                  >*</small
                >
              </label>

              <input
                type="number"
                min="0"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                class="form-control form-control-lg"
                formControlName="minorsGirls"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-1">
              <label class="form-label form-control-lg">Zona Franca </label>

              <el-dropdown
                [options]="options"
                formControlName="freeZone"
                customClass="form-control form-control-lg"
                (onChange)="freeZoneValidators($event)"
                placeHolder="Elige una zona franca"
              ></el-dropdown>
            </div>
            <div
              class="col-lg-12 col-md-12 col-sm-12 mb-1"
              *ngIf="companyDataForm.get('freeZone').value"
            >
              <label class="form-label form-control-lg"
                >Nombre Zona Franca
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('freeZoneName').invalid"
                  >*</small
                >
              </label>
              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('freeZoneName')"
                type="text"
                placeholder="Nombre de zona franca"
                class="form-control form-control-lg"
                formControlName="freeZoneName"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-1">
              <label class="form-label form-control-lg">Sindicato </label>

              <el-dropdown
                [options]="options"
                formControlName="laborUnion"
                customClass="form-control form-control-lg"
                (onChange)="laborUnionValidators($event)"
                placeHolder="Elige un sindicato"
              ></el-dropdown>
            </div>
            <div
              class="col-lg-12 col-md-12 col-sm-12 mb-1"
              *ngIf="companyDataForm.get('laborUnion').value"
            >
              <label class="form-label form-control-lg"
                >Nombre Sindicato
                <small
                  class="text-danger"
                  *ngIf="companyDataForm.get('companyLaborUnion').invalid"
                  >*</small
                ></label
              >

              <input
                specialIsAlphaNumeric
                [fControl]="companyDataForm.get('companyLaborUnion')"
                type="text"
                placeholder="Nombre de sindicato"
                class="form-control form-control-lg"
                formControlName="companyLaborUnion"
              />
            </div>
          </div>
          <div class="form-row" *ngIf="geoInfo">
            <div class="col-md col-6 mb-1">
              <button (click)="openLocation()" class="btn btn-primary">
                ver locación
              </button>
            </div>
          </div>
        </form>
      </div>
    </p-tabPanel>
    <p-tabPanel
      [disabled]="!hasFiles"
      header="Formularios"
      rightIcon="pi pi-file"
      tooltip="Formularios de la empresa"
    >
      <app-tree-files
        *ngIf="hasFiles && companyFiles"
        [files]="companyFiles"
        (getformInfo)="getSirlaForms($event)"
      ></app-tree-files>
    </p-tabPanel>
    <!-- <p-tabPanel
      header="Direcciones"
      rightIcon="pi pi-map-marker"
      tooltip="Dirección de la empresa"
    >
      <app-company-location></app-company-location>
    </p-tabPanel> -->
  </p-tabView>
</div>
<ng-template #isTheFilter>
  <div class="centered-content" [ngClass]="{ cantEditCols: !canEdit }">
    <form [formGroup]="companyDataForm" [ngClass]="{ cantEdit: !canEdit }">
      <div class="form-row">
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg"
            >RNL

            <span [@rncPopOver]="stateName" class="badge badge-danger"
              >no se encontraron</span
            >
          </label>
          <input
            type="number"
            min="0"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            class="form-control form-control-lg rnl"
            formControlName="rnl"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Nombre Comercial
            <small
              class="text-danger"
              *ngIf="companyDataForm.get('companyTradeName').invalid"
              >*</small
            >
          </label>

          <input
            specialIsAlphaNumeric
            [fControl]="companyDataForm.get('companyTradeName')"
            type="text"
            placeholder="Nombre comercial"
            class="form-control form-control-lg"
            formControlName="companyTradeName"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="col-lg-12 col-md-12 col-sm-12 mb-1">
          <label class="form-label form-control-lg">A que se Dedica </label>

          <input
            specialIsAlphaNumeric
            [fControl]="companyDataForm.get('companyArea')"
            type="text"
            placeholder="A que se dedica"
            class="form-control form-control-lg"
            formControlName="companyArea"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Rama Actividad RLT – 02
            <small
              class="text-danger"
              *ngIf="companyDataForm.get('activityBranchRLTId').invalid"
              >*</small
            >
          </label>

          <input
            specialIsAlphaNumeric
            [fControl]="companyDataForm.get('activityBranchRLTId')"
            type="text"
            placeholder="Rama actividad RLT"
            class="form-control form-control-lg"
            formControlName="activityBranchRLTId"
          />
        </div>
      </div>      
    </form>
  </div>
</ng-template>
