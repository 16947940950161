<form [formGroup]="serviceOrderForm">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg"
          >Representación Local
          <small
            [@warning]="tool.stateName(show)"
            class="text-danger"
            *ngIf="!serviceOrderForm.get('repLocalProvCode').value"
            >*</small
          >
        </label>
        <el-dropdown
          [options]="localReps"
          customClass="form-control form-control-lg colection-type"
          formControlName="repLocalProvCode"
          valueName="code"
          [stringOut]="true"
          placeHolder="Elige una representación"
          (onChange)="getRepresentative($event)"
        ></el-dropdown>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg"
          >Representante Local
          <small
            class="text-danger"
            *ngIf="serviceOrderForm.get('localRepresentativeCode').invalid"
            >*</small
          ></label
        >
        <el-dropdown
          [options]="localRepresentative"
          formControlName="localRepresentativeCode"
          customClass="form-control form-control-lg"
          [disabled]="serviceOrderForm.get('repLocalProvCode').invalid"
          (onChange)="getSupervisor($event)"
          placeHolder="Elige un representante local"
          [stringOut]="true"
        ></el-dropdown>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg">
          Supervisor
          </label>
        <el-dropdown
          [options]="supervisors"
          formControlName="supervisorCode"
          customClass="form-control form-control-lg"
          (onChange)="getInspector($event)"
          placeHolder="Elige un supervisor"
          [stringOut]="true"
        ></el-dropdown>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg"
          >Inspector Asignado
        </label>
        <el-dropdown
          [options]="inspectors"
          formControlName="userCode"
          customClass="form-control form-control-lg"
          placeHolder="Elige un estatus"
          [stringOut]="true"
        ></el-dropdown>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg"
          >Tipo de Servicio
        </label>
        <el-dropdown
          [options]="applicatTypes"
          formControlName="applicantTypeCode"
          customClass="form-control form-control-lg"
          placeHolder="Elige un tipo de servicio"
          [stringOut]="true"
        ></el-dropdown>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg"
          >Estado de orden de servicio
        </label>
        <el-dropdown
          [options]="statusServicesOrder"
          formControlName="statusServicesOrderCode"
          customClass="form-control form-control-lg"
          placeHolder="Elige unstado de orden de servicio"
          [stringOut]="true"
        ></el-dropdown>
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <label class="form-label form-control-lg d-block"
          >Rango de fecha
          <small
            [@warning]="tool.stateName(show)"
            class="text-danger"
            *ngIf="!serviceOrderForm.get('date').value"
            >*</small
          >
        </label>
        <p-calendar
          formControlName="date"
          [yearNavigator]="true"
          [maxDate]="maxDate"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          [inputStyle]="{ width: '100% !important' }"
          yearRange="2000:2030"
          selectionMode="range"
          [readonlyInput]="true"
          [disabledDays]="[0, 7]"
        ></p-calendar>
      </div>
    </div>
    <div class="btn-group float-right m-3">
      <button
        type="submit"
        class="rounded-pill btn btn-save px-5"
        (click)="generateReport()"
      >
        Generar Reporte
      </button>
    </div>
  </form>
  