<div class="modal-content">
  <div class="modal-header border-0">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="ngbActiveModal.close()"></button>
  </div>
  <div class="modal-body p-4">
    <div class="titulo text-center">
      <h1 class="modal-title fs-3 mb-5" id="staticBackdropLabel">
        Búsqueda Avanzanda
      </h1>
    </div>
    <form [formGroup]="advanceSearchForm" (ngSubmit)="advanceSearch()">
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Entrevistador</label>
              <!-- <input type="text" class="form-control"id="ID_Usuario" formControlName="ID_Usuario"
                    placeholder="Escriba Entrevistador"> -->
              <select formControlName="ID_Usuario" id="" class="form-select">
                <option value="">--Seleccione Entrevistador--</option>
                <option *ngFor="let layer of listUsersParalegal" value="{{ layer.userId }}">{{
                  layer.firstName }} {{ layer.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">No. de Documento</label>
              <input type="text" class="form-control" id="exampleFormControlInput1"
                placeholder="Escriba No. de Documento" formControlName="Identificacion">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Nombre Solicitante</label>
              <input type="text" class="form-control" id="exampleFormControlInput1"
                placeholder="Escriba Nombre Solicitante" formControlName="NombreSolicitante">
            </div>
          </div>
        </div>
        <div class="col" *ngIf="isAsistenciaJudicial === false">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">RNC</label>
              <input type="number" class="form-control" id="exampleFormControlInput1" formControlName="RNC"
                placeholder="Escriba RNC">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">RNL</label>
              <input type="number" class="form-control" id="exampleFormControlInput1" formControlName="RNL"
                placeholder="Escriba RNL">
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Nombre Comercial Empresa</label>
              <input type="text" class="form-control" id="exampleFormControlInput1"
                placeholder="Escriba Nombre Comercial Empresa" formControlName="NombreComercial">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">No. Documento Persona
                Física</label>
              <input type="number" class="form-control" id="exampleFormControlInput1"
                placeholder="Escriba No. Documento Persona Física" formControlName="IdentificacionPersonaFisica">
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Nombre Persona Física</label>
              <input type="text" class="form-control" id="exampleFormControlInput1"
                placeholder="Escriba Nombre Persona Física" formControlName="NombrePersonaFisica">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Representante Legal y/o
              Empleador</label>
            <input class="form-control" id="exampleFormControlInput1"
              placeholder="Escriba Representante Legal y/o Empleador " formControlName="RepresentanteLegalEmpleador">
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Abogado Principal</label>
              <select formControlName="ID_Abogado" class="form-select">
                <option value="">--Seleccione Entrevistador--</option>
                <option *ngFor="let layer of listUsersLayers" value="{{ layer.userId }}">{{
                  layer.firstName }} {{ layer.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Abogado Alterno 1</label>
              <select formControlName="ID_AbogadoAlterno" class="form-select">
                <option value="">--Seleccione Entrevistador--</option>
                <option *ngFor="let layer of listUsersLayers" value="{{ layer.userId }}">{{
                  layer.firstName }} {{ layer.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="exampleFormControlInput1" class="form-label">Abogado Alterno 2</label>
              <select formControlName="ID_AbogadoAlterno1" class="form-select">
                <option value="">--Seleccione Entrevistador--</option>
                <option *ngFor="let layer of listUsersLayers" value="{{ layer.userId }}">{{
                  layer.firstName }} {{ layer.firstLastName }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isAsistenciaJudicial === false">
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="">Instancia Judicial </label>
              <select class="form-select" formControlName="ID_InstanciaJudicial">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let faseDoc of instanciJudicialDrop" value="{{faseDoc.value}}">
                  {{faseDoc.text}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <div class="form-group">
              <label for="">Fase Procesal</label>
              <select formControlName="ID_FaseProceso" class="form-select">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let faseDoc of faseProcesalDrop" value="{{faseDoc.value}}">
                  {{faseDoc.text}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 justify-content-center">
        <button type="submit" class="btn btn-guardar mb-4">Buscar</button>
      </div>
    </form>
  </div>
