<div [ngClass]="{ cantEditCols: !canEdit }">
  <div [ngClass]="{ cantEdit: !canEdit }">
    <div class="table-responsive">
      <table
        class="table"
        id="tb-attached-document"
        *ngIf="uploadedFiles && warningReport; else standar"
        [ngClass]="{ cantEdit: !canEdit }"
      >
        <thead>
          <tr>
            <th scope="col">Formato</th>
            <th scope="col">Número&nbsp;Acta</th>
            <th scope="col">Concepto</th>
            <th scope="col">Fecha&nbsp;Vencimiento</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of uploadedFiles">
            <td>
              <div class="container">
                <span class="me-2" [ngSwitch]="tolowerCase(file.extension)">
                  <div *ngSwitchCase="'png'">
                    <i class="far fa-file-image fa-2x text-info"></i>
                  </div>
                  <div *ngSwitchCase="'jpg'">
                    <i class="far fa-file-image fa-2x text-info"></i>
                  </div>
                  <div *ngSwitchCase="'txt'">
                    <i class="far fa-file-alt fa-2x .text-warning"></i>
                  </div>
                  <div *ngSwitchCase="'csv'">
                    <i class="fas fa-file-csv fa-2x text-success"></i>
                  </div>
                  <div *ngSwitchCase="'pdf'">
                    <i class="far fa-file-pdf fa-2x text-danger"></i>
                  </div>
                  <div *ngSwitchCase="'excel'">
                    <i
                      class="fa fa-file-excel-o text-success"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div *ngSwitchCase="'docx'">
                    <i class="far fa-file-word fa-2x text-info"></i>
                  </div>
                  <div *ngSwitchDefault></div>
                </span>
                <!-- <el-ellipsis
                  [text]="file.fileName + '.' + tolowerCase(file.extension)"
                  [charLength]="15"
                ></el-ellipsis> -->
              </div>
            </td>
            <td style="vertical-align: middle">
              <el-ellipsis
                [text]="file.reportNumber"
                [charLength]="15"
              ></el-ellipsis>
            </td>

            <td style="vertical-align: middle">
              <el-ellipsis
                [text]="file.conceptListedStr"
                [charLength]="15"
              ></el-ellipsis>
            </td>
            <td style="vertical-align: middle">
              <el-ellipsis
                [text]="file.reportDateStr"
                [charLength]="15"
              ></el-ellipsis>
            </td>
            <td style="vertical-align: middle">
              <el-ellipsis
                [text]="file.documentStatusInfo"
                [charLength]="15"
              ></el-ellipsis>
            </td>
            <td>
              <button
                pButton
                type="button"
                class="p-button-outlined p-button-rounded m-2"
                icon="far fa-eye "
                (click)="ViewFile(file)"
                name="Ver archivo"
                iconPos="left"
              ></button>
              <!-- <button pButton type="button" class="p-button-outlined p-button-rounded m-2 p-button-danger" icon="far fa-trash-alt " (click)="preRemove(file)" iconPos="left"></button> -->
              <!-- <button
                pButton
                type="button"
                class="p-button-outlined p-button-rounded m-2 p-button-danger"
                icon="fas fa-pencil-alt "
                (click)="edit(file)"
                name="Editar archivo"
                [disabled]="!this.warningReport.recordStatus ? true : false"
                iconPos="left"
              ></button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #standar [ngClass]="{ cantEdit: !canEdit }">
      <table class="table" id="tb-attached-document" *ngIf="uploadedFiles">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of uploadedFiles">
            <td>
              <div class="container">
                <span class="me-2" [ngSwitch]="tolowerCase(file.extension)">
                  <div *ngSwitchCase="'png'">
                    <i class="far fa-file-image fa-2x text-info"></i>
                  </div>
                  <div *ngSwitchCase="'jpg'">
                    <i class="far fa-file-image fa-2x text-info"></i>
                  </div>
                  <div *ngSwitchCase="'txt'">
                    <i class="far fa-file-alt fa-2x .text-warning"></i>
                  </div>
                  <div *ngSwitchCase="'csv'">
                    <i class="fas fa-file-csv fa-2x text-success"></i>
                  </div>
                  <div *ngSwitchCase="'pdf'">
                    <i class="far fa-file-pdf fa-2x text-danger"></i>
                  </div>
                  <div *ngSwitchCase="'excel'">
                    <i
                      class="fa fa-file-excel-o text-success"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div *ngSwitchCase="'docx'">
                    <i class="far fa-file-word fa-2x text-info"></i>
                  </div>
                  <div *ngSwitchDefault></div>
                </span>
                <el-ellipsis
                  [text]="file.fileName + '.' + tolowerCase(file.extension)"
                  [charLength]="15"
                ></el-ellipsis>
              </div>
            </td>

            <td>
              <button
                pButton
                type="button"
                class="p-button-outlined p-button-rounded m-2"
                icon="far fa-eye "
                (click)="ViewFile(file)"
                name="Ver archivo"
                iconPos="left"
              ></button>
              <!-- <button
                pButton
                type="button"
                [disabled]="!this.infractionReport.recordStatus ? true : false"
                class="p-button-outlined p-button-rounded m-2 p-button-danger"
                icon="far fa-trash-alt "
                (click)="preRemove(file)"
                iconPos="left"
                name="Eliminar archivo"
              ></button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <div *ngIf="this.infractionReport; else noInfraction">
      <app-file-uploader
        *ngIf="uploadedFiles?.length < 1"
        (selectedfiles)="assignFiles($event)"
        [toolbar]="
          this.toolbar
            ? this.toolbar
            : 'Documentos Anexos/Documentos de Inspeccion'
        "
      ></app-file-uploader>
    </div>
    <ng-template #noInfraction>
      <app-file-uploader
        (selectedfiles)="assignFiles($event)"
        [toolbar]="
          this.toolbar
            ? this.toolbar
            : 'Documentos Anexos/Documentos de Inspeccion'
        "
      ></app-file-uploader>
    </ng-template>
  </div>
</div>
