import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthChildGuardService } from '../auth/_services/auth-child-guard.service';
import { AuthGuardService } from '../auth/_services/auth-guard.service';
import { CanExitGuard } from './Guards/can-exit.guard';
import { InspectionProgramGuard } from './Guards/inspection-program.guard';
import { RoleGuard } from './Guards/role.guard';
import { ServiceOrderGuardGuard } from './Guards/service-order-guard.guard';
import { UserServiceResolverGuard } from './Guards/user-service-resolver.guard';
import { FormsCreationComponent } from './service/forms-creation/forms-creation.component';
import { InspectionProgramComponent } from './service/inspection-program/inspection-program.component';
import { RecordHistoryComponent } from './service/record-history/record-history.component';
import { ServiceOrderComponent } from './service/service-order/service-order.component';
import { ServiceComponent } from './service/service.component';
import { UserServiceFormComponent } from './service/user-service-form/user-service-form.component';



const routes: Routes = [

  {

    path: '',
    component: ServiceComponent,
    data: { breadcrumb: `Registros de Servicios` },
    canActivate: [AuthGuardService],
    children: [

      {

        path: '',
        redirectTo: '/Casos/historial',
        pathMatch: 'full'
      },
      {
        path: 'creacionRecord/:idAsistenciaDiaria',
        component: FormsCreationComponent
      },
      {
        path: 'creacionRecord',
        component: FormsCreationComponent,
        // data: { breadcrumb: 'Crear' },
      },
    
      {
        path: 'historial',
        component: RecordHistoryComponent
      },

    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class serviceRoutingModule { }
