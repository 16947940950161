import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { HttpResponse } from 'src/app/modules/shared/_services/http-client/http-Response';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { serviceOrderForm } from '../../serviceOrderForm/General-data/serviceOrderForm.component';
import { serviceOrder } from '../../serviceOrderForm/_models/serviceOrder';
import { ServiceOrderAssignment } from '../../serviceOrderForm/_models/ServiceOrderAssignment';
import { ActivatedRoute, Router } from '@angular/router';
import { workerData } from '../../serviceOrderForm/_models/workerData';
import { Observable, Subject, Subscription } from 'rxjs';

import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { companyData } from '../../serviceOrderForm/_models/companyData';
import { AttachedDocument } from '../../serviceOrderForm/_models/attachedDocuments';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { OrderAssignmentComponent } from '../../serviceOrderForm/order-assignment/order-assignment.component';
import { CompanyDataComponent } from '../../serviceOrderForm/company-data/company-data.component';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { VisitDataComponent } from '../../serviceOrderForm/visit-data/visit-data.component';
import * as _ from 'lodash';
import { visitData } from '../../serviceOrderForm/_models/visitData';
import { PrimeNGConfig } from 'primeng/api';
import { inspectionProgram } from '../inspection-program/_models/inspectionProgram';
import { WarningReportComponent } from '../../serviceOrderForm/warning-report/warning-report.component';
import { warningReport } from '../../serviceOrderForm/warning-report/_models/warningReport';
import { infractionReportComponent } from '../../serviceOrderForm/infraction-report/infraction-report.component';
import { infractionReport } from '../../serviceOrderForm/infraction-report/_models/infractionReport';
import { emailAssignment } from '../../serviceOrderForm/order-assignment/_models/emailAssignment';
import { orderNotes } from '../../serviceOrderForm/order-notes/_models/orderNotes';
import { OrderNotesComponent } from '../../serviceOrderForm/order-notes/order-notes.component';
import { user } from 'src/app/modules/auth/_models/User';
import { InspectionReportComponent } from '../../serviceOrderForm/inspection-report/inspection-report.component';
import { inspectionReport } from '../../serviceOrderForm/inspection-report/_models/inspectionReport';
import { companyEdit } from '../../serviceOrderForm/company-data/companyEdit.service';
import { FormGroup } from '@angular/forms';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { visitDataService } from '../../serviceOrderForm/visit-data/visitData.service';
import { DbPwaService } from '../pwa/db_pwa.service';
import * as uuid from 'uuid';
import { InspectionReportService } from '../../serviceOrderForm/inspection-report/inspection-report.service';
import { ServiceorderService } from '../../serviceOrderForm/General-data/serviceorder.service';
import { InfractionReportService } from '../../serviceOrderForm/infraction-report/infraction-report.service';
import { OrderWorkerNewComponent } from '../../serviceOrderForm/order-worker-new/order-worker-new.component';

@Component({
  selector: 'app-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.css']
})
export class ServiceOrderComponent implements OnInit {
  serviceOrderInvalid: boolean = true
  orderAssignmentInvalid: boolean = true
  serviceOrder: serviceOrder;
  serviceOrderAssignment: ServiceOrderAssignment;
  AssignmentValue: ServiceOrderAssignment;
  companyData: companyData;
  workerData: workerData;
  visitData: visitData[] = [];
  warningReport: warningReport[] = []
  infractionReport: infractionReport[] = []
  orderNotes: orderNotes[] = []
  canEditOrder: boolean = true;
  canEditAssignment: boolean = true;
  canEditworker: boolean = true;
  canEditCompany: boolean = false;
  canEditDocuments: boolean = true
  inspectionReport: inspectionReport;
  visitDataEdit: boolean = false;
  warningReportEdit: boolean = false;
  warningEmpty: boolean = false
  infractionReportEdit = false;
  orderNotesEdit: boolean = false;
  inspectionReportEdit: boolean = false;
  assignmentEdit: boolean = false;
  isEdit: boolean = false;
  Digitador: boolean = false;
  invalid: boolean = true;
  orderValid: boolean;
  hasJustification: boolean = false;
  assignmentValid: boolean;
  workerDataValid: boolean;
  companyDataValid: boolean;
  serviceOrderChanged: boolean = false;
  orderAssignmentChanged: boolean = false;
  workerChanged: boolean = false;
  companyChanged: boolean = false;
  additionalForms: any[] = []
  status: any[] = [];
  inspectionPrograms: any[];
  actualInspectionProgram: number;
  actualState: number;
  isSaving: boolean = false;
  loading: boolean = false;
  additionalForm = null;
  currentUser: user;
  canEdit: boolean;
  canDigitEdit: boolean = true;
  isSupervisorOrHigher: boolean = false;
  userRol: string;
  InternetSubject = new Subject<boolean>();
  subjectSubscription: Subscription;
  companyInfo: any;
  addiontalFormsButton: ButtonModel = { icon: 'fa fa-plus', function: (id) => { this.createNewForm(id) }, class: 'btn btn-outline-secondary' }
  hasNetworkConnection: boolean;
  connectionSubscription: Subscription;
  @Input() inspectionProgramId: number;
  @Input() visitResultId: number;
  @ViewChild('editServiceOrder') editOrderService: serviceOrderForm
  @ViewChild('CreateServiceOrder') orderService: serviceOrderForm
  @ViewChild('workerDataTemplate') dataWorker: OrderWorkerNewComponent
  @ViewChild('OrderAssigment') dataAssignment: OrderAssignmentComponent
  @ViewChild('companyDataTemplate') dataCompany: CompanyDataComponent
  @ViewChild("pdfContainer", { read: ViewContainerRef }) container;
  activityBranchRLTIdChanges: number;

  constructor(private HttpService: HttpClientService, private toast: ToastService, private route: ActivatedRoute,
    private router: Router, private sweet: SweetAlertService, private user: UserService, private ngBModal: NgbModal,
    private primengConfig: PrimeNGConfig, private companyEditS: companyEdit, public tool: ToolsService,
    private visitService: visitDataService, private pwa: DbPwaService,
    private inspectionService: InspectionReportService,
    private orderSService: ServiceorderService, private infractionService: InfractionReportService,
    public ActiveModal: NgbActiveModal) {
    this.getDropdowns()

  }

  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (this.orderService.serviceOrder.dirty && !this.serviceOrder) {
      $event.returnValue = true;
    }
  }

  getActivityBranchRLTId(ActivityBranchRLTId){
    this.activityBranchRLTIdChanges = ActivityBranchRLTId;
  }
  async permitirSalirDelRoute(): Promise<boolean | Observable<boolean>> {

    if (!this.serviceOrder) {
      if (this.orderService?.serviceOrder?.dirty && !this.serviceOrder && this.invalid) {
        let response;
        await this.tool.changesWarning().then((result) => {
          response = result.isConfirmed
        })

        return response
      } else {

        return true
      }
    }
    else {

      if ((this.serviceOrderChanged || this.workerChanged || this.companyChanged || this.orderAssignmentChanged) && !this.isSaving) {
        let response;

        await this.tool.changesWarning().then((result) => {
          response = result.isConfirmed
        })

        return response
      } else {

        return true
      }

    }

  }




  async checkavailableForms(addOne?: boolean) {

    if (this.warningReport.length == 1 || addOne) {
      this.additionalForms = await this.HttpService.get<any[]>('TypeOfRegister?independentRecord=false&registrationStatus=true').toPromise()

      this.additionalForms = this.additionalForms.filter(form => form.alternateField !== 'ACAPE');
      this.additionalForm = null;
    } else {
      this.additionalForms = await this.HttpService.get<any[]>('TypeOfRegister?independentRecord=false&registrationStatus=true').toPromise()

    }

  }

  checkPermission(user: user) {
    let status = this.actualState == this.status.find(status => status.alternateField == 'ARCDO')?.value;
    this.canEdit = ((this.currentUser.checkAuthorization(this.router.url, 'E') || this.currentUser.checkAuthorization(this.router.url, 'H')) && !status);

    if (user.roleCode == 'DIG') {
      this.Digitador = true
    }

  }
  async getDropdowns() {
    this.status = await this.HttpService.get<any[]>('StatusServiceOrder?status=true').toPromise()
    this.HttpService.get<inspectionProgram[]>('InspectionProgram?Status=true').subscribe((response) => {

      this.inspectionPrograms = response.map(program => { return { value: program.inspectionProgramId, text: `(${program.inspectionProgramNumber}) ${program.programName}` } })
    });
  }
  async ngOnInit() {

    this.loading = true;
    if (this.tool.hasInternetConnection) { await this.checkavailableForms() }

    this.companyEditS.subject.subscribe(t => {

      this.canEditCompany = true
    })

    this.currentUser = this.user.getUserData();
    if (this.currentUser.roleCode !== 'INSP' && this.currentUser.roleCode !== 'DIG') {
      this.isSupervisorOrHigher = true;
    }

    this.primengConfig.ripple = true;

    if (this.route.data) {
      this.route.data.subscribe((data: {
        order: [serviceOrder, ServiceOrderAssignment, workerData, companyData, visitData[],
          warningReport[], infractionReport[], orderNotes[], inspectionReport, any]
      }) => {

        if (data.order) {
          this.isEdit = true
          this.loading = false;
          this.serviceOrder = data.order[0]
          this.actualState = data.order[0].statusOrderServiceId;
          this.actualInspectionProgram = data.order[0].inspectionProgramId;
          this.checkPermission(this.currentUser)
          if (data.order[1]) {
            this.serviceOrderAssignment = data.order[1]
            
            this.assignmentEdit = true
            if (this.Digitador) {
              this.canDigitEdit = false;
            }

          }

          if (data.order[2]) {

            this.workerData = data.order[2]
          }
          if (data.order[3]) {

            this.companyData = data.order[3]
          }
          if (data.order[4].length > 0) {
            this.visitData = data.order[4];
          } else {
            this.visitData = []
          }

          if (data.order[5].length > 0) {
            this.warningReport = data.order[5];
          } else {
            this.warningReport = []
          }
          if (data.order[6].length > 0) {
            this.infractionReport = data.order[6];

          } else {
            this.infractionReport = []
          }
          if (data.order[7].length > 0) {
            this.orderNotes = data.order[7];
          } else {
            this.orderNotes = []
          }
          if (data.order[8]) {
            this.inspectionReport = data.order[8];
          } else {
            this.inspectionReport = null
          }
          if (data.order[9]) {
            this.companyInfo = data.order[9];
          } else {
            this.companyInfo = null
          }
        } else {
          this.loading = false;
        }
      })

    }




    if (this.currentUser.roleCode == 'INSP') {
      if (this.assignmentEdit) {
        let status = this.actualState == this.status.find(status => status.alternateField == 'ARCDO')?.value
        if (this.currentUser.userCode == this.serviceOrderAssignment.assignedInspectorInfo && !status) {
          this.canEdit = true;
        } else {
          this.canEdit = false;
        }
      }
      else {
        this.Digitador = true;
      }
    }
    //   this.checkavailableForms()
    if (this.serviceOrder) {
      let status = this.actualState == this.status.find(status => status.alternateField == 'ARCDO')?.value ? true : false
      if (status) {
        this.canEdit = false;
      }
    }
    this.visitService.edition.subscribe(el => {
      let { estados, id } = el

      this.statusToVisitData(estados, id)
    })
    if (this.inspectionReport) {
      this.removeInspectionOption()
    }

    this.inspectionService.subject.subscribe(el => {
      this.statusToInspection()
    })

    this.infractionService.subject.subscribe(_ => {
      (async () => { await this.getInfractions() })()
    })
  }

  async getOrder() {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    if (id) {
      await this.HttpService.get<serviceOrder>(`ServiceOrderFirstStep/${id}`).toPromise().then(
        response => {
          this.serviceOrder = response;
          this.isEdit = true
        }
      ).catch(
        () => {
          this.serviceOrder = this.serviceOrder
        }
      )
      await this.getOrderAssignment(this.serviceOrder.serviceOrderNumber)

    }
    else {
      this.serviceOrder = this.serviceOrder
    }
  }
  async getOrderAssignment(serviceOrderNumber) {

    await this.HttpService.get<ServiceOrderAssignment>(`ServiceOrderSecondStep/ServiceOrderByNumber/${serviceOrderNumber}`).toPromise()
      .then(Response => {
        this.serviceOrderAssignment = Response

      }).catch(() => {
        this.serviceOrderAssignment = this.serviceOrderAssignment;
      })


  }

  async saveAll(serviceOrder: serviceOrder, orderAssignment: ServiceOrderAssignment,  companyData: companyData, doc: File[]) {
    try {
      
      if(companyData.activityBranchRLTId === null) companyData.activityBranchRLTId = this.activityBranchRLTIdChanges;
     
      this.isSaving = true;
      if (companyData.activityBranchRLTId !== null && this.activityBranchRLTIdChanges !== undefined) {
      // if (this.assignmentEdit || this.canEditCompany) { 

        await this.saveWorkerData(null, companyData);
      }
      if (!this.currentUser.checkCode('G') && (this.currentUser.roleCode !== "INSP" && this.currentUser.roleCode !== "DIG")) {
        if(companyData && orderAssignment.assignedInspectorId !== null){
          await this.saveServiceAssignment(orderAssignment)
        }
      }
      
      if (!this.assignmentEdit && orderAssignment.assignedInspectorId !== null) {
        this.actualState = this.status.find(status => status.alternateField == 'ASDIP').value
      }

      this.HttpService.update<serviceOrder>(this.mapOrderData(serviceOrder), `ServiceOrderFirstStep?hasJustification=${this.hasJustification}`).subscribe(order => {


        return this.router.navigate(['./historial'], { relativeTo: this.route.parent });
      })

      await this.saveDocuments(doc)
      this.sweet.record('success', `Registro No.${this.serviceOrder.serviceOrderNumber}`, 'Su caso se ha actualizado correctamente', '');


      //  this.toast.error('No se pudo guardar ', 'error')
    }
    catch {
      this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
    }
  }


  async saveDocuments(doc) {
    if (doc.length > 0) {
      this.HttpService.post<AttachedDocument>(this.MapDocuments(doc), 'ServiceOrderDocuments').toPromise()

      let file: File = doc[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      let Headers: any = new HttpHeaders();
      /** In Angular 5, including the header Content-Type can invalidate your request */
      Headers.append('Content-Type', 'multipart/form-data');
      Headers.append('Accept', 'application/json');
      let options: any = new RequestOptions({ headers: Headers });

    }


  }

  saveRecord(orden, orderForm: FormGroup, elem: ElementRef) {
    this.invalid = false;

    if (this.tool.hasInternetConnection) {
      if (orderForm.valid) {

        this.HttpService.post<serviceOrder>(this.mapOrderData(orden), 'ServiceOrderFirstStep').subscribe(
          {
            next: response => {
              if (this.inspectionProgramId && this.visitResultId) {
                this.HttpService.patch('', `VisitResult/${this.visitResultId}/${true}`).subscribe(
                  el => {
                    this.ActiveModal.close(true)
                  })
              }
              // this.toast.success('guardado correctamente', response.serviceOrderNumber)
              this.sweet.record('success', `Registro No.${response.serviceOrderNumber}`, 'Su caso se ha registrado correctamente', `Este caso será añadido a su historial de registro`)
              localStorage.setItem('typeHistory', 'ORDSE');
              if (!this.inspectionProgramId) {
                // return this.router.navigate(['./historial'], { relativeTo: this.route.parent.parent })
                return this.router.navigate([`Casos/ordenServicio`, response.serviceOrderNumber])
 
              }
            }
            , error: error => {

              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')

            }
          }
        )
      } else {
        if (this.inspectionProgramId) {
          this.ActiveModal.close()
        }
        this.tool.createWarning(orderForm, elem).then(result => { this.orderService.notFound() })
      }
    } else {
      if (orderForm.valid) {

        this.sweet.record('success', `Registro local`, 'Su caso se ha registrado correctamente', `Este caso será añadido a sus registros locales`)

        this.pwa.addOrderTask(this.mapOrderData(orden, true));

        this.orderSService.hasCreatedOffline.next(true);

      } else {
        this.tool.createWarning(orderForm, elem).then(result => { this.orderService.notFound() })

      }
    }

  }


  async updateRecord(orden: serviceOrder, orderForm: FormGroup, elem: ElementRef) {

    if (orderForm.valid) {
      this.HttpService.update<HttpResponse<any>>(this.mapOrderData(orden), 'ServiceOrderFirstStep').subscribe(
        {
          next: data => {
            this.sweet.record('success', `Registro No.${this.serviceOrder.serviceOrderNumber}`, 'Su caso se ha actualizado correctamente', '');
            return this.router.navigate(['./historial'], { relativeTo: this.route.parent })
          },
          error: error => {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
          }
        }
      )
    } else {
      this.tool.createWarning(orderForm, elem).then(result => { this.editOrderService.notFound() })

    }

  }



  get validateForms(): boolean {
    return !this.serviceOrderInvalid && !this.orderAssignmentInvalid ? false : true;
  }

  get validateServiceOrder(): boolean {
    return !this.serviceOrderInvalid ? false : true;
  }
  dismiss() {

  }

  async saveServiceAssignment(ServiceAssignment: ServiceOrderAssignment) {
    if (!this.assignmentEdit) {
      await this.sendNotification(ServiceAssignment)

      return await this.HttpService.post<HttpResponse<any>>(this.mapAssignmentData(ServiceAssignment), 'ServiceOrderSecondStep').toPromise()
    }

    this.serviceOrderAssignment !== this.AssignmentValue ? await this.sendNotification() : ''

    return await this.HttpService.update<HttpResponse<any>>(this.mapAssignmentData(ServiceAssignment), 'ServiceOrderSecondStep').toPromise()
  }


  async sendNotification(orderAssignment?: ServiceOrderAssignment) {

    let reAssign;
    this.serviceOrderAssignment ? reAssign = true : reAssign = false;
    let email: emailAssignment = {
      serviceOrderNumber: this.serviceOrder.serviceOrderNumber,
      deadline: this.serviceOrder.estimatedDeadLine,
      userCode: this.user.getUserData().userCode,
      assignmentUserId: this.serviceOrderAssignment?.assignedInspectorId ? this.serviceOrderAssignment.assignedInspectorId : orderAssignment.assignedInspectorId,
      isReassignment: reAssign,
      textCurrentOrderStatus: this.serviceOrder.statusOrderServiceInfo
    }

    await this.HttpService.post<emailAssignment>(email, 'EmailSender/SendNotificationAssignmentInspector').toPromise()
  }


  async saveWorkerData(workerData: workerData, companyData: companyData) {
    
    if (!this.workerData) {

      return await this.HttpService.post<HttpResponse<any>>(this.mapWorkerData(workerData, companyData), 'ServiceOrderThirdStep').toPromise()
    }

    return await this.HttpService.update<HttpResponse<any>>(this.mapWorkerData(workerData, companyData), 'ServiceOrderThirdStep').toPromise()
  }


  resetForms() {
    this.orderService.serviceOrder.reset()

  }

  mapAssignmentData(AssignmentData) {
    var AsignData = {
      serviceOrderNumber: this.serviceOrder.serviceOrderNumber
    }
    return { ...AssignmentData, ...AsignData }
  }

  mapWorkerData(workerData: workerData, companyData: companyData) {
    if (companyData.operationsStartDate) {
      var map = {
        operationsStartDate: new Date(`${companyData.operationsStartDate['year']}-${companyData.operationsStartDate['month']}-${companyData.operationsStartDate['day']}`)
      }
      return { ...workerData, ...companyData, ...map }

    }

    return { ...workerData, ...companyData }
  }

  mapOrderData(order: serviceOrder, id?: true) {


    let reasons = order.inspectionReasonsListed
      .map((motivo) => { return motivo['value'] })

    if (!order.newEstimatedDeadline) {

      let mapOrder = {

        orderDate: new Date(`${order.orderDate['year']}-${order.orderDate['month']}-${order.orderDate['day']}`),
        estimatedDeadLine: new Date(`${order.estimatedDeadLine['year']}-${order.estimatedDeadLine['month']}-${order.estimatedDeadLine['day']}`),
        statusOrderServiceId: this.actualState,
        inspectionReasonsListed: reasons.join(","),
        registrationDate: new Date(order.fechaRegistro),
        recordModificationDate: new Date(order.fechaModificacion),
        inspectionProgramId: this.inspectionProgramId || this.actualInspectionProgram
      }

      if(this.actualState === null || this.actualState === undefined) delete mapOrder.statusOrderServiceId;

      return !id ? { ...order, ...mapOrder } : { ...order, ...mapOrder, ...{ _id: uuid.v4() } }
    }
    let mapOrder = {

      orderDate: new Date(`${order.orderDate['year']}-${order.orderDate['month']}-${order.orderDate['day']}`),
      estimatedDeadLine: new Date(`${order.estimatedDeadLine['year']}-${order.estimatedDeadLine['month']}-${order.estimatedDeadLine['day']}`),
      newEstimatedDeadline: new Date(`${order.newEstimatedDeadline['year']}-${order.newEstimatedDeadline['month']}-${order.newEstimatedDeadline['day']}`),
      inspectionReasonsListed: reasons.join(","),
      statusOrderServiceId: this.actualState,
      registrationDate: new Date(order.fechaRegistro),
      recordModificationDate: new Date(order.fechaModificacion),
      inspectionProgramId: this.actualInspectionProgram

    }

    return !id ? { ...order, ...mapOrder } : { ...order, ...mapOrder, ...{ _id: uuid.v4() } }
  }

  MapDocuments(files: File[]) {

    let document: any;
    document = {
      evidenceFiles: files,
      documentId: 0,
      modifiedBy: this.user.getUserData().userCode,
      serviceOrderNumber: this.serviceOrder.serviceOrderNumber,
      registrationDate: new Date(),
      registeredBy: this.user.getUserData().userCode,
      registerStatus: true,
      modificationRegisterDate: null,

    }
    let form = new FormData()
    for (let file of document.evidenceFiles) {
      form.append('evidenceFiles', file);
    }

    form.append('documentId', '0')
    form.append('modifiedBy', document.modifiedBy)
    form.append('serviceOrderNumber', document.serviceOrderNumber)
    form.append('registeredBy', document.registeredBy)
    form.append('registerStatus', 'true')
    form.append('modificationRegisterDate', document.registrationDate.toDateString())
    return form
  }




  statusToVisitData(estatus: any[], id: number) {
    let complete = false;


    if (this.status.find(el => el.value == this.actualState).alternateField !== 'REIFI') {
      if (id && estatus.find(el => el.value == id)?.alternateField == "COMPL") {
        complete = true
      }

      let value = complete ? this.status.find(x => x.alternateField == 'EMPVI').value :
        this.status.find(x => x.alternateField == 'VISPR').value;

      this.actualState = value;
      if (this.serviceOrder.statusOrderServiceId == value) return
      (async () => {
        let alternateField = this.status.find(el => el.value == value).alternateField
        await this.HttpService.patch({ statusOrderServiceCode: alternateField }, `ServiceOrderFirstStep/StatusServiceOrder/${this.serviceOrder.serviceOrderNumber}/${alternateField}`).toPromise();

      })();
    }
  }

  statusToWarningReport() {
    if (this.status.find(el => el.value == this.actualState).alternateField !== 'REIFI') {
      let { value } = this.status.find(x => x.alternateField == 'PPCGR');
      this.actualState = value;
      if (this.serviceOrder.statusOrderServiceId == value) return
      (async () => {
        await this.HttpService.patch({ statusOrderServiceCode: 'PPCGR' }, `ServiceOrderFirstStep/StatusServiceOrder/${this.serviceOrder.serviceOrderNumber}/${'PPCGR'}`).toPromise();

      })();
    }
  }

  statusToInspection() {
    let { value } = this.status.find(x => x.alternateField == 'REIFI');
    this.actualState = value;
    if (this.serviceOrder.statusOrderServiceId == value) return
    (async () => {
      await this.HttpService.patch({ statusOrderServiceCode: 'REIFI' }, `ServiceOrderFirstStep/StatusServiceOrder/${this.serviceOrder.serviceOrderNumber}/${'REIFI'}`).toPromise();

    })();
  }

  createNewForm(id) {
    let { alternateField } = this.additionalForms?.find(x => x.value == id)
    switch (alternateField) {
      case "DATVS":
        const ngmodal: NgbModalRef = this.ngBModal.open(
          VisitDataComponent, {
          size: 'lg', backdrop: 'static',
          keyboard: false
        })
        ngmodal.componentInstance.serviceOrderNumber = _.cloneDeep(this.serviceOrder.serviceOrderNumber);
        ngmodal.componentInstance.edit.subscribe(output => {
          let { estados, id } = output

          this.statusToVisitData(estados, id)
          this.visitDataEdit = true;

        });

        break;
      case "ACAPE":
        const ngmodalWarningReport: NgbModalRef = this.ngBModal.open(
          WarningReportComponent, {
          size: 'xl', backdrop: 'static',
          keyboard: false
        });
        ngmodalWarningReport.componentInstance.serviceOrderNumber = _.cloneDeep(this.serviceOrder.serviceOrderNumber);

        ngmodalWarningReport.componentInstance.edit.subscribe(t => {
          this.warningReportEdit = true;
          this.statusToWarningReport()
          //  this.checkavailableForms(true)
        });
        break;

      case "ACDIN":
        if (this.infractionReport.length == 0) {
          const ngmodalinfractionReport: NgbModalRef = this.ngBModal.open(
            infractionReportComponent, {
            size: 'xl', backdrop: 'static',
            keyboard: false
          });
          ngmodalinfractionReport.componentInstance.serviceOrderNumber = _.cloneDeep(this.serviceOrder.serviceOrderNumber);
          ngmodalinfractionReport.componentInstance.edit.subscribe(t => {
            this.infractionReportEdit = true;

            (async () => { await this.getInfractions() })()
            this.statusToWarningReport()
          })

        }
        else {
          const ngmodalinfractionReport: NgbModalRef = this.ngBModal.open(
            infractionReportComponent, {
            size: 'xl', backdrop: 'static',
            keyboard: false
          });
          let infraction = { ...this.infractionReport[0], ...{ infractionReportNumber: null } }
          ngmodalinfractionReport.componentInstance.serviceOrderNumber = _.cloneDeep(this.serviceOrder.serviceOrderNumber);
          ngmodalinfractionReport.componentInstance.isDuplicate = true;
          ngmodalinfractionReport.componentInstance.infractionReport = infraction
          ngmodalinfractionReport.componentInstance.edit.subscribe(t => {
            this.infractionReportEdit = true;

          }
          )

        }
        break;
      case "NOTAS":
        const ngmodalorderNote: NgbModalRef = this.ngBModal.open(
          OrderNotesComponent, {
          size: 'lg', backdrop: 'static',
          keyboard: false, centered: true
        });
        ngmodalorderNote.componentInstance.serviceOrderNumber = _.cloneDeep(this.serviceOrder.serviceOrderNumber);
        ngmodalorderNote.componentInstance.edit.subscribe(t => {
          this.orderNotesEdit = true;

        });
        break;
      case "INFIN":
        const ngmodalinspectionReport: NgbModalRef = this.ngBModal.open(
          InspectionReportComponent, {
          size: 'xl', backdrop: 'static',
          keyboard: false
        });
        ngmodalinspectionReport.componentInstance.serviceOrderNumber = _.cloneDeep(this.serviceOrder.serviceOrderNumber);
        ngmodalinspectionReport.componentInstance.edit.subscribe(t => {
          this.inspectionReportEdit = true;
          this.removeInspectionOption()
          this.statusToInspection()
          this.additionalForm = null
        });
        break;
      default:
        break;
    }
  }
  
  async getInfractions() {

    this.infractionReport = await this.HttpService.get<infractionReport[]>(`InfractionReport/ServiceOrder/${this.serviceOrder?.serviceOrderNumber}`).toPromise()
  }
  async emptyWarning(value: boolean) {
    if (value) {
      this.warningReportEdit = false;
      this.warningReport.length = null;
    }
    this.checkavailableForms()
  }
  emptyInfraction(value: boolean) {
    if (value) {
      this.infractionReportEdit = false;
      this.infractionReport.length = null;
    }
  }
  emptyVisit(value: boolean) {
    if (value) {
      this.visitDataEdit = false;
      this.visitData.length = null;
    }
  }

  emptyInspection(value: boolean) {
    if (value) {
      this.inspectionReportEdit = false;
      this.inspectionReport = null;
    }
  }
  emptyNotes(value: boolean) {
    if (value) {
      this.orderNotesEdit = false;
      this.orderNotes.length = null;
    }
  }


  removeInspectionOption() {
    this.additionalForms = this.additionalForms.filter(el => el.alternateField !== 'INFIN');
  }

}
