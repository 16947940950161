import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import Swal from 'sweetalert2';
import { ProgramFilter } from '../../advance-Filters/advanced-program-filter/programFilter';
import { inspectionProgram } from '../_models/inspectionProgram';
import { user as userModel } from "../../../../auth/_models/User"
import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';

@Component({
  selector: 'app-inspection-program-form',
  templateUrl: './inspection-program-form.component.html',
  styleUrls: ['./inspection-program-form.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [

    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class InspectionProgramFormComponent implements OnInit {

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private HttpService: HttpClientService, private user: UserService,
    private sweet: SweetAlertService, private toast: ToastService, private router: Router, public tool: ToolsService, private elem: ElementRef,
    private calendarS: NgbCalendar) { }
  get currentDay() {
    let date = new Date()
    return date.setDate(date.getDate() - 1)
  }
  @Input() isFilter: boolean = false
  minDate = { year: this.calendarS.getToday().year, month: this.calendarS.getToday().month, day: this.calendarS.getToday().day }
  MinEstimatedEndDate: any;
  maxEstimatedDate = { year: moment().year(), month: moment().month() + 3, day: moment().date() }
  MinEndDate: any;
  maxDate = { year: moment().year(), month: moment().month() + 3, day: moment().date() }
  @Input() inspectionProgram: inspectionProgram;
  @Input() canEdit: boolean = true;
  inspectionProgramValue: inspectionProgram
  inspectionProgramForm: FormGroup;
  localReps: DropDownOptionModel[] = [];
  clasificationTypes: any[] = []
  programTypes: DropDownOptionModel[] = []
  StatusPrograms: any[] = []
  programFilter: ProgramFilter = this.tool.getFilterLS('programFilter')?.form;
  isSaving: boolean = false;
  isUserDirector: boolean = false;
  isUserLocalRep: boolean = false;
  local: number;
  currentUser = this.user.getUserData() as userModel
  options: DropDownOptionModel[] = [{ value: true, text: 'Sí' }, { value: false, text: 'No' }]

  show: boolean = false
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (this.inspectionProgramForm.dirty && !this.inspectionProgram) {
      $event.returnValue = true;
    }
  }

  isAproved(value) {

    let aproved = this.StatusPrograms.find(status => status.alternatedField == true).value;
    let decline = this.StatusPrograms.find(status => status.code == 'PRDEC').value;
    if (value) {
      this.inspectionProgramForm.patchValue({
        'statusInspectionProgramId': aproved
      })
    } else {
      this.inspectionProgramForm.patchValue({
        'statusInspectionProgramId': decline
      })
    }
  }

  realStartDateSet() {
    let clasifiId = this.inspectionProgramForm.get('classificationTypeId').value
    let clasification = this.clasificationTypes.find(clasi => clasi.value == clasifiId).text
    if (clasification == "Local") {

      let id = this.StatusPrograms.find(status => status.code == 'PRELO').value;

      this.inspectionProgramForm.patchValue({
        'statusInspectionProgramId': id
      })
    } else {
      let id = this.StatusPrograms.find(status => status.code == 'PREJM').value;

      this.inspectionProgramForm.patchValue({
        'statusInspectionProgramId': id
      })
    }
  }


  async permitirSalirDelRoute(): Promise<boolean | Observable<boolean>> {
    if (!this.inspectionProgram) {
      if (this.inspectionProgramForm.dirty && !this.inspectionProgram && this.inspectionProgramForm.invalid) {
        let response;
        await Swal.fire({
          title: 'Se realizaron nuevos cambios, Esta seguro que desea salir?',
          text: "Toda tu informacion se perdera",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'

        }).then((result) => {
          response = result.isConfirmed
        })

        return response
      } else {
        return true
      }
    }
    else {

      if (this.tool.shallowEqual(this.inspectionProgramValue, this.inspectionProgramForm.value) && !this.isSaving) {
        let response;
        await Swal.fire({
          title: 'Se realizaron nuevos cambios, Esta seguro que desea salir?',
          text: "Toda tu informacion se perdera",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'

        }).then((result) => {
          response = result.isConfirmed
        })

        return response
      } else {
        return true
      }
    }
  }


  async ngOnInit() {


    this.minDate = !this.inspectionProgram ? { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date } : null
    this.checkRole()
    this.getRegular()
    if (this.route.data) {
      this.route.data.subscribe((data: { program: inspectionProgram }) => {

        this.inspectionProgram = data.program
        if (this.inspectionProgram?.approvedInspection == false || (this.currentUser.roleCode !== 'REPLO' && this.currentUser.roleCode !== 'DIR')) (
          this.canEdit = false
        )
      })
    }

    this.getDropdowns()
    this.createForm()
    if (this.user.getUserData().roleCode == "REPLO" && !this.isFilter) {
      this.inspectionProgramForm.patchValue({
        'localRepresentativeProvinceId': this.user.getUserData().repLocalProvId
      })
    }
  }

  createForm() {
    if (this.isFilter) {
      this.inspectionProgramForm = this.fb.group({

        programTypeId: new FormControl(this.programFilter ? this.programFilter.programTypeId : null),
        programName: new FormControl(this.programFilter ? this.programFilter.programName : null),
        classificationTypeId: new FormControl(this.programFilter ? this.programFilter.classificationTypeId : null),
        realStartDate: new FormControl(this.programFilter ? this.programFilter.realStartDate : null),
        realEndDate: new FormControl(this.programFilter ? this.programFilter.realEndDate : null)
      })
    }
    else {
      if (!this.inspectionProgram) {
        this.inspectionProgramForm = this.fb.group({
          inspectionProgramId: new FormControl(!this.isFilter ? 0 : null),
          inspectionProgramNumber: new FormControl(null),
          programTypeId: new FormControl(this.getRegular(), Validators.required),
          programObjective: new FormControl(null, Validators.required),
          geographicLimit: new FormControl(null, Validators.required),
          classificationTypeId: new FormControl(null, Validators.required),
          localRepresentativeProvinceId: new FormControl(null, Validators.required),
          estimatedStartDate: new FormControl(null, [Validators.required, this.tool.dateValidator]),
          estimatedEndDate: new FormControl(null, [Validators.required, this.tool.dateValidator]),
          statusInspectionProgramId: new FormControl(null, Validators.required),
          registrationDate: new FormControl(null),
          registeredBy: new FormControl(this.user.getUserData().userCode),
          recordModificationDate: new FormControl(null),
          modifiedBy: new FormControl(this.user.getUserData().userCode),
          recordStatus: new FormControl(null),
          programName: new FormControl(null, Validators.required),
          approvedInspection: new FormControl(true),
          validatedBy: new FormControl(null),
          validationComment: new FormControl(null),
          realStartDate: new FormControl(null),
          realEndDate: new FormControl(null),
          isNotified: new FormControl(false)
        })
        if (this.isFilter) {
          this.tool.removeValidators(this.inspectionProgramForm)
        }
        this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }
      }
      else {
        this.inspectionProgramForm = this.fb.group({
          inspectionProgramId: new FormControl(this.inspectionProgram.inspectionProgramId),
          inspectionProgramNumber: new FormControl(this.inspectionProgram.inspectionProgramNumber),
          programTypeId: new FormControl(this.inspectionProgram.programTypeId, Validators.required),
          programObjective: new FormControl(this.inspectionProgram.programObjective, Validators.required),
          geographicLimit: new FormControl(this.inspectionProgram.geographicLimit, Validators.required),
          classificationTypeId: new FormControl(this.inspectionProgram.classificationTypeId, Validators.required),
          localRepresentativeProvinceId: new FormControl(this.inspectionProgram.localRepresentativeProvinceId, Validators.required),
          estimatedStartDate: new FormControl(this.tool.dateToObject(this.inspectionProgram.estimatedStartDate), [Validators.required, this.tool.dateValidator]),
          estimatedEndDate: new FormControl(this.tool.dateToObject(this.inspectionProgram.estimatedEndDate), [Validators.required, this.tool.dateValidator]),
          statusInspectionProgramId: new FormControl(this.inspectionProgram.statusInspectionProgramId, Validators.required),
          registrationDate: new FormControl(this.tool.dateToObject(this.inspectionProgram.fechaRegistro)),
          registeredBy: new FormControl(this.inspectionProgram.registradoPor),
          recordModificationDate: new FormControl(null),
          modifiedBy: new FormControl(this.user.getUserData().userCode),
          recordStatus: new FormControl(this.inspectionProgram.estatusRegistro),
          programName: new FormControl(this.inspectionProgram.programName, Validators.required),
          approvedInspection: new FormControl(this.inspectionProgram.approvedInspection),
          validatedBy: new FormControl(this.inspectionProgram.validatedBy),
          validationComment: new FormControl(this.inspectionProgram.validationComment),
          realStartDate: new FormControl(this.tool.dateToObject(this.inspectionProgram.realStartDate)),
          realEndDate: new FormControl(this.tool.dateToObject(this.inspectionProgram.realEndDate)),
          isNotified: new FormControl(this.inspectionProgram.isNotified)
        })

        this.inspectionProgramValue = _.clone(this.inspectionProgramForm.value);
        this.MinEstimatedEndDate = { ...this.tool.dateToObject(this.inspectionProgram.estimatedStartDate), ...{ day: this.tool.dateToObject(this.inspectionProgram.estimatedStartDate).day + 1 } }
        this.MinEndDate = { ...this.tool.dateToObject(this.inspectionProgram?.realStartDate), ...{ day: this.tool.dateToObject(this.inspectionProgram.realStartDate)?.day + 1 } }
        if (this.currentUser.roleCode !== 'DIR') { this.checkApprovedStatus() }
      }
    }

  }
  refreshMin() {
    this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }

  }

  checkRole() {
    if (this.user.getUserData().roleCode == 'DIR') {
      this.isUserDirector = true;

    }


  }


  validateBy(value) {
    this.inspectionProgramForm.patchValue({
      'validatedBy': this.user.getUserData().userCode
    })
    this.isAproved(value)

  }

  getLocal() {
    if (!this.isUserDirector && !this.inspectionProgram && !this.isFilter) {
      this.inspectionProgramForm.patchValue({
        'classificationTypeId': this.clasificationTypes.find(clasifications => clasifications.isLocalRepresentative == true).value
      })
    }
  };
  getStatus() {
    if (!this.isUserDirector && !this.inspectionProgram && !this.isFilter) {
      this.inspectionProgramForm.patchValue({
        'statusInspectionProgramId': this.StatusPrograms.find(x=>x.code=='PRALO' && x.alternatedField ==true).value
      })
      this.validateBy(true);

    }
  };
  assignLocal(): number {

    if (!this.isUserDirector) {

      return this.local
    }
    return null
  }
  getDropdowns() {
    this.HttpService.get<DropDownOptionModel[]>('LocalRepresentativeProvince').subscribe((response) => { this.localReps = response })
    this.HttpService.get<any[]>('ClassificationType').subscribe((response) => {
      this.clasificationTypes = response;
      this.getLocal();
    })
    this.HttpService.get<DropDownOptionModel[]>('TypeOfService').subscribe((response) => { this.programTypes = response })
    this.HttpService.get<any[]>('StatusInspectionProgram').subscribe((response) => {
      this.StatusPrograms = response
      this.getStatus();
    })
  }

  getRegular(): number {
    let t = this.programTypes?.find(type => type.text == 'Regular')?.value

    return Number(t)
  }

  changeMinDate(date) {
    if (!this.isFilter) {
      if (typeof date === 'object') {
        if (date['year'].toString().length == 4) {

          this.MinEstimatedEndDate = { ...this.inspectionProgramForm.get('estimatedStartDate').value, ...{ day: this.inspectionProgramForm.get('estimatedStartDate').value.day + 1 } }
          // this.inspectionProgramForm.patchValue({
          //   estimatedEndDate: { ...date, ...{ day: date.day + 1 } }
          // })
        }
      }
    }
  }

  changeMinFinalDate(date) {
    if (!this.isFilter) {
      this.realStartDateSet()
      this.MinEndDate = { ...this.inspectionProgramForm.get('realStartDate').value, ...{ day: this.inspectionProgramForm.get('realStartDate').value.day + 1 } }

    }
  }
  sendNotification(programNumber: any) {
    this.inspectionProgramForm.value.inspectionProgramNumber = programNumber;



    this.HttpService.post<inspectionProgram>(this.mapProgram(this.inspectionProgramForm.value), 'EmailSender/SendInspectionProgramNotification').subscribe(
      {
        next: response => {
        }
        , error: err => {

          this.toast.error('No se pudo enviar notificación vía Correo', 'error')

        }
      }
    )
  }

  checkApprovedStatus() {
    if (this.inspectionProgramForm.get('approvedInspection').value) {
      this.inspectionProgramForm.get('realEndDate').setValidators(Validators.required)
      this.inspectionProgramForm.get('realStartDate').setValidators(Validators.required)
      this.inspectionProgramForm.updateValueAndValidity()

    } else {
      this.inspectionProgramForm.get('realEndDate').clearValidators()
      this.inspectionProgramForm.get('realStartDate').clearValidators()
      this.inspectionProgramForm.updateValueAndValidity()
    }
  }
  saveRecord() {

    let statusId = this.inspectionProgramForm.get('statusInspectionProgramId').value
    let status = this.StatusPrograms.find(status => status.value == statusId)?.code
    this.isSaving = true


    let t = this.user.getUserData().roleCode ==='DIR' ?  (this.inspectionProgramForm.get('programTypeId')?.value??  this.programTypes?.find(type => type.text == 'Regular')?.value) : this.programTypes?.find(type => type.text == 'Regular')?.value

    this.inspectionProgramForm.patchValue(
      {
        programTypeId: t
      }
    )
    if ((status == "PRELO" || status == "PREJM")) {
      this.inspectionProgramForm.patchValue({
        'isNotified': true
      })
    } else {
      this.inspectionProgramForm.patchValue({
        'isNotified': false
      })
    }
    if (this.inspectionProgramForm.valid) {
      if (!this.inspectionProgram) {

        this.HttpService.post<inspectionProgram>(this.mapProgram(this.inspectionProgramForm.value), 'InspectionProgram').subscribe(
          {
            next: response => {
              // this.toast.success('guardado correctamente', response.serviceOrderNumber)
              this.sweet.record('success', `Registro No.${response.inspectionProgramNumber}`, 'Su Programa se ha registrado correctamente', `Este caso será añadido a su historial de registro`)
              if ((status == "PRELO" || status == "PREJM")) {
                this.sendNotification(response.inspectionProgramNumber);
              }
              localStorage.setItem('typeHistory', 'PROGI');

              return this.router.navigate(['./historial'], { relativeTo: this.route.parent.parent })
            }
            , error: error => {

              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')

            }
          }
        )
      } else {
        if ((status == "PRELO" || status == "PREJM") && !this.inspectionProgram.isNotified) {
          this.sendNotification(this.inspectionProgram.inspectionProgramNumber);
        }
        this.HttpService.update<inspectionProgram>(this.mapProgram(this.inspectionProgramForm.value), 'InspectionProgram').subscribe(
          {
            next: response => {
              // this.toast.success('guardado correctamente', response.serviceOrderNumber)
              this.sweet.record('success', `Registro No.${this.inspectionProgram.inspectionProgramNumber}`, 'Su Programa se ha actualizado correctamente', ``)
              return this.router.navigate(['./historial'], { relativeTo: this.route.parent.parent })
            }
            , error: error => {

              this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')

            }
          }
        )

      }
    }
    else {
      this.tool.createWarning(this.inspectionProgramForm, this.elem).then(result => this.notFound())
    }
  }

  mapProgram(program: inspectionProgram) {
    let { realStartDate, realEndDate } = this.inspectionProgramForm.value;
    if (this.inspectionProgram) {

      var map = {
        estimatedStartDate: new Date(`${program.estimatedStartDate['year']}-${program.estimatedStartDate['month']}-${program.estimatedStartDate['day']}`),
        estimatedEndDate: new Date(`${program.estimatedEndDate['year']}-${program.estimatedEndDate['month']}-${program.estimatedEndDate['day']}`),
        registrationDate: new Date(`${program.fechaRegistro['year']}-${program.fechaRegistro['month']}-${program.fechaRegistro['day']}`),
        realStartDate: realStartDate ? new Date(`${realStartDate['year']}-${realStartDate['month']}-${realStartDate['day']}`) : null,
        realEndDate: realEndDate ? new Date(`${realEndDate['year']}-${realEndDate['month']}-${realEndDate['day']}`) : null,
      }
      return { ...this.inspectionProgramForm.value, ...map }
    }
    else {
      var mapCreate = {
        estimatedStartDate: new Date(`${program.estimatedStartDate['year']}-${program.estimatedStartDate['month']}-${program.estimatedStartDate['day']}`),
        estimatedEndDate: new Date(`${program.estimatedEndDate['year']}-${program.estimatedEndDate['month']}-${program.estimatedEndDate['day']}`),
        realStartDate: realStartDate ? new Date(`${realStartDate['year']}-${realStartDate['month']}-${realStartDate['day']}`) : null,
        realEndDate: realEndDate ? new Date(`${realEndDate['year']}-${realEndDate['month']}-${realEndDate['day']}`) : null
      }
      return { ...this.inspectionProgramForm.value, ...mapCreate }


    }

  }

  checkAproved() {
    if (this.inspectionProgramForm.get('approvedInspection').valid && this.inspectionProgram.approvedInspection == false) {
      return true
    }
    return false
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }

  clean() {
    if (!this.isFilter) {
      this.inspectionProgramForm.patchValue({
        programTypeId: null,
        classificationTypeId: null,
        localRepresentativeProvinceId: null,
        estimatedStartDate: null,
        estimatedEndDate: null,
        statusInspectionProgramId: null,
        approvedInspection: null,
        realStartDate: null,
        realEndDate: null,
      })
    }
    else {
      this.inspectionProgramForm.patchValue({
        programName: null,
        programTypeId: null,
        classificationTypeId: null,
        realStartDate: null,
        realEndDate: null,
      })
    }
  }
}
