<div class="container-fluid">
    <div class="row mb-4">
      <div class="col-12 col-md-6">
        <h2 class="text-left">Datos de la Solicitud</h2>
      </div>
    </div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="requestAcordeon">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#requestAcordeonCollapse" aria-expanded="false">
            INFORMACION GENERAL
          </button>
        </h2>
        <div id="requestAcordeonCollapse" class="accordion-collapse collapse" aria-labelledby="requestAcordeon"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body" *ngIf="this.dataRequest">
            <app-form-request [data]="this.dataRequest"></app-form-request>       
          </div>
        </div>
      </div>
    </div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="responseAcordeon">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#responseAcordeonCollapse" aria-expanded="false">
            RESPUESTA
          </button>
        </h2>
        <div id="responseAcordeonCollapse" class="accordion-collapse collapse" aria-labelledby="responseAcordeon"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body" *ngIf="this.dataResponse">
            <app-form-response [data]="this.dataResponse"></app-form-response>       
          </div>
        </div>
      </div>
    </div>
</div>

