<div class="tab-content" id="pills-tabContent" style="height: 100% !important">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link pb-4"
          id="auditoria-tab"
          data-bs-toggle="tab"
          type="button"
          role="tab"
          name="auditoria"
          aria-selected="false"
          routerLink="./historial"
          routerLinkActive="is-active"
        >
          Auditoria
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link pb-4"
          id="request-tab"
          data-bs-toggle="tab"
          type="button"
          role="tab"
          aria-selected="true"
          routerLink="/Auditoria/request"
          routerLinkActive="is-active"
          [routerLinkActiveOptions]="{exact: true}"
          name="request"
        >
          Solicitud y Respuesta
        </button>
      </li>
    </ul>
    <div class="tab-content h-100" id="myTabContent pt-5">
      <router-outlet></router-outlet>
    </div>
  </div>