<div class="modal-content">
    <div class="modal-header border-0">
      <h1 class="modal-title text-center fs-4 modalTitle" id="staticBackdropLabel">Seleccionar Empresa/Sucursal (RNL)</h1>
      <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="container mt-4 mb-3">
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="companyFiltered" placeholder="Buscar por nombre o RNL">
              <button type="button" class="btn btn-buscarInput" (click)="filterByNameOrRNL()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>

        <hr>

        <div class="table-responsive">
          <table class="table table-hover table-borderless">
            <thead class="table-light">
              <tr>
                <th>Nombre Empresa</th>
                <th>RNL</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let coincidencia of coincidencias | paginate: { itemsPerPage: 10, currentPage: p}; let i = index"
              (click)="setRnlNumber(i); activeModal.close()">
                <td>{{ coincidencia.nombreComercial }}</td>
                <td>{{ coincidencia.rnl }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <ul class="list-group list-group-flush">
          <li *ngFor="let coincidencia of coincidencias | paginate: { itemsPerPage: 10, currentPage: p}; let i = index" 
              class="list-group-item list-group-item-action">
            <div (click)="setRnlNumber(i); activeModal.close()">
              <p>{{ coincidencia.nombreComercial }} - {{ coincidencia.rnl }}</p>
            </div>
          </li>
        </ul> -->

        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>

    </div>
</div>
  