<div class="modal-content mapContainer">
  <div class="modal-header border-0">
    <!-- <h5 class="modal-title" id="exampleModalLabel">{{ title || "Mapa" }}</h5> -->
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="ngbActiveModal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h1 class="modal-title fs-4" id="staticBackdropLabel">Cerrar Expediente</h1>
    </div>

    <div class="modal-body p-5">
      <form [formGroup]="cerrarExpedientForm" (ngSubmit)="validateCerrarExpedient()">
        <div class="row mb-3">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="">Estado Actual</label>
              <input value={{EstadoNombre}} class="form-control" disabled>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="">Motivo de Cierre <span class="asterisco">*</span></label>
              <select formControlName="idMotivoCierre" class="form-select" style=" border-radius: inherit;">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let estadoC of motivoCierreDrop" value="{{ estadoC.value }}">{{ estadoC.text }}</option>
              </select>
              <div *ngIf="cerrarExpedientForm.get('idMotivoCierre').invalid &&
              cerrarExpedientForm.get('idMotivoCierre').touched">
                <span class="text-danger">Seleccione una opcion</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="">Fecha Solicitud <span class="asterisco">*</span></label>
              <!-- <input type="date" name="" id="" class="form-control"> -->
              <input class="form-control" formControlName="fechaSolicitud" type="date" id="fechaSolicitud"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="">Suma Pagada</label>
            <div class="input-group mb-3">
              <span class="input-group-text">RD$</span>
              <input type="number" class="form-control" formControlName="sumaPagada">
            </div>
          </div>
          <div class="col-md-8">
            <label for=""><span class="asterisco">*</span> Comentarios u Observaciones</label>
            <input type="text" maxlength="180" formControlName="observacionSolicitud" class="form-control">
          </div>
        </div>
        <div class="row mt-5 cierreDoc">
          <div class="col pt-3">
            <p> <span class="asterisco">*</span> Documentos de Cierre</p>
          </div>
          <div class="col pt-2">
            <button type="button" (click)="addFileGroup()" class="btn btn-anadir float-end">
              <i class="fa fa-plus-circle" aria-hidden="true"></i> Añadir
            </button>
          </div>
        </div>

        <div formArrayName="files" *ngFor="let fileGroup of files.controls; let i=index">
          <div [formGroupName]="i" class="formAdjuntar card p-4 mt-4">
            <div class="d-flex justify-content-between align-items-center">
              <label for="" class="form-label mb-2">Documentos Notificados</label>
              <i (click)="deleteFileGroup(i)" class="fa fa-trash text-danger"></i>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="input-group">
                  <input type="text" formControlName="comentario" class="form-control" placeholder="Comentarios"
                    aria-label="Recipient's username" aria-describedby="button-addon2">

                  <button class="btn-upload">
                    <i class="fa fa-download"></i> Adjuntar
                    <input type="file" (change)="onFileSelect($event, i)">
                  </button>
                </div>
                <div *ngIf="fileGroup.get('file').value">
                  <div class="documentContainer">
                    <span>{{fileGroup.get('file').value?.name}}</span>
                    <a type="button" class="downloadBtn" (click)="downLoadAttachedFile(fileGroup.get('file'))">
                      <i class="fa fa-cloud-download"></i>
                    </a>
                    <a type="button" class="deleteBtn" (click)="deleteFileGroup(i)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <p class="maximoRe">Peso máximo de 20 mb</p>
        </div>

        <div class="modal-footer border-0 justify-content-center" *ngIf="this.showBotonGuardar">
          <button type="button" class="btn btn-guardar" [disabled]="expedientCerrado"
            (click)="submitForm()">{{mostrarAccionDelDirectorAlAbogado?"Reenviar":"Guardar"}}</button>
        </div>
        <div class="modal-footer border-0 justify-content-center" *ngIf="this.expedientIsWaitingForAction">
          <h3 class="text-warning">Expediente en espera de la respuesta del Encargado </h3>
        </div>
      </form>

      <div
        *ngIf="this.mostrarAccionDelDirectorAlAbogado || this.expedientResult.value.estado.codigoReferencia === 'CERR'"
        class="aceptacion-declinacion">
        <div class="card border-0">
          <div class="card-body">
            <form>
              <div class="mb-3 mt-4">
                <label for="" class="mb-2">Comentarios u Observaciones</label>
                <input readonly type="text" maxlength="180" id="observacionAccion"
                  [value]="expedientCierre?.observacionAccion" class="form-control"
                  placeholder="Escriba algun comentario, nota u observación">
                <div *ngIf="closeExpedientFormApprove.get('observacionAccion').invalid &&
                    closeExpedientFormApprove.get('observacionAccion').touched">
                  <span class="text-danger">El campo de Observaciones o comentarios es obligatorio.</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3 mt-3">
                    <label for="fechaAccion" class="form-label mb-2">Fecha de Acción</label>
                    <input [value]="expedientCierre?.fechaAccion" class="form-control" id="fechaAccion" disabled
                      readonly>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3 mt-3">
                    <label for="aprobador-declin" class="form-label mb-2">Aprobado/Declinado Por</label>
                    <input type="text" class="form-control"
                      value="{{expedientCierre?.usuarioAccion?.firstName}} {{expedientCierre?.usuarioAccion?.firstLastName}}"
                      disabled readonly>
                  </div>
                </div>
              </div>
              <div class="botones text-center mt-3 mb-2" *ngIf="mostrarAprobarDeclinarParaDirector">
                <button type="button" class="btn btn-aceptar" (click)="submitFormApprove('APROBADO')">Aprobar</button>
                <button type="button" class="btn btn-declinar ms-2"
                  (click)="submitFormApprove('DECLINADO')">Declinar</button>
              </div>
              <div class="botones text-center mt-3 mb-2" *ngIf="!mostrarAprobarDeclinarParaDirector">
                <div *ngIf="this.expedientResult?.value?.expedienteCierre?.estado === 'APROBADO'" type="button"
                  class="btn btn-aceptar">APROBADO</div>
                <div *ngIf="this.expedientResult?.value?.expedienteCierre?.estado === 'DECLINADO'" type="button"
                  class="btn btn-declinar ms-2">DECLINADO</div>
              </div>
            </form>
          </div>

        </div>
      </div>

      <div *ngIf="this.showFormSolicitudExpediente" class="aceptacion-declinacion">
        <div class="card border-0">
          <div class="card-body">
            <h5 class="card-title">
              Aceptación / Declinación del Cierre
            </h5>
            <form [formGroup]="closeExpedientFormApprove">
              <div class="mb-3 mt-4">
                <label for="" class="mb-2">Comentarios u Observaciones</label>
                <input type="text" maxlength="180" id="observacionAccion" formControlName="observacionAccion"
                  class="form-control" placeholder="Escriba algun comentario, nota u observación">
                <div *ngIf="closeExpedientFormApprove.get('observacionAccion').invalid &&
                    closeExpedientFormApprove.get('observacionAccion').touched">
                  <span class="text-danger">El campo de Observaciones o comentarios es obligatorio.</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3 mt-3">
                    <label for="fechaAccion" class="form-label mb-2">Fecha de Acción</label>
                    <input type="date" class="form-control" id="fechaAccion" formControlName="fechaAccion" disabled
                      readonly>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3 mt-3">
                    <label for="aprobador-declin" class="form-label mb-2">Aprobador/Declinado Por</label>
                    <input type="text" class="form-control" value="{{user.firstName}} {{user.firstLastName}}" disabled
                      readonly>
                  </div>
                </div>
              </div>
              <div class="botones text-center mt-3 mb-2" *ngIf="mostrarAprobarDeclinarParaDirector">
                <button type="button" class="btn btn-aceptar" (click)="submitFormApprove('APROBADO')">Aprobar</button>
                <button type="button" class="btn btn-declinar ms-2"
                  (click)="submitFormApprove('DECLINADO')">Declinar</button>
              </div>
              <div class="botones text-center mt-3 mb-2" *ngIf="!mostrarAprobarDeclinarParaDirector">
                <div *ngIf="this.expedientResult?.value?.expedienteCierre?.estado === 'APROBADO'" type="button"
                  class="btn btn-aceptar">APROBADO</div>
                <div *ngIf="this.expedientResult?.value?.expedienteCierre?.estado === 'DECLINADO'" type="button"
                  class="btn btn-declinar ms-2">DECLINADO</div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>


  </div>
</div>
