import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-montly-inspection-work',
  templateUrl: './montly-inspection-work.component.html',
  styleUrls: ['./montly-inspection-work.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ]
})
export class MontlyInspectionWorkComponent implements OnInit {
  urlApi: any;

  constructor(private translate: TranslateService, private config: PrimeNGConfig, private FB: FormBuilder, public tool: ToolsService, private http: HttpClientService, private el: ElementRef) {
    this.translate.setDefaultLang('es');
    this.urlApi = environment.api_url;
  }
  montlyViolationForm: FormGroup;
  show: boolean = false
  reportTypes: any[]
  localReps: any[];
  inspectorDataDrops: any[];
  maxDate = moment().toDate()
  async ngOnInit() {

    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.montlyViolationForm = this.FB.group({
      repLocalProvId: new FormControl(null, Validators.required),
      inspectorCode: new FormControl(null),
      date: new FormControl(null, Validators.required)
    })
    await this.getDropdowns()
  }



  async getDropdowns() {
    this.localReps = await this.http.get<any[]>(`LocalRepresentativeProvince`).toPromise();
  }
  async getInspectorDropdowns() {
    
    let Code = this.montlyViolationForm.get('repLocalProvId')?.value;
    if(Code) this.inspectorDataDrops = await this.http.get<any[]>(`User/InspectorByLocalRepresentativeCode/provinceCode?provinceCode=${Code}`).toPromise();
  }
  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }

  generateReport() {
    if (this.montlyViolationForm.invalid) {
      this.tool.createWarning(this.montlyViolationForm, this.el).then(result => this.notFound())

    } else {
      const { date, repLocalProvId, inspectorCode } = this.montlyViolationForm.value
      let month = moment(date).get('month') + 1
      let year = moment(date).get('year')
      let paramUserCode:string = "";
       if(inspectorCode) paramUserCode = `&UserCode=${inspectorCode}`
 
      this.tool.showLoading(`${this.urlApi}/ExcelReports/InspectionWorkRLT02?localRepresentativeCode=${repLocalProvId}&month=${month}&year=${year}${paramUserCode}`)

    }
  }
}
