import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../user-service-form/_model/UserService'
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { UserServiceFormComponent } from '../../user-service-form/user-service-form.component';
import { UserServiceFilter } from './userServiceFilter';

@Component({
  selector: 'app-advanced-user-service-filter',
  templateUrl: './advanced-user-service-filter.component.html',
  styleUrls: ['./advanced-user-service-filter.component.css']
})
export class AdvancedUserServiceFilterComponent implements OnInit {
  @Input() isActive: boolean;
  constructor(public ngbActiveModal: NgbActiveModal, private tool: ToolsService) { }

  ngOnInit(): void {
  }
  buildSearch(forms: UserService) {


    const userService: UserServiceFilter = {
      requestFormId: forms.requestFormId,
      reportDate: forms.reportDate
    }

    const filter = {
      form: userService,
      active: this.isActive
    }
    this.tool.setFilterLS('serviceFilter', JSON.stringify(filter))
    this.tool.buildSearch(userService, this.ngbActiveModal)
  }

}
