<div *ngIf="loading; else load">
  <div class="d-flex align-items-center justify-content-center">
    <img
      class="align-self-center image"
      src="../../../../../assets/icons/icon-152x152.png"
      alt=""
    />
  </div>
</div>

<ng-template #load>
  <div class="" *ngIf="!approved; else fillAll">
    <app-inspection-program-form
      #inspectionProgramForm
      [inspectionProgram]="inspectionProgram"
    ></app-inspection-program-form>
    <div class="btn-group float-right m-3">
      <button
        type="button"
        class="rounded-pill btn btn-save px-5"
        (click)="saveRecord(inspectionProgramForm)"
      >
        Guardar
      </button>
      <!-- <button type="button" class="btn btn-primary" (click)="saveRecord()">
         Guardar
         </button> -->
    </div>
  </div>
  <ng-template #fillAll>
    <app-inspection-program-form
      [inspectionProgram]="inspectionProgram"
      #inspectionProgramForm
    ></app-inspection-program-form>

    <div class="container-fluid">
      <div class="accordion m-1 mb-2" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <div class="checkIcon m-2">
                <!-- <i
                    *ngIf="!workerDataValid; else orderNotValid"
                    class="far fa-check-circle text-success"
                  ></i>
                  <ng-template #orderNotValid
                    ><i class="far fa-times-circle text-danger"></i
                  ></ng-template> -->
              </div>
              <h4>Rutas</h4>
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body"></div>
            <app-route-history
              [programNumber]="inspectionProgram.inspectionProgramNumber"
              [inspectionProgramId]="inspectionProgram.inspectionProgramId"
            ></app-route-history>
          </div>
        </div>
      </div>

      <div class="btn-group float-right m-3" *ngIf="canEdit">
        <button
          type="button"
          class="rounded-pill btn btn-save px-5 ml-2"
          (click)="saveAll(inspectionProgramForm)"
        >
          Guardar
        </button>
        <!-- <button
            (click)="
              test(
                AssignServiceOrder.serviceOrder,
                OrderAssigment.assignmentForm,
                workerDataTemplate.workerDataForm,
                companyDataTemplate?.companyDataForm
              )
            "
          >
            test
          </button> -->
      </div>
    </div>

    <!-- end container -->
  </ng-template>
</ng-template>
